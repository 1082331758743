import { FC, ReactNode } from 'react';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { Divider, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import {
  StaticDateRangePicker,
  StaticDateRangePickerProps,
} from '@mui/x-date-pickers-pro/StaticDateRangePicker';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { fDT } from 'src/utils/formatTime';

interface Props extends StaticDateRangePickerProps<Date> {
  customFooter?: ReactNode | null;
}

const CustomStaticDateRangePicker: FC<Props> = ({ customFooter, ...other }) => {
  const { value } = other;
  const isMobile = useResponsive('down', 'sm');
  const { shortDateFormat, currentLang } = useLocales();
  const { translate } = useLocales('common');

  return (
    <Stack divider={<Divider />}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDateRangePicker
          displayStaticWrapperAs={isMobile ? 'mobile' : 'desktop'}
          {...other}
          slotProps={{
            ...other.slotProps,
            toolbar: {
              hidden: false,
              sx: { '& .MuiPickersToolbar-content .MuiTypography-root': { alignSelf: 'center' } },
            },
            actionBar: { actions: [] },
          }}
          sx={{
            ...(isMobile && {
              '&.MuiPickersLayout-root': {
                display: 'flex', flexDirection: 'column',
                '& .MuiList-root': {
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxWidth: 'inherit',
                  padding: 1,
                  gap: 0.5,
                  '& .MuiListItem-root': {
                    px: 0,
                    width: 'max-content',
                  },
                },
                '& .MuiPickersLayout-contentWrapper': {
                  alignItems: 'center',
                },
              },
            }),
          }}
        />
      </LocalizationProvider>

      {customFooter ? (
        customFooter
      ) : (
        <Stack direction="row" justifyContent="center" p={2}>
          <Typography variant="caption" color="text.secondary">
            {value?.[0]
              ? fDT({
                  date: new Date(value[0]),
                  pattern: shortDateFormat,
                  options: { locale: currentLang.dateLocale },
                })
              : translate('from')}
            &nbsp;-&nbsp;
            {value?.[1]
              ? fDT({
                  date: new Date(value[1]),
                  pattern: shortDateFormat,
                  options: { locale: currentLang.dateLocale },
                })
              : translate('to')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default CustomStaticDateRangePicker;
