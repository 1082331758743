import { subDays } from 'date-fns';
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { AppliedFilter, DateFilterValue, Filter, FilterState, FilterValue } from 'src/@types/filter';
import { getDateFilterFromTo, getDateFilterFromToDate } from 'src/utils/getDateFilterFromTo';
import { fDT } from 'src/utils/formatTime';
import useLocales from 'src/hooks/useLocales';

export const LIMIT_VIEWS = 5;
export type ViewsChangeReason = 'create' | 'edit' | 'delete' | 'selectOption' | 'pin' | 'rename' | 'duplicate';

export type Views = {
  defaultView: SavedView;
  savedViews: SavedViews;
};

export type SavedViews = {
  viewsCreatedByMe: SavedView[];
  viewsCreatedByOthers: SavedView[];
};

export type SavedView = {
  id: number;
  gridName: string;
  savedFilterName: string;
  savedFilterSetting: SavedFilterSetting;
  savedFilterPinned: boolean;
  savedFilterOrder: number;
  columnVisibilityModel?: GridColumnVisibilityModel;
  columnOrders?: string[];
};

export type SavedFilterSetting = {
  filtersMap: Partial<Filter>[];
  appliedFilters: AppliedFilter[];
  filtersState: FilterState;
};

export type ViewDialogEditMode = 'create' | 'rename' | 'duplicate';

export type ViewDialogProps = {
  open: boolean;
  mode: ViewDialogEditMode;
  view: Partial<SavedView>;
};

export type ViewsContextType = {
  hasViewsAccess: boolean;
  defaultView: SavedView | null;
  myViews: SavedView[];
  othersViews: SavedView[];
  pinnedViews: SavedView[];
  allViews: SavedView[];
  selectedView: SavedView | null;
  reachedToLimitViews: boolean,
  shouldShowSaveFiltersAsAViewButton: boolean,
  viewDialog: ViewDialogProps,
  initiateFiltersMapsValue: (
    filtersMap: Filter[],
    filtersState: FilterState
  ) => Filter[],
  setShouldShowSaveFiltersAsAViewButton: Dispatch<SetStateAction<boolean>>,
  setViewDialog: Dispatch<SetStateAction<ViewDialogProps>>,
  setPinnedViews: Dispatch<SetStateAction<SavedView[]>>,
  setSelectedView: Dispatch<SetStateAction<SavedView | null>>,
};

const initialState: ViewsContextType = {
  hasViewsAccess: false,
  defaultView: null,
  myViews: [],
  othersViews: [],
  pinnedViews: [],
  allViews: [],
  selectedView: null,
  reachedToLimitViews: false,
  shouldShowSaveFiltersAsAViewButton: false,
  viewDialog: { open: false, mode: 'create', view: {} },
  initiateFiltersMapsValue: () => [],
  setShouldShowSaveFiltersAsAViewButton: () => {},
  setPinnedViews: () => {},
  setSelectedView: () => {},
  setViewDialog: () => {},
};

export type ViewsProviderType = {
  views?: Views;
};

interface ViewsProviderProps extends ViewsProviderType {
  children: ReactNode;
}

const ViewsContext = createContext(initialState);

function ViewsProvider({ children, views }: ViewsProviderProps) {
  const { translate, currentLang, shortDateFormat } = useLocales('common');
  const [myViews, setMyViews] = useState<SavedView[]>([]);
  const [othersViews, setOthersViews] = useState<SavedView[]>([]);
  const [pinnedViews, setPinnedViews] = useState<SavedView[]>([]);
  const [allViews, setAllViews] = useState<SavedView[]>([]);
  const [selectedView, setSelectedView] = useState<SavedView | null>(views?.defaultView || null);
  const [viewDialog, setViewDialog] = useState<ViewDialogProps>({ open: false, mode: 'create', view: {} });
  const [shouldShowSaveFiltersAsAViewButton, setShouldShowSaveFiltersAsAViewButton] = useState<boolean>(false);

  const defaultView = views?.defaultView || null;
  const hasViewsAccess = views !== undefined;
  const reachedToLimitViews = pinnedViews.length === LIMIT_VIEWS;

  const formatDate = (date: string | Date | null, subDay = false) => date ? fDT({
    date: subDay ? subDays(typeof date === 'string' ? new Date(date) : date, 1) : date,
    pattern: shortDateFormat,
    options: { locale: currentLang.dateLocale }
  }) : '';

  const getDateLabel = (key: string, value: DateFilterValue) =>
    `${key}: ${formatDate(value?.from)} - ${formatDate(value?.to, true)}`;

  const getCustomAccordionDateLabel = (condition: string, value: { start: Date | null; end: Date | null }) =>
    `${translate(condition)} ${formatDate(value?.start)}-${formatDate(value?.end)}`

  useEffect(() => {
    setShouldShowSaveFiltersAsAViewButton(false);
  }, [selectedView]);

  useEffect(() => {
    const myViews = normalizeViews(views?.savedViews?.viewsCreatedByMe || []);
    const othersViews = normalizeViews(views?.savedViews?.viewsCreatedByOthers || []);
    const allViews = [...myViews, ...othersViews];
    const topPinnedViews = allViews.filter(view => view.savedFilterPinned).slice(0, LIMIT_VIEWS);

    setMyViews(myViews);
    setOthersViews(othersViews);
    setPinnedViews(topPinnedViews);
    setAllViews(allViews);
  }, [views?.savedViews]);

  const normalizeViews = (views: SavedView[]) => {
    const newViews = [...views];
    newViews.forEach((view) => {
      const { filtersMap, appliedFilters, filtersState } = view.savedFilterSetting;
      appliedFilters?.forEach((appliedFilter, index) => {
        const filter = filtersMap.find((filter) => filter.stateKey === appliedFilter.stateKey);
        // setting dynamic date label and value
        if (filter && filtersState.hasOwnProperty(appliedFilter.stateKey)) {
          if (filter.type === 'date-select') {
            const dateProperty = filtersState[appliedFilter.stateKey] as DateFilterValue;
            if (dateProperty && dateProperty.selectValue !== 'custom') {
              const { from, to } = getDateFilterFromTo(dateProperty.selectValue);
              Object.assign(dateProperty, { from: from, to: to });
            }
            // @ts-ignore
            appliedFilters[index] = {
              id: appliedFilter.stateKey,
              stateKey: appliedFilter.stateKey,
              label: getDateLabel(String(filter.title), dateProperty)
            };
          } else if (filter.type === 'custom-accordion' || filter.type === 'custom-accordion-date') {
            const customAccordionFilter = filtersState[appliedFilter.stateKey] as FilterValue;
            const isDateVariable = customAccordionFilter &&
              customAccordionFilter.value.condition === 'specific_date_range' &&
              customAccordionFilter.value.shortcut !== 'custom' &&
              customAccordionFilter.value.shortcut !== 'reset';
            if (isDateVariable) {
              const { from, to } = getDateFilterFromToDate(customAccordionFilter.value.shortcut);
              Object.assign(customAccordionFilter, {
                value: { ...customAccordionFilter.value, start: from, end: to ? subDays(to, 1) : to },
                label: getCustomAccordionDateLabel(customAccordionFilter.value.condition, customAccordionFilter.value),
              });
              appliedFilters[index] = {
                id: appliedFilter.stateKey,
                stateKey: appliedFilter.stateKey,
                label: getCustomAccordionDateLabel(customAccordionFilter.value.condition, customAccordionFilter.value)
              };
            } else if (customAccordionFilter.value.condition === 'specific_date_range' &&
              customAccordionFilter.value.shortcut === 'custom'
            ) {
              Object.assign(customAccordionFilter, {
                value: {
                  ...customAccordionFilter.value,
                  start: new Date(customAccordionFilter.value.start),
                  end: new Date(customAccordionFilter.value.end)
                },
              });
              appliedFilters[index] = {
                id: appliedFilter.stateKey,
                stateKey: appliedFilter.stateKey,
                label: customAccordionFilter.label
              };
            }
          }
        }
      });
    });
    return newViews;
  };

  const initiateFiltersMapsValue = (filtersMap: Filter[], filterState: FilterState) => {
    const newFiltersMap: Filter[] = [];
    filtersMap.forEach((filter: Filter) => {
      const initializedFilter: Filter = {
        ...filter,
        value: filterState[filter.stateKey] as any
      };
      newFiltersMap.push(initializedFilter)
    });
    return newFiltersMap;
  };

  return (
    <ViewsContext.Provider
      value={{
        hasViewsAccess,
        defaultView,
        myViews,
        othersViews,
        pinnedViews,
        allViews,
        selectedView,
        reachedToLimitViews,
        shouldShowSaveFiltersAsAViewButton,
        viewDialog,
        initiateFiltersMapsValue,
        setShouldShowSaveFiltersAsAViewButton,
        setPinnedViews,
        setSelectedView,
        setViewDialog,
      }}
    >
      {children}
    </ViewsContext.Provider>
  );
}

export { ViewsProvider, ViewsContext };
