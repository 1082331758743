import { getUnixTime } from 'date-fns';
import { dispatch } from 'src/redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MaintenanceTask, MaintenanceTaskDetail, MaintenanceTaskState, TaskPriority, TaskStatus } from 'src/@types/tasks';
import { SortDirection } from 'src/@types/booking-list';
import TasksAPI from 'src/api/tasks';

const initialState: MaintenanceTaskState = {
  loading: false,
  maintenanceTask: {
    view: null,
    list: [],
    rowCount: 0,
    gridConfigs: null,
  },
};

const slice = createSlice({
  name: 'maintenance-tasks',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMaintenanceTask(state, action) {
      state.maintenanceTask.view = action.payload;
    },
    setList(state, action: PayloadAction<MaintenanceTask[]>) {
      state.maintenanceTask.list = action.payload;
    },
    setRowCount(state, action: PayloadAction<number>) {
      state.maintenanceTask.rowCount = action.payload;
    },
    setGridConfigs(state, action) {
      state.maintenanceTask.gridConfigs = action.payload;
    },
    updateMaintenanceTask(state, action: PayloadAction<{ taskId: number, newValue: Partial<MaintenanceTask> }>) {
      const { taskId, newValue } = action.payload;
      const foundTaskIndex = state.maintenanceTask.list.findIndex((task) => task.id === taskId);
      if (foundTaskIndex >= 0) {
        state.maintenanceTask.list[foundTaskIndex] = {
          ...state.maintenanceTask.list[foundTaskIndex],
          ...newValue
        }
      }
    },
    updateMaintenanceTaskDetail(state, action: PayloadAction<{ newValue: Partial<MaintenanceTaskDetail> }>) {
      const { newValue } = action.payload;
      if (state.maintenanceTask.view) {
        state.maintenanceTask.view = {
          ...state.maintenanceTask.view,
          ...newValue
        }
      }
    },
  }
});

export default slice.reducer;

export const {
  setLoading,
  setRowCount,
  setGridConfigs,
  updateMaintenanceTask,
  updateMaintenanceTaskDetail,
} = slice.actions;

export function getMaintenanceTaskList({
  page,
  limit,
  filtersState,
  order,
  signal,
  advanceFilterParam,
}: {
  page: number;
  limit: number;
  filtersState: {};
  order?: { field: string; sort: SortDirection };
  signal?: AbortSignal;
  advanceFilterParam?: { key: string; value: string };
}) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await TasksAPI.fetchMaintenanceTasks({
        page,
        limit,
        order,
        signal,
        filtersState,
        advanceFilterParam,
      });
      dispatch(slice.actions.setList(response.data.data));
      dispatch(slice.actions.setRowCount(response.data.meta.total_items));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function getMaintenanceTask(maintenanceTaskId: number) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await TasksAPI.fetchTask(maintenanceTaskId);
      const { progress_status, status, assignee } = response.data;
      dispatch(slice.actions.setMaintenanceTask({
        ...response.data,
        status: progress_status?.toLowerCase() || 'not_started',
        isUnassigned: assignee.full_name.trim() === 'nobody',
        assignee: assignee.full_name.trim() === 'nobody' ? null : assignee,
        ...(status?.toLowerCase() === 'pending' && { startTime: response.data.updatedAt }),
      }));
    } catch (error) {
      console.error(error);
    }
    dispatch(slice.actions.setLoading(false));
  };
}

export function changeAssignee(
  maintenanceTaskId: number,
  housekeeperId: number | null,
  setLoading: (succeed: boolean) => void,
  callback: (succeed: boolean, response: any) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await TasksAPI.changeTaskAssignee(maintenanceTaskId, housekeeperId);
      dispatch(slice.actions.updateMaintenanceTaskDetail({
        newValue: {
          ...(response.data.full_name.trim() === 'nobody' ? {
            isUnassigned: true,
            assignee: null,
          } : {
            isUnassigned: false,
            assignee: {
              user_id: response.data.id,
              full_name: response.data.full_name,
              avatar_url: response.data.avatar_url,
            },
          }),
          updatedAt: getUnixTime(new Date()),
        }
      }));
      callback(true, response);
    } catch (error) {
      callback(false, error.data);
    } finally {
      setLoading(false);
    }
  };
}

export function changeTimeFrame(
  maintenanceTaskId: number,
  canStartFrom: string | null,
  mustFinishBy: string | null,
  setLoading: (succeed: boolean) => void,
  callback: (succeed: boolean, response: any) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await TasksAPI.changeTaskTimeFrame(maintenanceTaskId, canStartFrom, mustFinishBy);
      dispatch(slice.actions.updateMaintenanceTaskDetail({
        newValue: {
          can_start_from: response.data.can_start_from,
          must_finished_by: response.data.must_finish_by,
          updatedAt: getUnixTime(new Date()),
        }
      }));
      callback(true, response);
    } catch (error) {
      callback(false, error.data);
    } finally {
      setLoading(false);
    }
  };
}

export function changeStatus(
  maintenanceTaskId: number,
  status: TaskStatus,
  setLoading: (succeed: boolean) => void,
  callback: (succeed: boolean, response: any) => void,
  startedAt?: string | null,
  finishedAt?: string | null,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await TasksAPI.changeTaskStatus(maintenanceTaskId, status, startedAt, finishedAt, true);
      dispatch(slice.actions.updateMaintenanceTaskDetail({
        newValue: {
          status: response.data.progress_status?.toLowerCase(),
          maintenance_task_started_at: response.data.started_at,
          maintenance_task_finished_at: response.data.finished_at,
          updatedAt: getUnixTime(new Date()),
        }
      }));
      callback(true, response);
    } catch (error) {
      callback(false, error.data);
    } finally {
      setLoading(false);
    }
  };
}

export function changePriority(maintenanceTaskId: number, newPriority: TaskPriority) {
  return async () => {
    try {
      await TasksAPI.changeTaskPriority(maintenanceTaskId, newPriority);
      dispatch(slice.actions.updateMaintenanceTaskDetail({
        newValue: { priority: newPriority, updatedAt: getUnixTime(new Date()), }
      }));
    } catch (error) {
    }
  };
};

export function changeDescription(
  maintenanceTaskId: number,
  description: string | null,
  setLoading: (succeed: boolean) => void,
  callback: (succeed: boolean, response: any) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await TasksAPI.changeTaskDescription(maintenanceTaskId, description);
      dispatch(slice.actions.updateMaintenanceTaskDetail({
        newValue: { description: response.data.description, updatedAt: getUnixTime(new Date()), }
      }));
      callback(true, response);
    } catch (error) {
      callback(false, error.data);
    } finally {
      setLoading(false);
    }
  };
}

export function addAdditionalNote(
  maintenanceTaskId: number,
  noteText: string,
  setLoading: (succeed: boolean) => void,
  callback: (succeed: boolean, response: any) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await TasksAPI.addNote(maintenanceTaskId, noteText);
      callback(true, response);
    } catch (error) {
      callback(false, error.data);
    } finally {
      setLoading(false);
    }
  };
}

export function editAdditionalNote(
  maintenanceTaskId: number,
  noteId: number,
  note: string,
  setLoading: (succeed: boolean) => void,
  callback: (succeed: boolean, response: any) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await TasksAPI.editNote(maintenanceTaskId, noteId, note);
      callback(true, response);
    } catch (error) {
      callback(false, error.data);
    } finally {
      setLoading(false);
    }
  };
}

export function deleteAdditionalNote(
  maintenanceTaskId: number,
  noteId: number,
  setLoading: (succeed: boolean) => void,
  callback: (succeed: boolean, response: any) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await TasksAPI.deleteNote(maintenanceTaskId, noteId);
      callback(true, response);
    } catch (error) {
      callback(false, error.data);
    } finally {
      setLoading(false);
    }
  };
}