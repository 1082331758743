import {
  FilterApiKey,
  TaskApiParams,
  TaskPriority,
  TaskSetting,
  TaskStatus,
} from 'src/@types/tasks';
import axios from '../utils/axios';
import { utcToZonedTime } from 'date-fns-tz';
import { SortDirection } from 'src/@types/booking-list';
import { FilterValue } from 'src/@types/filter';

function tasksAPI() {
  return {
    fetchTaskStatus: () => axios.get('/apis/task/tasks_landing_page/get_task_status'),
    fetchNotifications: () => axios.get('/apis/task/tasks_landing_page/get_smart_notifications'),
    fetchTaskAmount: (from: string, to: string) => {
      const url = 'apis/tasks_landing_page/tasks_numbers';
      return axios.get(url, { params: { from, to } });
    },
    fetchFilterTypeOptions: () => axios.get('/apis/task/filter/type'),
    fetchFilterOptions: (filterApiKey: FilterApiKey, page: number, name: string, limit: number) => {
      const url = `/apis/filter/${filterApiKey}`;
      return axios.get(url, {
        params: {
          limit,
          page: page === undefined ? page : page + 1,
          name,
        },
      });
    },
    fetchUnassignedTasks: (days: number) => {
      const url = '/apis/task/tasks_landing_page/get_unassigned_tasks';
      return axios.get(url, { params: { days } });
    },
    fetchEarlyCheckInTasks: (days: number) => {
      const url = '/apis/task/tasks_landing_page/get_early_checkin_tasks';
      return axios.get(url, { params: { days } });
    },
    HousekeepingTasksSetting: (settings: TaskSetting[]) => {
      const url = `/apis/task_list/settings`;
      return axios.post(url, { key: 'settings', settings });
    },
    updateTaskAutoApproval: (brandId: number, autoApproval: boolean) => {
      return axios.post(`/apis/task/change_auto_approval`, {
        brand_id: brandId,
        auto_approval: autoApproval,
      });
    },
    updateTaskMandatoryHousekeepingPhotos: (
      brandId: number,
      mandatoryHousekeepingPhotos: boolean
    ) => {
      return axios.post(`/apis/task/change_mandatory_housekeeping_photos`, {
        brand_id: brandId,
        mandatory_housekeeping_photos: mandatoryHousekeepingPhotos,
      });
    },
    updateTaskMidStayHousekeepingPhotos: (brandId: number, midStayHousekeepingPhotos: number) => {
      return axios.post(`/apis/task/change_mid_stay_housekeeping_period`, {
        brand_id: brandId,
        mid_stay_housekeeping_period: midStayHousekeepingPhotos,
      });
    },
    fetchSetting: (key: string) =>
      axios.get('/apis/task_list/retrieve_settings', { params: { key } }),
    fetchOrganizationBrands: () => axios.get('/apis/organization/all_brands'),
    fetchHousekeepingTasksGridsCount: () => axios.get('/apis/count_housekeeping_task_list_grids'),
    fetchHousekeepingTasks: ({
      limit,
      page = 1,
      timeFrameOrder,
      showCancelled,
      showOnlyUnassigned,
      showOnlyEarlyCheckIn,
      showOnlyNotCompleted,
      booking,
      cities,
      property,
      unit,
      assignee,
      status,
      priority,
      type,
      taskType,
      dueDateFrom,
      dueDateTo,
      signal,
    }: TaskApiParams) => {
      const url = `/apis/v2/tasks/housekeeping?${type || ''}`;
      return axios.get(url, {
        signal,
        params: {
          page: page,
          limit: limit,
          type: taskType,
          'booking.id': booking,
          ...(Array.isArray(cities) && {
            'booking.property.area.city.id': cities.map((type) => type.value),
          }),
          'booking.property.id': property,
          'booking.unit.id': unit,
          'owner.id': assignee,
          progressStatus: status,
          priority: priority,
          ...(dueDateFrom && { 'not_sooner[after]': utcToZonedTime(dueDateFrom, 'UTC') }),
          ...(dueDateTo && { 'not_sooner[before]': utcToZonedTime(dueDateTo, 'UTC') }),
          'order[not_sooner]': timeFrameOrder,
          ...(showOnlyUnassigned && { unassigned: '' }),
          ...(showOnlyEarlyCheckIn && { early_checkin: '' }),
          'status[neq]': [
            ...(showOnlyNotCompleted ? ['completed'] : []),
            ...(showCancelled ? [] : ['cancelled']),
          ],
        },
      });
    },
    fetchTask: (taskId: number) => axios.get(`/apis/tasks_view/get_task_setting/${taskId}`),
    addNote: (taskId: number, text: string) => {
      const url = `/apis/tasks_view/add_note/${taskId}`;
      return axios.post(url, { text });
    },
    editNote: (taskId: number, noteId: number | null, text: string) => {
      const url = `/apis/tasks_view/edit_note/${taskId}/${noteId}`;
      return axios.put(url, { text });
    },
    deleteNote: (taskId: number, noteId: number) =>
      axios.delete(`/apis/tasks_view/note/${taskId}/${noteId}`),
    fetchHousekeepers: (page: number, search_key: string) => {
      const url = `/apis/housekeepers`;
      return axios.get(url, {
        params: { page, search_key },
      });
    },
    changeTaskPriority: (taskId: number, priority: TaskPriority) => {
      const url = `/apis/tasks_view/change_priority/${taskId}`;
      return axios.put(url, { priority });
    },
    changeTaskStatus: (
      taskId: number,
      status: TaskStatus,
      started_at?: string | null,
      finished_at?: string | null,
      isMaintenanceTask?: boolean
    ) => {
      const url = `/apis/tasks_view/change_status/${taskId}`;
      return axios.put(url, { status, started_at, finished_at, is_maintenance_task: isMaintenanceTask });
    },
    approveTask: (taskId: number) => {
      const url = `/apis/tasks_view/approve_task/${taskId}`;
      return axios.put(url, { approved: true });
    },
    fetchStaffList: (
      task_id?: number,
      property_id?: number,
      unit_id?: number,
      key: 'staff' | 'company' = 'staff'
    ) => {
      const url = task_id
        ? '/apis/tasks_view/assigned_to'
        : '/apis/tasks_view/create_new_task_staff';
      return axios.get(url, { params: { task_id, property_id, unit_id, key } });
    },
    changeTaskAssignee: (taskId: number, housekeeperId: number | null) => {
      const url = `/apis/tasks_view/change_assignee/${taskId}`;
      return axios.put(url, { id: housekeeperId });
    },
    changeTaskTimeFrame: (
      taskId: number,
      can_start_from: string | null,
      must_finish_by: string | null,
      estimation?: number
    ) => {
      const url = `/apis/tasks_view/change_time_frame/${taskId}`;
      return axios.put(url, { can_start_from, must_finish_by, estimation });
    },
    changeTaskDescription: (taskId: number, description: string | null) => {
      const url = `/apis/task/add_description/${taskId}`;
      return axios.put(url, { description });
    },
    addTaskAttachment: (taskId: number, fileIds: number[]) => {
      const url = '/apis/task/add_attachment';
      return axios.post(url, { task_id: taskId, file_ids: fileIds });
    },
    getBlockedDates: ({ page, limit = 50 }: { page: number, limit?: number }) => {
      const url = '/apis/block_dates';
      return axios.get(url, { params: { page: page + 1, limit } })
    },
    createTask: (body: {
      property_id: number;
      unit_id: number;
      can_start_from: string;
      must_finish_by: string;
      estimation: number;
      housekeeper_id?: number;
      priority: TaskPriority;
      note?: string;
    }) => axios.post('/apis/tasks_view/create_task', body),
    reopenTaskManually: (
      taskId: number,
      body: {
        can_start_from: string;
        must_finish_by: string;
        estimation: number;
        housekeeper_id?: number;
        priority: TaskPriority;
        note?: string;
      }
    ) => axios.put(`/apis/tasks_view/reopen_manually_task/${taskId}`, body),
    reopenTaskAutomatically: (taskId: number) =>
      axios.put(`/apis/tasks_view/reopen_automatically_task/${taskId}`),
    fetchMaintenanceTasks: ({
      page,
      limit,
      order,
      signal,
      filtersState,
      advanceFilterParam,
    }: {
      page: number;
      limit: number;
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      filtersState: {
        priorities?: FilterValue[];
        statuses?: FilterValue[];
        types?: FilterValue[];
        cities?: FilterValue[];
        show_completed?: boolean;
      };
      advanceFilterParam?: { key: string; value: string };
    }) =>
      axios.get('/apis/list/maintenanceTasks?maintenanceTask', {
        signal,
        params: {
          page,
          limit,
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(!Boolean(filtersState.show_completed) && {
            progressStatus: 'not_started',
          }),
          ...(Array.isArray(filtersState.statuses) &&
            filtersState.statuses.length > 0 && {
              progressStatus: filtersState.statuses.map((status) => status.value),
            }),
          ...(Array.isArray(filtersState?.priorities) && {
            priority: filtersState.priorities.map((priority) => priority.value),
          }),
          ...(Array.isArray(filtersState?.types) && {
            type: filtersState.types.map((type) => type.value),
          }),
          ...(Array.isArray(filtersState?.cities) && {
            'property.area.city.id': filtersState.cities.map((type) => type.value),
          }),
        },
      }),
    fetchTasks: ({
      page,
      limit,
      order,
      signal,
      filtersState,
      defaultFilters,
      advanceFilterParam,
      type,
      headers,
      responseType,
      fields
    }: {
      page?: number;
      limit?: number;
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      filtersState: {
        priorities?: FilterValue[];
        statuses?: FilterValue[];
        types?: FilterValue[];
        assignees?: FilterValue[];
        date?: {
          from?: string;
          to?: string;
        };
        task_types?: FilterValue[];
        cities?: FilterValue[];
        units?: FilterValue[];
        properties?: FilterValue[];
        show_completed?: boolean;
        show_cancelled?: boolean;
        show_unassigned?: boolean;
        show_early_check_in?: boolean;
      };
      defaultFilters?: {};
      advanceFilterParam?: { key: string; value: string };
      type?: 'payment_reminder' | 'housekeeping' | string;
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) =>
      axios.get(`/apis/v2/tasks/housekeeping${type ? '?' + type : ''}`, {
        signal,
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(defaultFilters && { ...defaultFilters }),
          ...(Array.isArray(filtersState.statuses) &&
            filtersState.statuses.length > 0 && {
              progressStatus: filtersState.statuses.map((status) => status.value),
            }),
          ...(Array.isArray(filtersState?.priorities) &&
            filtersState.priorities.length > 0 && {
              priority: filtersState.priorities.map((priority) => priority.value),
            }),
          ...(Array.isArray(filtersState?.types) &&
            filtersState.types.length > 0 && {
              type: filtersState.types.map((type) => type.value),
            }),
          ...(Array.isArray(filtersState?.assignees) && filtersState.assignees.length > 0 && {
            'owner.id': filtersState.assignees.map((assignee) => assignee.id),
          }),
          ...(Array.isArray(filtersState?.task_types) && filtersState.task_types.length > 0 && {
            type: filtersState.task_types.map((taskType) => taskType.id),
          }),
          ...(Array.isArray(filtersState?.cities) && filtersState.cities.length > 0 && {
            'booking.property.area.city.id': filtersState.cities.map((city) => city.id),
          }),
          ...(Array.isArray(filtersState?.units) && filtersState.units.length > 0 && {
            'booking.unit.id': filtersState.units.map((unit) => unit.id),
          }),
          ...(Array.isArray(filtersState?.properties) && filtersState.properties.length > 0 && {
            'booking.property.id': filtersState.properties.map((property) => property.id),
          }),
          ...(filtersState?.date && { 'not_sooner[after]': filtersState?.date?.from }),
          ...(filtersState?.date && { 'not_sooner[before]': filtersState?.date?.to }),
          ...(Boolean(filtersState.show_completed) && {
            progressStatus: 'completed',
          }),
          ...(Boolean(filtersState.show_cancelled) && {
            progressStatus: 'cancelled',
          }),
          ...(Boolean(filtersState.show_unassigned) && {
            unassigned: '',
          }),
          ...(Boolean(filtersState.show_early_check_in) && {
            early_checkin: '',
          }),
          ...(fields && { fields }),
        },
        ...(headers && { headers }),
        ...(responseType && { responseType }),
      }),

    addDescription: (task_id: number, description: string) =>
      axios.put(`/apis/task/add_description/${task_id}`, { description }),
  };
}

export default tasksAPI();
