import ReactQuill from 'react-quill';
import { Variable } from 'src/@types/common';
import boldPartOfText from './boldPartOfText';
import { replaceNewLineInStringWithBrTag } from './stringUtils';

export const initialMentionConfig = {
  isolateCharacter: true,
  allowedChars: /^[A-Za-z\sÅÄÖåäö-]*$/,
  renderItem: (item, searchTerm) => boldPartOfText(item.name, searchTerm),
  onSelect: (item, insertItem) => {},
  source: (searchTerm, renderList, mentionChar) => {},
};

function isInsideIfBlock(text) {
  const startRegex = /{% if/g;
  const endRegex = /{% if.*?%}/g;
  let depth = 0;
  let match;
  while ((match = startRegex.exec(text)) !== null) {
    depth++;
  }
  while ((match = endRegex.exec(text)) !== null) {
    depth--;
  }
  return depth > 0;
}

export const setVariablesMentionConfig = (ref: ReactQuill, variables: Variable[]) => {
  const quill = ref.getEditor();
  const mention = quill?.getModule('mention');
  if (mention) {
    mention.options.onSelect = (item, insertItem) => {
      const foundVariable = variables.find((variable) => variable.value === item.value);
      if (foundVariable) {
        const { name, value } = foundVariable;
        insertItem({ type: 'variable', denotationChar: '@', value: name, variable: value });
      }
    };
    mention.options.source = (searchTerm, renderList, mentionChar) => {
      // Full text in Editor
      const currentText = quill?.getText();
      // Cursor inder
      const cursorPosition = quill.getSelection()?.index;
      // Index of cursor
      const textUntilCursor = currentText?.slice(0, cursorPosition);

      const ifOpenRegex = /{%\s*if[^%}]*%}(?![\s\S]*?{%\s*endif\s*%})/;
      const isIfBlockOpen = isInsideIfBlock(textUntilCursor);
      //
      const matches = variables.filter((item) => {
        if (isIfBlockOpen) {
          return (
            item.value?.startsWith('(') &&
            item.name?.toLowerCase().includes(searchTerm.toLowerCase())
          );
        } else {
          return (
            item.value?.startsWith('{{') &&
            item.name?.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
      });
      renderList(matches, searchTerm);
    };
  }
};

export const insertVariableOfDropdown = (quillRef, selectedVariable) => {
  const quill = quillRef?.current?.getEditor();
  const mention = quill?.getModule('mention');
  const { name, value } = selectedVariable;
  mention.insertItem(
    {
      type: 'variable',
      denotationChar: '@',
      value: name,
      variable: value,
    },
    true
  );
};

export const getIdOfVariableFromValue = (variableValue: string) =>
  variableValue.replace('{{ entity | ', '').split(' ')[0];
export const getVariableFromIdOfVariable = (variableId: string, variables: Variable[]) =>
  variables.find((variable) => variable.value.includes(`entity | ${variableId}`));

export const generateVariableElement = (variable: Variable) => {
  const { value, name } = variable;
  const element = `<span class="mention" data-type="variable" data-denotation-char="@" data-value="${name}" data-variable="${value}"><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${variable.name}</span></span>`;
  return element;
};

export const getSpecificVariable = (variables: Variable[], variableValue: string) => {
  const specificVariable = variables.find((variable) => variable.value === variableValue);
  return specificVariable;
};

export const removeNewLineFromEntity = (content: string) =>
  content.replace(/{{([\s\S]*?)}}/g, (matched) => matched.replace(/\n/g, ' '));

const checkCondition = (strippedContent, variables) => {
  //
  const conditionRegex =
    /{%\s*if\s+(@{[^}]+}|'[^']+')\s*(==|!=|>|<|>=|<=)\s+(@{[^}]+}|'[^']+')\s*%}/;
  const match = strippedContent.match(conditionRegex);
  if (match) {
    let variable1 = match[1];
    let operator = match[2];
    let variable2 = match[3];

    if (variable1.startsWith('@')) {
      const variableName = variable1.replace('@', '');
      const foundVariable = variables.find((variable) => variable.name === variableName);

      if (foundVariable) {
        variable1 = foundVariable.example || variable1;
      }
    } else {
      variable1 = variable1.replace(/'/g, '');
    }
    if (variable2.startsWith('@')) {
      const variableName = variable2.replace('@', '');
      const foundVariable = variables.find((variable) => variable.name === variableName);
      if (foundVariable) {
        variable2 = foundVariable.example || variable2;
      }
    } else {
      variable2 = variable2.replace(/'/g, '');
    }
    if (variable1 == variable2) {
      return true;
    }
  }

  return false;
};

export const makeContentPreviewable = (content: string, variables: Variable[]) => {
  let convertedContent = content;
  const ifRegex = /{% if[\s\S]*?%}([\s\S]*?){% endif %}/g;
  let match;
  while ((match = ifRegex.exec(content)) !== null) {
    const fullMatch = match[0];
    const innerContent = match[1];
    const strippedContent = fullMatch.replace(/<[^>]+>/g, '').trim();
    let conditionMet = checkCondition(strippedContent, variables);
    if (conditionMet) {
      convertedContent = convertedContent.replace(fullMatch, innerContent);
    } else {
      convertedContent = convertedContent.replace(fullMatch, '');
    }
  }
  if (content.indexOf('data-type="variable"') > -1) {
    const div = document.createElement('div');
    div.innerHTML = content;
    const variableElements = div.querySelectorAll('[data-type="variable"]');
    variableElements.forEach((variableElement) => {
      const variableValue = variableElement.getAttribute('data-variable') || '';
      const variable = getSpecificVariable(variables, variableValue);
      let element = '';
      if (variable) {
        const { example } = variable;
        if (example) element = example;
      }
      convertedContent = convertedContent.replace(variableElement.outerHTML, element);
    });
  }
  return convertedContent;
};

export const replaceContentVariablesWithEntity = (content: string, removeNextLines = false) => {
  let convertedContent = removeNextLines ? content.replace(/\n/g, '') : content;
  if (content.includes('data-type="variable"')) {
    const div = document.createElement('div');
    div.innerHTML = content;
    const variableElements = div.querySelectorAll('[data-type="variable"]');
    variableElements.forEach((variableElement) => {
      const variableValue = variableElement.getAttribute('data-variable') || '';
      convertedContent = convertedContent.replace(variableElement.outerHTML, variableValue);
    });
  }
  return convertedContent;
};
export const replaceContentEntitiesWithVariables = (content: string, variables: Variable[]) => {
  let convertedContent = content;
  if (content.includes('{{') || content.includes('(')) {
    convertedContent = removeNewLineFromEntity(convertedContent);
    const entityRegex = /({{[\s\S]*?}}|\([\s\S]*?\))/g;
    convertedContent = convertedContent.replace(entityRegex, (matched) => {
      let variable;
      // Check if variable value format is: {{ ... }}
      if (matched.startsWith('{{') && matched.endsWith('}}')) {
        variable = variables.find((variable) => variable.value === matched);
      }
      //Check if variable value format is: ( ... )
      else if (matched.startsWith('(') && matched.endsWith(')')) {
        variable = variables.find((variable) => variable.value === matched);
      }
      if (variable) return generateVariableElement(variable);
      return matched;
    });
  }
  convertedContent = replaceNewLineInStringWithBrTag(convertedContent);
  // Addinng div tag
  convertedContent =
    convertedContent && !convertedContent.trim().startsWith('<')
      ? `<div>${convertedContent}</div>`
      : convertedContent;
  return convertedContent;
};
