import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import {
  CircularProgress,
  Tooltip,
  Box,
  ButtonProps,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  IconButtonProps,
  MenuProps,
  MenuItemProps,
  StackProps,
  TooltipProps,
  LinkProps,
  Link as MuiLink,
  Collapse,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import useLocales from 'src/hooks/useLocales';
import { styled } from '@mui/material/styles';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

// ----------------------------------------------------------------------
const ButtonStyle = styled(LoadingButton)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 600,
  minWidth: 'auto',
  letterSpacing: 1.25,
}));

interface ActionFunction {
  (event?: React.MouseEvent): void;
}

export type ActionType = {
  type: 'primary' | 'more';
  tooltipTitle?: string;
  tooltipProps?: Partial<TooltipProps>;
  label: React.ReactElement | string;
  onAction: VoidFunction | ActionFunction | undefined;
  disabled?: boolean;
  hidden?: boolean;
  MenuItemProps?: MenuItemProps;
  ButtonProps?: LoadingButtonProps;
  href?: string;
  LinkProps?: LinkProps;
};

export type CardActionProps = {
  actions: ActionType[];
  mode?: 'normal' | 'collapsed';
  MenuProps?: Partial<MenuProps>;
  IconButtonProps?: IconButtonProps;
  ButtonProps?: ButtonProps;
  StackProps?: StackProps;
  moreButtonLabel?: string;
  loading?: boolean;
  showEndIcon?: boolean;
  disabledDivider?: boolean;
  iconSize?: number;
};

const CustomMenuItem = ({
  label,
  tooltipTitle,
  tooltipProps,
  disabled,
  href,
  LinkProps,
  onAction,
  MenuItemProps,
  onClick,
}: { onClick: React.MouseEventHandler<HTMLLIElement> } & ActionType) => {
  return (
    <Tooltip title={tooltipTitle || ''} arrow {...tooltipProps}>
      <Box component="span">
        <MenuItem
          disabled={disabled}
          onClick={(event) => {
            onAction?.();
            onClick(event);
          }}
          {...MenuItemProps}
        >
          {href ? (
            <MuiLink
              component={Link}
              to={href}
              color="text.primary"
              underline="none"
              {...LinkProps}
            >
              {label}
            </MuiLink>
          ) : (
            label
          )}
        </MenuItem>
      </Box>
    </Tooltip>
  );
};

const ITEM_HEIGHT = 36;
const MAX_HEIGHT = (itemsCount: number) => ITEM_HEIGHT * itemsCount + 48;

const CardAction = ({
  actions,
  mode = 'normal',
  showEndIcon = false,
  loading,
  disabledDivider,
  iconSize = 20,
  MenuProps,
  IconButtonProps,
  ButtonProps,
  StackProps,
  moreButtonLabel,
}: CardActionProps) => {
  const { translate } = useLocales('common');
  const primaryActions = actions.filter((action) => action.type === 'primary' && !action?.hidden);
  const moreActions = actions.filter((action) => action.type !== 'primary' && !action?.hidden);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMoreEl, setAnchorMoreEl] = React.useState<null | HTMLElement>(null);
  const [collapsedMoreActionsAnchorEl, setCollapsedMoreActionsAnchorEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMore = Boolean(anchorMoreEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorMoreEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorMoreEl(null);
    setAnchorEl(null);
    setCollapsedMoreActionsAnchorEl(null);
  };

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" color="action.active" p={1}>
        <CircularProgress color="inherit" size={iconSize} />
      </Stack>
    );
  }

  if (actions.filter((action) => !action?.hidden).length === 0) return null;

  if (mode === 'collapsed' && actions.length > 0) {
    return (
      <Box>
        <IconButton onClick={handleOpenMenu} {...IconButtonProps}>
          <Iconify icon={'mdi:dots-vertical'} height={iconSize} width={iconSize} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            style: {
              maxHeight: MAX_HEIGHT(6),
              width: '20ch',
            },
          }}
          {...MenuProps}
        >
          {moreButtonLabel ? (
            <Stack spacing={1}>
              {primaryActions.map((action, index) => (
                <CustomMenuItem key={index} {...action} onClick={handleClose} />
              ))}
              {moreActions.length > 0 && (
                <MenuItem
                  onClick={(event) =>
                    setCollapsedMoreActionsAnchorEl((prev) => (!!prev ? null : event.currentTarget))
                  }
                  sx={{ ...(collapsedMoreActionsAnchorEl && { color: 'text.secondary' }) }}
                >
                  {moreButtonLabel}
                </MenuItem>
              )}
            </Stack>
          ) : (
            <Stack spacing={1}>
              {actions.map((action, index) =>
                !action?.hidden ? (
                  <CustomMenuItem key={index} {...action} onClick={handleClose} />
                ) : null
              )}
            </Stack>
          )}
        </Menu>
        <Menu
          anchorEl={collapsedMoreActionsAnchorEl}
          open={!!collapsedMoreActionsAnchorEl}
          onClose={() => setCollapsedMoreActionsAnchorEl(null)}
          anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          transformOrigin={{ vertical: 'center', horizontal: 'right' }}
          PaperProps={{
            style: {
              maxHeight: MAX_HEIGHT(6),
              width: '20ch',
            },
          }}
          {...MenuProps}
        >
          {moreActions.map((action, index) => (
            <CustomMenuItem key={index} {...action} onClick={handleClose} />
          ))}
        </Menu>
      </Box>
    );
  }

  return (
    <Box>
      <Stack direction={'row'} spacing={1} {...StackProps}>
        {primaryActions.map((action, index) => (
          <Tooltip key={index} title={action.tooltipTitle || ''} arrow {...action.tooltipProps}>
            <Box component="span">
              <ButtonStyle
                color="inherit"
                disabled={action.disabled}
                onClick={action.onAction}
                {...ButtonProps}
                {...action.ButtonProps}
              >
                {action.label}
              </ButtonStyle>
            </Box>
          </Tooltip>
        ))}

        {primaryActions.length > 0 && moreActions.length > 0 && !disabledDivider && (
          <Divider orientation="vertical" variant="middle" flexItem />
        )}

        {moreActions.length > 0 && (
          <Box>
            <ButtonStyle
              color="inherit"
              onClick={handleOpenMoreMenu}
              endIcon={
                showEndIcon ? (
                  <Iconify icon={`mdi:chevron-${openMore ? 'up' : 'down'}`} />
                ) : undefined
              }
              {...ButtonProps}
              className="more-button"
            >
              {moreButtonLabel || translate('more')}
            </ButtonStyle>
            <Menu
              anchorEl={anchorMoreEl}
              open={openMore}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: MAX_HEIGHT(5),
                },
              }}
              {...MenuProps}
            >
              <Stack spacing={1}>
                {moreActions.map((action, index) => (
                  <CustomMenuItem key={index} {...action} onClick={handleClose} />
                ))}
              </Stack>
            </Menu>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default CardAction;
