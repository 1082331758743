import { ComponentType, ReactNode } from 'react';
import format from 'date-fns/format';
// @mui
import {
  DateRangePickerDay as MuiDateRangePickerDay,
  DateRangePickerDayProps,
} from '@mui/x-date-pickers-pro/DateRangePickerDay';
import { alpha, styled } from '@mui/material/styles';
import { ButtonProps, Tooltip } from '@mui/material';
// theme
import { ColorSchema } from 'src/theme/palette';

type Props = {
  isStart: boolean;
  isEnd: boolean;
  isHighlight: boolean;
  isSingle: boolean;
  status: 'default' | ColorSchema;
};

type CustomDateOption = {
  date: Date;
  isStart: boolean;
  isEnd: boolean;
  isHighlight: boolean;
  status: 'default' | ColorSchema;
  isSingle: boolean;
  tooltip: ReactNode | string;
};

const DateRangePickerDay = styled(MuiDateRangePickerDay, {
  shouldForwardProp: (prop) =>
    prop !== 'isStart' &&
    prop !== 'isEnd' &&
    prop !== 'isHighlight' &&
    prop !== 'isSingle' &&
    prop !== 'status',
})<Props>(
  ({
    theme,
    isStartOfHighlighting,
    isEndOfHighlighting,
    isHighlighting,
    isStart,
    isEnd,
    isHighlight,
    isSingle,
    status,
  }) => ({
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
    ...(isHighlighting && {
      backgroundColor:
        status === 'default' ? theme.palette.grey['200'] : alpha(theme.palette[status].main, 0.08),
      '.MuiButtonBase-root': {
        color:
          isSingle && status !== 'default'
            ? theme.palette[status].dark
            : theme.palette.text.primary,
      },
    }),
    '& .MuiDateRangePickerDay-rangeIntervalDayPreview': {
      backgroundColor:
        status === 'default' ? theme.palette.grey['200'] : theme.palette[status].lighter,
    },
    '& .MuiPickersDay-today': {
      border: 'none !important',
      backgroundColor: theme.palette.background.neutral,
    },
    '&:first-of-type': {
      borderTopLeftRadius: '20%',
      borderBottomLeftRadius: '20%',
    },
    '&:last-of-type': {
      borderTopRightRadius: '20%',
      borderBottomRightRadius: '20%',
    },
    '& .MuiDateRangePickerDay-day': {
      '&:hover': {
        backgroundColor: 'transparent',
        ...(status !== 'default' &&
          status !== 'primary' && {
            borderColor:
              status === 'warning' ? theme.palette[status].dark : theme.palette[status].light,
            color: theme.palette[status].dark,
          }),
        ...(status === 'default' && {
          borderColor: `${theme.palette.grey['500']} !important`,
          color: theme.palette.text.secondary,
        }),
        ...(status === 'primary' && {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
        }),
        borderWidth: '1px !important',
        borderStyle: 'solid !important',
      },
    },
    '& .MuiDateRangePickerDay-day.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    ...(isSingle && {
      '.MuiButtonBase-root': {
        color: status === 'default' ? theme.palette.text.secondary : theme.palette[status].dark,
      },
    }),
    ...(isStart && {
      '.MuiButtonBase-root': {
        color: `${theme.palette.common.white} !important`,
      },
      '.MuiDateRangePickerDay-rangeIntervalPreview': {
        borderRadius: '50%',
        ...(status !== 'default' &&
          status !== 'primary' && {
            backgroundColor:
              status === 'warning' ? theme.palette[status].dark : theme.palette[status].light,
          }),
        ...(status === 'default' && { backgroundColor: theme.palette.grey['500'] }),
        ...(status === 'primary' && { backgroundColor: theme.palette.primary.main }),
        '.MuiButtonBase-root': {
          color: theme.palette.common.white,
        },
        '& .MuiDateRangePickerDay-day.Mui-selected': {
          backgroundColor: 'transparent',
        },
        '& .MuiPickersDay-today': {
          backgroundColor: 'transparent',
        },
      },
    }),
    ...(isEnd && {
      '.MuiDateRangePickerDay-rangeIntervalPreview': {
        borderRadius: '50%',
        ...(status !== 'default' &&
          status !== 'primary' && {
            backgroundColor:
              status === 'warning' ? theme.palette[status].dark : theme.palette[status].light,
          }),
        ...(status === 'default' && { backgroundColor: theme.palette.grey['500'] }),
        ...(status === 'primary' && { backgroundColor: theme.palette.primary.main }),
        '.MuiButtonBase-root': {
          color: theme.palette.common.white,
        },
        '& .MuiDateRangePickerDay-day.Mui-selected': {
          backgroundColor: 'transparent',
        },
        '& .MuiPickersDay-today': {
          backgroundColor: 'transparent',
        },
      },
    }),
    ...(isHighlight && {
      backgroundColor:
        status === 'default' ? theme.palette.grey['200'] : alpha(theme.palette[status].main, 0.08),
      ...(isStart && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      }),
      ...(isEnd && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      }),
      '& .MuiDateRangePickerDay-rangeIntervalDayPreview': {
        backgroundColor: 'transparent',
      },
      '& .MuiDateRangePickerDay-rangeIntervalPreview': {
        border: '2px solid transparent !important',
      },
    }),
  })
) as ComponentType<DateRangePickerDayProps<Date> & Props>;

const CustomDateRangePickerDay = (
  props: DateRangePickerDayProps<Date> & {
    customDates?: CustomDateOption[];
    ButtonProps?: ButtonProps;
  }
) => {
  const { customDates = [], day, outsideCurrentMonth, ButtonProps, ...other } = props;
  const formattedDate = format(day ?? new Date(), 'yyyy-MM-dd');
  const customDate = customDates.find((item) => format(item.date, 'yyyy-MM-dd') === formattedDate);
  const tooltipMsg =
    customDates.find((item) => format(item.date, 'yyyy-MM-dd') === formattedDate)?.tooltip || '';

  return (
    <Tooltip title={tooltipMsg} placement="top" arrow>
      <DateRangePickerDay
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        isStart={customDate !== undefined && customDate.isStart && !outsideCurrentMonth}
        isEnd={customDate !== undefined && customDate.isEnd && !outsideCurrentMonth}
        isHighlight={customDate !== undefined && customDate.isHighlight && !outsideCurrentMonth}
        isSingle={customDate !== undefined && customDate.isSingle && !outsideCurrentMonth}
        status={customDate?.status || 'primary'}
        {...other}
        {...ButtonProps}
      />
    </Tooltip>
  );
};

export default CustomDateRangePickerDay;
