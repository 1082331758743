import axios from '../utils/axios';
import { Meta } from 'src/@types/common';
import { RateRule, RateRuleCreateBody, RateRulesParams } from 'src/@types/rate-rule';

function rateRuleAPI() {
  return {
    getRateRules: (params: RateRulesParams, signal?: AbortSignal) => (
      axios.get<{ data: RateRule[], meta: Meta }>('/apis/rate_rules', { params, signal })
    ),
    getRateRule: (id: number) => (
      axios.get<RateRule>(`/apis/rate_rules/${id}`)
    ),
    createRateRule: (RateRuleCreateBody: RateRuleCreateBody) => (
      axios.post<{ data: RateRule[], meta: Meta }>('/apis/rate_rules', RateRuleCreateBody)
    ),
    updateRateRule: (id: number, body: Partial<RateRuleCreateBody>) => (
      axios.put<RateRule>(`/apis/rate_rules/${id}`, body)
    ),
  };
}

export default rateRuleAPI();