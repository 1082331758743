import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VariableApiType } from 'src/@types/common';
import {
  Agreement,
  AgreementConfig,
  ConfirmationSetting,
  ConfirmationSettingItem,
  CustomQuestionTemplate,
  DocumentUploadItemTemplate,
  EditableCustomQuestion, PaymentMethod,
  Preferences,
  Tab,
  TabsData,
  UpdatableCategory,
  UpdatableConfirmationSetting,
  UpdateDocumentUploadItemTemplate,
  Variables
} from 'src/@types/confirmation-page-settings';
import confirmationPageSettingsAPI from 'src/api/confirmation-page-settings';
import { provideVariables } from 'src/utils/automatedMessage';
import { dispatch } from '../store';
import { AxiosResponse } from 'axios';
import { FooterTemplate } from 'src/@types/automated-message';

const getSettingState = (state, categoryId, confirmationSettingId) => {
  let oldSetting;
  let currentConfirmationTabIndex;
  let categoryIndex;
  let settingIndex;
  const currentConfirmationTab = state.confirmationTabs.find((tab) => tab.title === state.currentTab);
  if (currentConfirmationTab) {
    const category = currentConfirmationTab.categories.find((category) => category.id === categoryId);
    if (category) {
      oldSetting = category.confirmation_settings.find((setting) => setting.id === confirmationSettingId);
      if (oldSetting) {
        currentConfirmationTabIndex = state.confirmationTabs.indexOf(currentConfirmationTab);
        categoryIndex = currentConfirmationTab.categories.indexOf(category);
        settingIndex = category.confirmation_settings.indexOf(oldSetting);
      }
    }
  }
  return {
    oldSetting,
    currentConfirmationTabIndex,
    categoryIndex,
    settingIndex,
  };
}

const getCategoryState = (state, categoryId) => {
  let oldCategory;
  let currentConfirmationTabIndex;
  let categoryIndex;
  const currentConfirmationTab = state.confirmationTabs.find((tab) => tab.title === state.currentTab);
  if (currentConfirmationTab) {
    oldCategory = currentConfirmationTab.categories.find((category) => category.id === categoryId);
    if (oldCategory) {
        currentConfirmationTabIndex = state.confirmationTabs.indexOf(currentConfirmationTab);
        categoryIndex = currentConfirmationTab.categories.indexOf(oldCategory);
    }
  }
  return {
    currentConfirmationTabIndex,
    categoryIndex,
    oldCategory,
  };
}

// ----------------------------------------------------------------------
type ConfirmationState = {
  tabsOrder: Tab[];
  currentTab: Tab;
  confirmationTabs: TabsData[];
  agreements: Agreement[];
  agreementConfig: AgreementConfig | null;
  selectedBrandId: number | null;
  accessBrandIds: number[];
  variables: Variables;
  hasClickSend: boolean;
  documentUploadItemTemplates: DocumentUploadItemTemplate[];
  customQuestionTemplates: CustomQuestionTemplate[];
  preferences: Preferences[];
  preferencesLoading: boolean;
};

const initialState: ConfirmationState = {
  tabsOrder: ['general_info', 'guest_info', 'agreement', 'upsells_&_payment', 'security_deposit', 'success_page'],
  currentTab: 'general_info',
  selectedBrandId: null,
  hasClickSend: false,
  documentUploadItemTemplates: [],
  customQuestionTemplates: [],
  agreements: [],
  agreementConfig: null,
  confirmationTabs: [],
  accessBrandIds: [41],
  variables: {
    agreementVariables: {
      header: [],
      body: [],
      footer: [],
    },
    depositVariables: [],
    paymentVariables: [],
  },
  preferences: [],
  preferencesLoading: false

};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setTabsOrder(state, action) {
      state.tabsOrder = action.payload;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setTabsData(state, action) {
      state.confirmationTabs = action.payload;
    },
    setSelectedBrandId(state, action) {
      state.selectedBrandId = action.payload;
    },
    setHasClickSend(state, action) {
      state.hasClickSend = action.payload;
    },
    setDocumentUploadItemTemplates(state, action) {
      state.documentUploadItemTemplates = action.payload;
    },
    addDocumentUploadItemTemplate(state, action) {
      state.documentUploadItemTemplates = [...state.documentUploadItemTemplates, action.payload];
    },
    updateDocumentUploadItemTemplate(state, action) {
      const foundItemTemplate = state.documentUploadItemTemplates.find((template) => template.id === action.payload.id);
      if (foundItemTemplate) {
        const index = state.documentUploadItemTemplates.indexOf(foundItemTemplate);
        if (index > -1) {
          state.documentUploadItemTemplates[index] = {
            ...state.documentUploadItemTemplates[index],
            ...action.payload.item,
          };
        }
      }
    },
    deleteDocumentUploadItemTemplate(state, action) {
      state.documentUploadItemTemplates = state.documentUploadItemTemplates.filter((template) => template.id !== action.payload);
    },
    seCustomQuestionTemplates(state, action: PayloadAction<CustomQuestionTemplate[]>) {
      state.customQuestionTemplates = action.payload;
    },
    addCustomQuestionTemplate(state, action: PayloadAction<CustomQuestionTemplate>) {
      state.customQuestionTemplates = [...state.customQuestionTemplates, action.payload];
    },
    updateCustomQuestionTemplate(state, action: PayloadAction<{ id: number, data: CustomQuestionTemplate }>){
      const { id, data } = action.payload; 
      const index = state.customQuestionTemplates.findIndex(template => template.id === id);
      if (index > -1) {
        state.customQuestionTemplates[index] = {
          ...state.customQuestionTemplates[index],
          ...data,
        };
      }
    },
    deleteCustomQuestionTemplate(state, action: PayloadAction<number>) {
      state.customQuestionTemplates = state.customQuestionTemplates.filter(template => template.id !== action.payload);
      //delete same custom setting by this template
      state.confirmationTabs.forEach((tab, tabIndex) => {
        tab.categories.forEach((category, categoryIndex) => {
          category.confirmation_settings.forEach((setting, settingIndex) => {
            if (setting.is_custom && setting.custom_question_template?.id === action.payload) {
              state.confirmationTabs[tabIndex].categories[categoryIndex].confirmation_settings.splice(settingIndex, 1);
            }
          })
        })
      })
    },
    setAgreementIsEnabled(state, action) {
      const agreementTab = state.confirmationTabs.find((tab) => tab.title === state.currentTab);
      if (agreementTab) {
        const index = state.confirmationTabs.indexOf(agreementTab);
        if (index) {
          state.confirmationTabs[index] = {
            ...state.confirmationTabs[index],
            is_enabled: action.payload,
          }
        }
      }
    },
    setAgreementConfig(state, action) {
      state.agreementConfig = action.payload;
    },
    setAgreementValue(state, action: PayloadAction<{
      propertyToChange: 'except_channels' | 'all_booking' | 'lead_guest_signed' | 'agreement_template',
      value: any,
    }>) {
      const agreementTab = state.confirmationTabs.find((tab) => tab.title === state.currentTab);
      if (agreementTab) {
        const index = state.confirmationTabs.indexOf(agreementTab);
        if (index) {
          if (state.agreementConfig) {
            state.agreementConfig = {
              ...state.agreementConfig,
              [action.payload.propertyToChange]: action.payload.value,
            }
          }
        }
      }
    },
    setAgreements(state, action) {
      state.agreements = action.payload;
    },
    addAgreement(state, action) {
      state.agreements = [...state.agreements, action.payload];
    },
    updateAgreement(state, action) {
      const foundAgreement = state.agreements.find((agreement) => agreement.id === action.payload.id);
      if (foundAgreement) {
        const index = state.agreements.indexOf(foundAgreement);
        if (index > -1) {
          state.agreements[index] = {
            ...state.agreements[index],
            ...action.payload.agreement,
          };
        }
      }
    },
    deleteAgreement(state, action) {
      state.agreements = state.agreements.filter((agreement) => agreement.id !== action.payload);
    },
    updateSetting(
      state,
      action: PayloadAction<{
        categoryId: number,
        confirmationSettingId: number,
        setting: UpdatableConfirmationSetting,
      }>
    ) {
      const {
        oldSetting,
        currentConfirmationTabIndex,
        categoryIndex,
        settingIndex,
      } = getSettingState(state, action.payload.categoryId, action.payload.confirmationSettingId);
      let updatedSetting = { ...oldSetting, ...action.payload.setting };
      state.confirmationTabs[currentConfirmationTabIndex].categories[categoryIndex].confirmation_settings[settingIndex] = updatedSetting;
    },
    updateSettingValidation(
      state,
      action: PayloadAction<{
        categoryId: number,
        confirmationSettingId: number,
        validationIndex: number,
        validation: { is_checked : boolean },
      }>
    ) {
      const {
        oldSetting,
        currentConfirmationTabIndex,
        categoryIndex,
        settingIndex,
      } = getSettingState(state, action.payload.categoryId, action.payload.confirmationSettingId);
      state.confirmationTabs[currentConfirmationTabIndex].categories[categoryIndex].confirmation_settings[settingIndex].validations[action.payload.validationIndex] = {
        ...oldSetting.validations[action.payload.validationIndex],
        ...action.payload.validation,
      };
    },
    updateCategory(
      state,
      action: PayloadAction<{
        categoryId: number,
        category: UpdatableCategory,
      }>
    ) {
      const { currentConfirmationTabIndex, categoryIndex, oldCategory } = getCategoryState(state, action.payload.categoryId);
      state.confirmationTabs[currentConfirmationTabIndex].categories[categoryIndex] = {
        ...oldCategory,
        ...action.payload.category,
      };
    },
    deleteSettingItem(
      state,
      action: PayloadAction<{
        categoryId: number,
        confirmationSettingId: number,
        itemId: number,
      }>
    ) {
      const {
        currentConfirmationTabIndex,
        categoryIndex,
        settingIndex,
      } = getSettingState(state, action.payload.categoryId, action.payload.confirmationSettingId);
      state.confirmationTabs[currentConfirmationTabIndex].categories[categoryIndex].confirmation_settings[settingIndex].items =
        state.confirmationTabs[currentConfirmationTabIndex].categories[categoryIndex].confirmation_settings[settingIndex].items.filter((item) => item.id !== action.payload.itemId);
    },
    addNewSettingItem(
      state,
      action: PayloadAction<{
        categoryId: number,
        confirmationSettingId: number,
        newItem: ConfirmationSettingItem,
      }>
    ) {
      const {
        currentConfirmationTabIndex,
        categoryIndex,
        settingIndex,
      } = getSettingState(state, action.payload.categoryId, action.payload.confirmationSettingId);
      state.confirmationTabs[currentConfirmationTabIndex].categories[categoryIndex].confirmation_settings[settingIndex].items.push(action.payload.newItem);
    },
    updateSettingItem(
      state,
      action: PayloadAction<{
        categoryId: number,
        confirmationSettingId: number,
        itemIndex: number,
        item: ConfirmationSettingItem,
      }>
    ) {
      const {
        currentConfirmationTabIndex,
        categoryIndex,
        settingIndex,
        oldSetting,
      } = getSettingState(state, action.payload.categoryId, action.payload.confirmationSettingId);
      state.confirmationTabs[currentConfirmationTabIndex].categories[categoryIndex].confirmation_settings[settingIndex].items[action.payload.itemIndex] = {
        ...oldSetting.items[action.payload.itemIndex],
        guest_document_template: {
          ...oldSetting.items[action.payload.itemIndex].guest_document_template,
          ...action.payload.item,
        }
      };
    },
    deleteSetting(
      state,
      action: PayloadAction<{
        categoryId: number,
        confirmationSettingId: number,
      }>
    ) {
      const {
        currentConfirmationTabIndex,
        categoryIndex,
        settingIndex,
      } = getSettingState(state, action.payload.categoryId, action.payload.confirmationSettingId);
      state.confirmationTabs[currentConfirmationTabIndex].categories[categoryIndex].confirmation_settings.splice(settingIndex, 1);
    },
    addNewSetting(
      state,
      action: PayloadAction<{
        categoryId: number,
        newSetting: ConfirmationSetting,
      }>
    ) {
      const { currentConfirmationTabIndex, categoryIndex } = getCategoryState(state, action.payload.categoryId);
      state.confirmationTabs[currentConfirmationTabIndex].categories[categoryIndex].confirmation_settings.push(action.payload.newSetting);
    },
    setVariables(state, action: PayloadAction<{ type: VariableApiType, variables: any }>) {
      const { type, variables } = action.payload;
      const { headerVariables, bodyVariables, footerVariables } = provideVariables(variables);
      if (type === 'agreement') {
        state.variables.agreementVariables = {
          header: headerVariables,
          body: bodyVariables,
          footer: footerVariables,
        };
      }
      else if (type === 'deposit') state.variables.depositVariables = bodyVariables;
      else if (type === 'payment') state.variables.paymentVariables = bodyVariables;
    },
    setPreferencesLoading(state, action: PayloadAction<boolean>) {
      state.preferencesLoading = action.payload;
    },
    updatePreferences(state, action: PayloadAction<{ brandId: number, newPreference: PaymentMethod, categoryName: string }>) {
      const brandIndex = state.preferences.findIndex(x => x.brand_id === action.payload.brandId);
      const financePreferencesIndex = state.preferences[brandIndex].financePreferences[0].findIndex(x => x.name === action.payload.categoryName);
      const settingsIndex = state.preferences[brandIndex].financePreferences[0][financePreferencesIndex].settings.findIndex(x => x.key === action.payload.newPreference.key);
      if (settingsIndex !== undefined) {
        state.preferences[brandIndex].financePreferences[0][financePreferencesIndex].settings[settingsIndex] = { ...state.preferences[brandIndex].financePreferences[0][financePreferencesIndex].settings[settingsIndex], ...action.payload.newPreference }
      }
    },
    setPreferences(state, action: PayloadAction<Preferences[]>) {
      if (!state.preferences.length) {
        state.preferences = action.payload;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setTabsOrder,
  setCurrentTab,
  setTabsData,
  setSelectedBrandId,
  setHasClickSend,
  setDocumentUploadItemTemplates,
  seCustomQuestionTemplates,
  setAgreementIsEnabled,
  setAgreementValue,
  setAgreementConfig,
  setAgreements,
  updateSetting,
  updateSettingValidation,
  updateCategory,
  deleteSettingItem,
  addNewSettingItem,
  updateSettingItem,
  deleteSetting,
  addNewSetting,
  setPreferences,
  updatePreferences
} = slice.actions;

// ----------------------------------------------------------------------

export function changeTabsOrder(currentTabsOrder: Tab[]) {
  return async () => {
    try {
      const firstOrder = ['general_info', 'guest_info', 'agreement', 'upsells_&_payment', 'security_deposit', 'success_page'];
      const secondOrder = ['general_info', 'guest_info', 'upsells_&_payment', 'security_deposit', 'agreement', 'success_page'];
      const order = currentTabsOrder.indexOf('agreement') === 2 ? secondOrder : firstOrder;
      dispatch(slice.actions.setTabsOrder(order));
    } catch (error) {}
  };
};

export function updateTabsCategory(categoryId: number, body, category, diableUpdateSlice: boolean = false) {
  return async () => {
    try {
      !diableUpdateSlice && dispatch(slice.actions.updateCategory({ categoryId, category: body }));
      await confirmationPageSettingsAPI.updateCategory(categoryId, body);
    } catch (error) {
      !diableUpdateSlice && dispatch(slice.actions.updateCategory({ categoryId, category: category }));
    }
  };
};

export function updateConfirmationSetting(categoryId: number, confirmationSettingId: number, body: UpdatableConfirmationSetting, setting, setStatus: null | Function = null) {
  return async () => {
    try {
      setStatus && setStatus('loading')
      dispatch(slice.actions.updateSetting({
        categoryId,
        confirmationSettingId,
        setting: body,
      }));
      await confirmationPageSettingsAPI.updateConfirmationSetting(
        confirmationSettingId, body.selected_option ? { selected_option: body.selected_option.id } : body
      );
      setStatus && setStatus('success')
    } catch (error) {
      dispatch(slice.actions.updateSetting({
        categoryId,
        confirmationSettingId,
        setting: setting,
      }));
      setStatus && setStatus('error')
    }
  };
};

export function addNewConfirmationSettingItem(
  categoryId: number,
  confirmationSettingId: number,
  body: { guest_document_template?: number },
  setLoading?: (state: boolean) => void,
  callback?: (response: AxiosResponse<any, any>, succeed: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const response = await confirmationPageSettingsAPI.addNewConfirmationSettingItem({
        ...body,
        confirmation_setting: confirmationSettingId,
      });
      dispatch(
        slice.actions.addNewSettingItem({
          categoryId,
          confirmationSettingId,
          newItem: response.data,
        })
      );
      callback?.(response, true);
    } catch (error) {
      callback?.(error, false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateConfirmationSettingItem(categoryId: number, confirmationSettingId: number, itemId: number, itemIndex: number, body, item, setStatus) {
  return async () => {
    try {
      setStatus('loading');
      await confirmationPageSettingsAPI.updateConfirmationSettingItem(itemId, body);
      dispatch(slice.actions.updateSettingItem({
        categoryId,
        confirmationSettingId,
        itemIndex,
        item: body,
      }));
      setStatus('success');
    } catch (error) {
      dispatch(slice.actions.updateSettingItem({
        categoryId,
        confirmationSettingId,
        itemIndex,
        item: item,
      }));
      setStatus('error');
    }
  };
};

export function deleteConfirmationSettingItem(
  categoryId: number,
  confirmationSetting: ConfirmationSetting,
  itemId: number,
  setLoading?: (state: boolean) => void,
  callback?: (succeed: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      await confirmationPageSettingsAPI.deleteConfirmationSettingItem(itemId);
      dispatch(
        slice.actions.deleteSettingItem({
          categoryId,
          confirmationSettingId: confirmationSetting.id,
          itemId,
        })
      );
      if (
        confirmationSetting.title === 'id_verification' &&
        confirmationSetting.items.length === 1
      ) {
        dispatch(
          updateConfirmationSetting(
            categoryId,
            confirmationSetting.id,
            { is_enabled: false },
            confirmationSetting
          )
        );
      }
      callback?.(true);
    } catch (error) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateConfirmationSettingValidation(categoryId: number, confirmationSettingId: number, validationId: number, validationIndex: number, body, validation) {
  return async () => {
    try {
      dispatch(slice.actions.updateSettingValidation({
        categoryId,
        confirmationSettingId,
        validationIndex,
        validation: body,
      }));
      await confirmationPageSettingsAPI.updateConfirmationSettingValidation(validationId, body);
    } catch (error) {
      dispatch(slice.actions.updateSettingValidation({
        categoryId,
        confirmationSettingId,
        validationIndex,
        validation: validation,
      }));
    }
  };
};

export function addNewConfirmationSetting(categoryId: number, templateId: number, setStatus) {
  return async () => {
    try {
      setStatus('loading');
      const response = await confirmationPageSettingsAPI.addNewConfirmationSetting({
        is_enabled: true,
        is_custom: true,
        is_required: false,
        category: categoryId,
        custom_question_template: templateId,
      });
      dispatch(slice.actions.addNewSetting({
        categoryId,
        newSetting: response.data,
      }));
      setStatus('success');
    } catch (error) {
      setStatus('error');
    }
  };
};

export function deleteConfirmationSetting(categoryId: number, confirmationSettingId: number, setDeleteLoading) {
  return async () => {
    try {
      setDeleteLoading(true);
      await confirmationPageSettingsAPI.deleteConfirmationSetting(confirmationSettingId);
      dispatch(slice.actions.deleteSetting({
        categoryId,
        confirmationSettingId,
      }));
    } catch (error) {
      setDeleteLoading(false);
    }
  };
};

export function createOrUpdateDocumentUploadItemTemplate(
  templateId: number | null,
  body: UpdateDocumentUploadItemTemplate,
  setLoading?: (state: boolean) => void,
  callback?: (response: AxiosResponse<any, any>, succeed: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      if (templateId) {
        const response = await confirmationPageSettingsAPI.updateDocumentUploadItemTemplate(
          body,
          templateId
        );
        dispatch(
          slice.actions.updateDocumentUploadItemTemplate({ id: templateId, item: response.data })
        );
        callback?.(response, true);
      } else {
        const response = await confirmationPageSettingsAPI.addDocumentUploadItemTemplate(body);
        dispatch(slice.actions.addDocumentUploadItemTemplate(response.data));
        callback?.(response, true);
      }
    } catch (e) {
      callback?.(e, false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function deleteDocumentUploadItemTemplate(documentUploadItemTemplate: DocumentUploadItemTemplate) {
  return async () => {
    try {
      await confirmationPageSettingsAPI.deleteDocumentUploadItemTemplate(documentUploadItemTemplate.id);
      dispatch(slice.actions.deleteDocumentUploadItemTemplate(documentUploadItemTemplate.id));
    } catch (error) {}
  };
}

export function addOrUpdateCustomQuestionTemplate(
  body: EditableCustomQuestion,
  brandId: number | null,
  templateId?: number,
  setStatus: null | Function = null,
  returnNewTemplateId?: (id: number) => void,
) {
  return async  () => {
    try {
      setStatus && setStatus('loading');
      if (templateId) {
        const response = await confirmationPageSettingsAPI.updateCustomQuestionTemplate(body, templateId);
        dispatch(slice.actions.updateCustomQuestionTemplate({ id: templateId, data: response.data }));
        setStatus && setStatus('success');
      } else if (brandId) {
        const response = await confirmationPageSettingsAPI.addCustomQuestionTemplate({ ...body, brand: brandId });
        dispatch(slice.actions.addCustomQuestionTemplate(response.data));
        returnNewTemplateId && returnNewTemplateId(response.data.id);
        setStatus && setStatus('next');
      }
    } catch (e) {
      setStatus && setStatus('error'); 
    }
  }
}

export function deleteCustomQuestionTemplate(customQuestionTemplate: CustomQuestionTemplate) {
  return async () => {
    try {
      await confirmationPageSettingsAPI.deleteCustomQuestionTemplate(customQuestionTemplate.id);
      dispatch(slice.actions.deleteCustomQuestionTemplate(customQuestionTemplate.id));
    } catch (e) {}
  }
}

export function createOrUpdateAgreementTemplate(
  contents: {
    title: string,
    header: string,
    body: string,
    footer: FooterTemplate,
  },
  brand: number,
  agreementId?: number,
  setStatus: null | Function = null,
  replaceCurrentAgreement?: boolean,
  selectAgreement?: (agreement: Agreement) => void,
) {
  return async () => {
    try {
      setStatus && setStatus('loading');
      let response;
      const { title, body, header, footer } = contents;
      const requestBody = {
        title,
        header: { content: header },
        body: { content: body },
        footer: footer.id ? footer : null,
        brand,
      }
      if (agreementId) {
        response = await confirmationPageSettingsAPI.updateAgreementTemplate(requestBody, agreementId);
        dispatch(slice.actions.updateAgreement({ id: agreementId, agreement: response.data }));
      } else {
        response = await confirmationPageSettingsAPI.saveAgreementTemplate(requestBody);
        dispatch(slice.actions.addAgreement(response.data));
      }
      if (replaceCurrentAgreement && selectAgreement && response) {
        selectAgreement(response.data);
      }
      setStatus && setStatus('success');
    } catch (error) {
      if (error.status === 400) {
        setStatus && setStatus('repeat');
      }
      setStatus && setStatus('error');
    }
  };
};

export function archiveAgreement(agreement: Agreement, archived: boolean, brand: number) {
  return async () => {
    try {
      dispatch(slice.actions.updateAgreement({ id: agreement.id, agreement: { archived } }));
      await confirmationPageSettingsAPI.updateAgreementTemplate({ brand, archived }, agreement.id);
    } catch (error) {
      dispatch(slice.actions.updateAgreement({ id: agreement.id, agreement: { archived: !archived } }));
    }
  };
};

export function deleteAgreement(agreement: Agreement) {
  return async () => {
    try {
      dispatch(slice.actions.deleteAgreement(agreement.id));
      await confirmationPageSettingsAPI.deleteAgreementTemplate(agreement.id);
    } catch (error) {
      dispatch(slice.actions.addAgreement(agreement));
    }
  };
};

export function changeEnabledAgreementStatus(categoryId: number | undefined, is_enabled: boolean) {
  return async () => {
    try {
      if (categoryId) {
        dispatch(setAgreementIsEnabled(is_enabled));
        await confirmationPageSettingsAPI.updateCategoryTypes(categoryId, { is_enabled });
      }
    } catch (error) {
      dispatch(setAgreementIsEnabled(!is_enabled));
    }
  };
};

export function saveAgreementSetting(
  confirmationId: number | undefined,
  propertyToChange: 'except_channels' | 'all_booking' | 'lead_guest_signed' | 'agreement_template',
  value: any,
) {
  return async () => {
    try {
      if (confirmationId) {
        dispatch(setAgreementValue({ propertyToChange, value }));
        if (propertyToChange === 'agreement_template') value = value.id;
        if (propertyToChange === 'except_channels') value = value.map((channel) => channel.id);
        const body = { [propertyToChange]: value };
        await confirmationPageSettingsAPI.updateAgreementConfig(confirmationId, body);
      }
    } catch (error) {
    }
  };
};

export function getVariables(type: VariableApiType) {
  return async () => {
    try {
      const response = await confirmationPageSettingsAPI.fetchVariables(type);
      dispatch(slice.actions.setVariables({ type, variables: response.data }));
    } catch (error) {}
  };
};

export function getPreferencesData(){
  return async () => {
    dispatch(slice.actions.setPreferencesLoading(true));
    try {
      const response = await confirmationPageSettingsAPI.fetchFinancePreferences();
      dispatch(slice.actions.setPreferences(response.data));
    } catch (error) {}
    dispatch(slice.actions.setPreferencesLoading(false));
  }
}

export function updatePreferencesAction(
  setLoading: (isLoading: boolean) => void,
  brandId: number,
  key: string,
  value: boolean | string | string[] | null,
  categoryName: string,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void,
  liveUpdate: boolean = false,
  beforeUpdateValue?: boolean | string | string[] | null
) {
  return async () => {
    setLoading(true);
    try {
      liveUpdate &&
        dispatch(
          slice.actions.updatePreferences({
            brandId: brandId,
            newPreference: { key, value },
            categoryName: categoryName,
          })
        );
      const response = await confirmationPageSettingsAPI.setPreferences(brandId, key, value);
      !liveUpdate &&
        dispatch(
          slice.actions.updatePreferences({
            brandId: brandId,
            newPreference: { key, value },
            categoryName: categoryName,
          })
        );
      callback?.(response.data, response.status);
    } catch (error) {
      callback?.(null, error.status || 500);
      liveUpdate &&
        beforeUpdateValue !== undefined &&
        dispatch(
          slice.actions.updatePreferences({
            brandId: brandId,
            newPreference: { key, value: beforeUpdateValue },
            categoryName: categoryName,
          })
        );
    }
    setLoading(false);
  };
}
