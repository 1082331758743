import { Nullable } from "./common";
import { RateType } from "./rate";
import { TaxRate } from "./tax";

export enum RatePlanTreeLevelEnum {
  BRAND = 'b',
  COUNTRY = 'k',
  CITY = 'c',
  AREA = 'a',
  PROPERTY = 'p',
  UNIT_TYPE = 't',
  UNIT = 'u',
  RATE_PLAN = 'r',
};

export type RatePlanTreeLevel = `${RatePlanTreeLevelEnum}`;

export type RatePlanTree = {
  id: number;
  name: string;
  type?: RateType;
  tax_rate?: Nullable<TaxRate>;
  apply_to_booking_channel?: boolean;
};

export type UnitTree = {
  id: number;
  name: string;
};

export type UnitTypeTree = {
  id: number;
  is_listed?: boolean;
  name: string;
  rate_plans?: RatePlanTree[];
  units?: UnitTree[];

};
export type PropertyTree = {
  id: number;
  short_name: string;
  is_active?: boolean;
  default_currency?: { code: string };
  unit_types: UnitTypeTree[];
};

export type CityTree = {
  id: number;
  name: string;
  areas: AreaTree[];
};

export type AreaTree = {
  id: number;
  name: string;
  properties: PropertyTree[];
};

export type CountryTree = {
  name: string;
  iso2_code: string;
  cities: CityTree[];
};

export type BrandTree = {
  id: number;
  name: string;
  countries: CountryTree[];
};

export type Tree = Array<BrandTree | CountryTree | CityTree | PropertyTree | UnitTypeTree | RatePlanTree | UnitTree>;
