import { FooterTemplate } from 'src/@types/automated-message';
import { VariableApiType } from 'src/@types/common';
import axios from '../utils/axios';

function confirmationPageSettingsAPI() {
  return {
    fetchBrands: (name?: string, page: number = 0, limit: number = 15) => {
      const url = '/apis/brands';
      return axios.get(url, {
        params: { limit, page: page + 1, name, 'order[name]': 'asc' },
      });
    },
    fetchVariables: (type: VariableApiType) => {
      const url = '/apis/automated_message/v1/get_variables';
      return axios.get(url, {
        params: { id: null, type },
      });
    },
    fetchChannels: (name: string, page: number) => {
      const url = '/apis/channels';
      return axios.get(url, {
        params: { limit: 20, name, page: page + 1, 'order[name]': 'asc' }
      })
    },
    fetchTabsData: (brandId: number) => {
      const url = '/apis/confirmation_setting_category_types';
      return axios.get(url, {
        params: { brand: brandId },
      });
    },
    updateCategory: (categoryId: number, body) => {
      const url = `/apis/confirmation_setting_categories/${categoryId}`;
      return axios.put(url, body);
    },
    updateCategoryTypes: (categoryTypesId: number, body) => {
      const url = `/apis/confirmation_setting_category_types/${categoryTypesId}`;
      return axios.put(url, body);
    },
    addNewConfirmationSetting: (body) => {
      const url = 'apis/confirmation_settings';
      return axios.post(url, body);
    },
    updateConfirmationSetting: (settingId: number, body) => {
      const url = `/apis/confirmation_settings/${settingId}`;
      return axios.put(url, body);
    },
    fetchAgreementConfig: (brandId: number) => axios.get(`/apis/agreement_configs?organization_config.brands=${brandId}`),
    updateAgreementConfig: (id: number, body) => {
      const url = `/apis/agreement_configs/${id}`;
      return axios.put(url, body);
    },
    deleteConfirmationSetting: (settingId: number) => {
      const url = `apis/confirmation_settings/${settingId}`;
      return axios.delete(url);
    },
    addNewConfirmationSettingItem: (body) => {
      const url = `/apis/confirmation_setting_items`;
      return axios.post(url, body);
    },
    updateConfirmationSettingItem: (itemId: number, body) => {
      const url = `/apis/confirmation_setting_items/${itemId}`;
      return axios.put(url, body);
    },
    deleteConfirmationSettingItem: (itemId: Number) => {
      const url = `/apis/confirmation_setting_items/${itemId}`;
      return axios.delete(url);
    },
    updateConfirmationSettingValidation: (validationId: number, body) => {
      const url = `/apis/confirmation_setting_validations/${validationId}`;
      return axios.put(url, body);
    },
    fetchDocumentUploadItemTemplates: (brandId: number) => {
      const url = '/apis/guest_document_templates';
      return axios.get(url, { params: { brand: brandId } });
    },
    addDocumentUploadItemTemplate: (body) => {
      const url = '/apis/guest_document_templates';
      return axios.post(url, body);
    },
    updateDocumentUploadItemTemplate: (body, id: number) => {
      const url = `/apis/guest_document_templates/${id}`;
      return axios.put(url, body);
    },
    deleteDocumentUploadItemTemplate: (id: number) => axios.delete(`/apis/guest_document_templates/${id}`),
    fetchCustomQuestionTemplates: (brandId: number) => {
      const url = `/apis/custom_question_templates`;
      return axios.get(url, { params: { brand: brandId } });
    },
    addCustomQuestionTemplate: (body) => {
      const url = `/apis/custom_question_templates`;
      return axios.post(url, body);
    },
    updateCustomQuestionTemplate: (body, id: number) => {
      const url = `/apis/custom_question_templates/${id}`;
      return axios.put(url, body);
    },
    deleteCustomQuestionTemplate: (id: number) => axios.delete(`/apis/custom_question_templates/${id}`),
    fetchAgreementTemplates: (brandId: number) => {
      const url = '/apis/agreement_templates';
      return axios.get(url, { params: { brand: brandId } });
    },
    saveAgreementTemplate: (body: { title: string, body: { content: string }, header: { content: string }, footer: FooterTemplate | null, brand: number }) => {
      const url = '/apis/agreement_templates';
      return axios.post(url, body);
    },
    updateAgreementTemplate: (body: { title?: string, body?: { content: string }, header?: { content: string }, footer?: FooterTemplate | null, brand: number, archived?: boolean }, id: number) => {
      const url = `/apis/agreement_templates/${id}`;
      return axios.put(url, body);
    },
    deleteAgreementTemplate: (id: number) => axios.delete(`/apis/agreement_templates/${id}`),
    setPreferences: (brandId: number, key: string, value: boolean | string | string[] | number | null) => axios.put('/apis/preferences/set', {
        brand: brandId,
        key: key,
        value: value
      }),
    fetchPreferences: () => axios.get('/apis/currentOrgPreferences/get'),
    fetchFinancePreferences: ()=>axios.get('/apis/currentOrgPreferences/finance/get')
  }
}

export default confirmationPageSettingsAPI();
