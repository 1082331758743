import { fTimestamp } from 'src/utils/formatTime';
import { utcToZonedTime } from 'date-fns-tz';
import { cloneDeep } from 'lodash';
import { format, hoursToSeconds, isAfter, isBefore, isSameDay } from 'date-fns';
import {
  AllTaskType,
  RelatedBlockedDate,
  RelatedIssue,
  TaskListDateFilter,
  TaskPriority,
  TaskStatus,
  TaskTab
} from 'src/@types/tasks';
import { Theme } from '@mui/material';
import { LabelColor, LabelVariant } from 'src/components/Label';
import { FilterValue } from 'src/@types/filter';
import { BlockedDateFormValues, BlockType } from 'src/@types/blocked-dates';
import { TFunction } from 'react-i18next';
import {dateToIndex} from "./booking";

export function taskTrackStatus({ endTime, startTime, must_finished_by, estimation }: {
  endTime?: number | null,
  startTime?: number | null,
  must_finished_by?: number,
  estimation?: number,
}) {
  const currentTimestamp = fTimestamp(new Date(), false);
  const timestampToCompare = endTime ? endTime : currentTimestamp;
  const startSchedule = must_finished_by && estimation ? must_finished_by - hoursToSeconds(estimation) : 0;
  const duration = startTime ? timestampToCompare - startTime : 0;
  return {
    duration,
    isStarted: Boolean(startTime),
    isFinished: Boolean(endTime),
    startedOnSchedule: Boolean(startTime && startTime <= startSchedule),
    isTakingLongerThanExpected: Boolean(estimation && duration > hoursToSeconds(estimation)),
    shouldHaveBeenStartedByNow: Boolean(!startTime && currentTimestamp > startSchedule),
    shouldHaveBeenFinishedByNow: Boolean(must_finished_by && timestampToCompare > must_finished_by),
    finishedOnSchedule: Boolean(endTime && must_finished_by && endTime <= must_finished_by),
  };
}

export const getTimeFilterOfView: (tabValue: TaskTab) => TaskListDateFilter = (tabValue) => {
  switch (tabValue) {
    case 'upcoming': return 'next_30_days';
    case 'today': return 'today';
    case 'unassigned': return 'next_7_days';
    case 'early_checkin': return 'next_7_days';
    case 'previous': return 'past_30_days';
    case 'all': return 'all';
    default: return 'upcoming';
  }
};

export const getDateOptions = (tabValue: TaskTab, translate: TFunction<any[], undefined>) => {
  const options: FilterValue[] = [
    { id: 'date', value: 'all', label: translate('all') },
    { id: 'date', value: 'today', label: translate('today') },
    { id: 'date', value: 'tomorrow', label: translate('tomorrow') },
    { id: 'date', value: 'next_7_days', label: translate('next_n_days', { n: '7' }) },
    { id: 'date', value: 'past_7_days', label: translate('past_n_days', { n: '7' }) },
    { id: 'date', value: 'next_30_days', label: translate('next_n_days', { n: '30' }) },
    { id: 'date', value: 'past_30_days', label: translate('past_n_days', { n: '30' }) },
    { id: 'date', value: 'this_month', label: translate('this_month') },
    { id: 'date', value: 'next_month', label: translate('next_month') },
    { id: 'date', value: 'last_month', label: translate('last_month') },
    { id: 'date', value: 'custom', label: translate('custom') },
  ];
  if (tabValue === 'previous') return options.filter(({ value }) => {
    const previousOptions = ['past_7_days', 'past_30_days', 'last_month', 'custom'];
    return previousOptions.includes(value);
  });
  if (tabValue === 'upcoming') return options.filter(({ value }) => {
    const upcomingOptions = ['today', 'tomorrow', 'next_7_days', 'next_30_days', 'next_month', 'custom'];
    return upcomingOptions.includes(value);
  });
  if (tabValue !== 'all') return options.filter(({ value }) => value !== 'all');
  return options;
};

export const getPriorityColor = (priority: TaskPriority | '', theme: Theme) => {
  switch (priority) {
    case 'Urgent':
      return {
        variant: 'error.darker',
        backgroundColor: theme.palette.error.darker,
        color: theme.palette.common.white,
      };
    case 'High':
      return {
        variant: 'error',
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
      };
    case 'Normal':
      return {
        variant: 'warning',
        backgroundColor:theme.palette.warning.main,
        color: theme.palette.common.white,
      };
    case 'Low':
      return {
        variant: 'grey.300',
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[700],
      };
    default:
      return {
        variant: 'grey.300',
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[700],
      };
  };
};

export const getStatusLabelStyle = (status: TaskStatus | '') => {
  const color: LabelColor = 
    (status === 'completed' && 'success') ||
    (status === 'pending' && 'warning') ||
    (status === 'cancelled' && 'error') ||
    (status === 'in_progress' && 'info') ||
    (status === 'not_started' && 'default') ||
    'default';
  const variant: LabelVariant = status === 'cancelled' ? 'outlined' : 'ghost';
  return { color, variant };
};

export const getStatusColor = (status: TaskStatus) => {
  switch (status) {
    case 'not_started':
      return 'text.disabled';
    case 'in_progress':
      return 'info.main';
    case 'cancelled':
      return 'error.main';
    case 'pending':
      return 'warning.main';
    case 'completed':
      return 'success.main';
  }
};

export const getTaskTypeTitles = (taskType: AllTaskType) => {
  const titles: {[key in AllTaskType]: { title: string, subtitle: string }} = {
    "mid stay housekeeping": { title: 'mid_stay', subtitle: 'housekeeping' },
    "one off housekeeping": { title: 'one_off', subtitle: 'housekeeping' },
    "checkout housekeeping": { title: 'checkout', subtitle: 'housekeeping' },
    "mid stay laundry": { title: 'mid_stay', subtitle: 'laundry' },
    "one off laundry": { title: 'one_off', subtitle: 'laundry' },
    "checkout laundry": { title: 'checkout', subtitle: 'laundry' },
    "checkin welcome": { title: 'checkin', subtitle: 'welcome' },
    "confirm check-in time": { title: 'checkin_time', subtitle: 'confirm' },
    "arival time notice": { title: 'arival_time', subtitle: 'notice' },
    "arival confirm notice": { title: 'arival_confirm', subtitle: 'notice' },
    "email not send to": { title: 'email', subtitle: 'not_send to' },
    "payment reminder": { title: 'payment', subtitle: 'reminder' },
    "EMAIL_HANDLING": { title: 'EMAIL_HANDLING', subtitle: '' },
  };
  return titles[taskType];
};

export const getAssigneeConfig = (type: string) => {
  switch (type.toLowerCase()) {
    case 'individuals':
      return { icon: 'ic:round-person-outline', tooltip: 'staff' };
    case 'supplier':
      return { icon: 'ic:round-apartment', tooltip: 'supplier' };
    default:
      return { icon: '', tooltip: '' }
  }
};

export const checkForUnassignedErrorAlert = (isUnassigned: boolean, taskDate: Date) => {
  if (isUnassigned) {
    const currentDate = new Date();
    if (currentDate > taskDate) return dateToIndex(currentDate, taskDate) < 29;
    else return dateToIndex(taskDate, currentDate) < 29;
  }
  return false;
};

export const normalizeBlockedDateToView = (object: RelatedBlockedDate): any => ({
  id: object.id,
  to: object.to,
  from: object.from,
  blockType: object.block_type,
  reason: object.reason,
  isActive: object.is_active,
  timezone: object.unit?.unit_type?.property?.timezone || '',
  isMultiUnit: object.unit?.unit_type.unit_count > 1 || false,
  isMultiUnitType: object.unit?.unit_type?.property?.unitTypeCount > 1 || false,
  unit: { id: object.unit.id, name: object.unit.name },
  unitType: { id: object.unit?.unit_type?.id || 0, name: object.unit?.unit_type?.name || '' },
  property: { id: object.unit?.unit_type?.property?.id || 0, name: object.unit?.unit_type?.property?.name || '' },
  isFromIcal: object.block_type === 'From iCal',
  icalId: object.block_from_i_cal?.id,
  icalLink: object.block_from_i_cal?.ical_link,
  createdAt: object.created_at,
  updatedAt: object.updated_at,
});

export const normalizeBlockedDateToEdit = (object: RelatedBlockedDate): BlockedDateFormValues => ({
  blockedDateId: object.id,
  blockType: object.block_type,
  reason: object.reason,
  property: object.unit?.unit_type?.property
    ? { id: object.unit?.unit_type?.property.id, name: object.unit?.unit_type?.property.name }
    : null,
  unit: object.unit && object.unit?.unit_type
    ? {
      id: object.unit.id,
      name: object.unit.name,
      unitTypeId: object.unit.unit_type.id,
      unitTypeName: object.unit.unit_type.name,
    } : null,
  units: [],
  fromDate: new Date(object.from),
  fromTime: format(utcToZonedTime(new Date(object.from), 'UTC'), 'HH:mm'),
  toDate: new Date(object.to),
  toTime: format(utcToZonedTime(new Date(object.to), 'UTC'), 'HH:mm'),
  timezone: object.unit?.unit_type?.property?.timezone || '',
  customDates: [],
});

export const normalizeBlockedDateToCreate = (object: RelatedIssue, timezone: string) => ({
  blockType: 'Maintenance' as BlockType,
  property: {
    id: object.property.id,
    name: object.property.name,
  },
  ...(object.unit && object.unit_type && {
    units: [{
      id: object.unit.id,
      name: object.unit.name,
      unitTypeId: object.unit_type.id,
      unitTypeName: object.unit_type.name,
    }],
  }),
  timezone: timezone,
});

export const hasScheduledOutsideOfBlockedDate = (
  canStartFrom: Date | null,
  mustFinishBy: Date | null,
  blockedDateFrom: Date,
  blockedDateTo: Date,
) => {
  if (canStartFrom && mustFinishBy) {
    const clonedCanStartFrom = cloneDeep(canStartFrom);
    const clonedMustFinishBy = cloneDeep(mustFinishBy);

    const blockedDateFromTime = format(utcToZonedTime(blockedDateFrom, 'UTC'), 'HH:mm');
    const canStartFromTime = format(canStartFrom, 'HH:mm');
    const blockedDateToTime = format(utcToZonedTime(blockedDateTo, 'UTC'), 'HH:mm');
    const mustFinishByTime = format(mustFinishBy, 'HH:mm');

    const fromDateOutside = isBefore(clonedCanStartFrom.setHours(0, 0, 0), blockedDateFrom.setHours(0, 0, 0));
    const toDateOutside = isAfter(clonedMustFinishBy.setHours(0, 0, 0), blockedDateTo.setHours(0, 0, 0));
    const timeOutside = (isSameDay(canStartFrom, blockedDateFrom) && canStartFromTime < blockedDateFromTime) ||
      (isSameDay(mustFinishBy, blockedDateTo) && mustFinishByTime > blockedDateToTime);

    if (fromDateOutside || toDateOutside || timeOutside) return true;
  }
  return false;
};