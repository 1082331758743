import _ from "lodash";
import isWithinInterval from "date-fns/isWithinInterval";
import { addYears, differenceInCalendarDays, getDate, getMonth, getYear, isSameDay, subDays } from "date-fns";
import isBefore from "date-fns/isBefore";
import getHours from "date-fns/getHours";
import set from "date-fns/set";
import differenceInHours from "date-fns/differenceInHours";
import subYears from "date-fns/subYears";
import { Property, SearchOption, SearchType, UnitType, Unit, RatePlan, CalendarDisplaySetting } from "src/@types/calendar";
import { alpha } from "@mui/material/styles";
import { CheckInReadyStatus } from "src/@types/booking";

export const DAY_WIDTH = 48;
export const DAY_HEIGHT = 48;
export const SCROLL_SMOTHNESS = DAY_HEIGHT / 2;
export const VISIBLE_YEARS_BEFORE = 2;
export const VISIBLE_YEARS_AFTER = 2;
export const START_OFFSET_DAYS = 2;
export const INITIAL_START_INDEX = differenceInCalendarDays(new Date(), subYears(new Date(), VISIBLE_YEARS_BEFORE)) - START_OFFSET_DAYS;
export const INITIAL_END_INDEX = differenceInCalendarDays(new Date(), addYears(new Date(), VISIBLE_YEARS_AFTER)) - START_OFFSET_DAYS;
export const VISIBLE_DAYS_COUNT = 1460;
export const INITIAL_SIZE = 1465;
export const PROPERTY_HEIGHT = 24;
export const UNIT_TYPE_HEIGHT = 48;
export const UNIT_HEIGHT = 48;
export const RATE_HEIGHT = 48;
export const UNALLOCATED_HEIGHT = 48;
export const BOOKING_OFFSET_TOP = 12;
export const BOOKING_OFFSET_TOP_CANCELLED = 6;
export const BLOCKED_OFFSET_TOP = 8;
export const BLOCKED_STATUS_CODE = 10;
export const CANCELED_STATUS_CODE = 3;
export const SCHEDULED_FOR_CANCELLATION_STATUS_CODE = 7;
export const BOOKING_STATUS_CODE = [
  { type: 'BOOKED', label: 'Booked', value: 0 },
  { type: 'CANCEL', label: 'Cancel', value: 1 },
  { type: 'SCHEDULED_FOR_CANCELLATION', label: 'Scheduled For Cancellation', value: 2 },
  { type: 'CHECKED_IN', label: 'Checked-in', value: 3 },
  { type: 'CHECKED_OUT', label: 'Checked-out', value: 4 },
  { type: 'NO_SHOW', label: 'No Show', value: 5 },
  { type: 'FINALIZED', label: 'Finalized', value: 6 },
  { type: 'BUSINESS_BOOKING', label: 'Business Booking', value: 7 },
];

export const isCancelled = (status: number) => status === 3 || status === 7 || status === BLOCKED_STATUS_CODE

export const SECURITY_DEPOSIT_STATUS = {
  NOT_AUTHORIZED: 'Not Authorized',
  AUTHORIZED: 'Authorized',
  CHARGED: 'Charged',
  PARTIALLY_CHARGED: 'Partially Charged',
  RELEASED: 'Released',
  PARTIALLY_AUTHORIZED: 'Partially Authorized',
  GUARANTEED_BY_CHANNEL: 'Guaranteed by Channel',
  NOT_APPLICABLE: 'Not Applicable',
  AWAITING_AUTHORIZATION: 'Awaiting Authorization',
  EXPIRED: 'Expired',
}

export const getSecurityDepositStatus = (key: string) => SECURITY_DEPOSIT_STATUS[key]

export const dateToIndex = (date: Date, start: Date) => differenceInCalendarDays(date, start)

export const initSwitches = (count, value) => new Array<boolean>(count).fill(value)

export const initPropertiesLoading = (count, value) => new Array<boolean>(count).fill(value)

export const initCollapsesFromProperties = (properties, isUnit, showPropertyRow, showUnitTypeRow) => {
  let collapses: any = []

  properties.forEach(property => {
    let collapse: any = { id: property.id, isOpen: true, unitTypes: [], height: PROPERTY_HEIGHT, isLoading: false }
    property.unitTypes.forEach(unitType => {
      let unitCollapse: any = { id: unitType.id, unallocatedBookingsCount: unitType.unallocatedBookingsCount, isOpen: true, units: [], ratePlans: [], height: UNIT_TYPE_HEIGHT }
      unitType.units.forEach(unit => {
        unitCollapse.units.push({ id: unit.id, height: UNIT_HEIGHT })
      })
      unitType.ratePlans.forEach(rate => {
        unitCollapse.ratePlans.push({ id: rate.id, height: RATE_HEIGHT })
      })
      collapse.unitTypes.push(unitCollapse)
    })
    collapses.push(collapse)
  })

  collapses.forEach((collapse, index) => {
    let height = calculatePropertyHeight(collapse, isUnit, showPropertyRow, showUnitTypeRow)
    collapse.height = height
  })

  return collapses
}

export const initDateConfig = () => {
  const today = new Date();
  const start = subYears(today, VISIBLE_YEARS_BEFORE);
  const end = addYears(today, VISIBLE_YEARS_AFTER);

  return {
    start,
    end,
    weekends: [0, 6],
    dayWidth: DAY_WIDTH,
    daysCount: dateToIndex(end, start),
    todayIndex: dateToIndex(today, start),
    date: subDays(today, START_OFFSET_DAYS),
    today,
  }
}

export const getBackgroundColor = (status: string | number, isLocked: boolean = false, checkInReadyStatus: CheckInReadyStatus[], isFinalized: boolean) => {
  const checkInScore = checkInReadyStatus ? getCheckInReadyScore(checkInReadyStatus) : [];

    switch (+status) {
      case 1:
      case 2:
        return getCheckInReadyColor(checkInScore, checkInReadyStatus, isLocked)
      case 3:
        return '#F9FAFB'
      case 4:
      case 5:
        return '#F1E9FC'
      case 6:
        return '#D6E4FF'
      case 7:
        return '#F9FAFB'
      case 8:
        return '#CFCFCF'
      case 9:
        return '#ffba53'
      case 10:
        return '#c4cdd5'
      default:
        return '#ffba53'
  }
}

export const getCheckInReadyScore = (checkInReadyStatus: CheckInReadyStatus[]) => Object.values(checkInReadyStatus).flatMap((value: CheckInReadyStatus, index) =>
    (value.isChecked && value.conditionIsMet) ? [index] : []
  )

export const getCheckInReadyColor = (checkInScore: number[], checkInReadyStatus: CheckInReadyStatus[], isLocked) => {
  let checkInReadyColor = '';
  const paymentCR = checkInReadyStatus.find(CRStatus => CRStatus.name === 'PAYMENT');

  if(checkInScore.length === Object.keys(checkInReadyStatus.filter((CRStatus) => CRStatus.isChecked)).length) {
    checkInReadyColor = isLocked ? '#5BE584' : '#00AB55';
  } else if (checkInScore.length === 0 || (paymentCR && paymentCR.isChecked && !paymentCR.conditionIsMet)) {
    checkInReadyColor = isLocked ? '#FE9BC8' : '#E30168';
  } else {
    checkInReadyColor = isLocked ? '#FFDF7B' : '#FFB300';
  }

  return checkInReadyColor;
}

export const getBookingShadowColor = (color) => `drop-shadow(1px 1px 4px ${alpha(color, 0.32)})`

export const getFinalizedIconColor = (status) => (status === 3 || status === 7) ? 'text.secondary' : 'info.dark'

export const getLabelColor = (status: number, checkInReadyStatus: CheckInReadyStatus[], isLocked: boolean, isFinalized: boolean) => {
  if (status === BLOCKED_STATUS_CODE || status === 7 || status === 3) {
    return '#000000'
  } else if (status === 4 || status === 5) {
    return '#6C1DCC'
  } else if (status === 6) {
    return '#091A7A'
  } else if (status === 1 || status === 2) {
    const checkInScore = getCheckInReadyScore(checkInReadyStatus);
    const paymentCR = checkInReadyStatus.find(CRStatus => CRStatus.name === 'PAYMENT');
    if(checkInScore.length === Object.keys(checkInReadyStatus.filter((CRStatus) => CRStatus.isChecked)).length) {
      return isLocked ? '#007B55' : '#ffffff';
    } else if (checkInScore.length === 0 || (paymentCR && paymentCR.isChecked && !paymentCR.conditionIsMet)) {
      return isLocked ? '#A3014A' : '#ffffff';
    } else {
      return isLocked ? '#FF7A00' : '#ffffff';
    }
  } else {
    return '#ffffff'
  }
}

export const getStatusLabelColor = (status, checkInReadyStatus) => {
  switch (parseInt(status)) {
    case 1:
    case 2:
      const checkInScore = getCheckInReadyScore(checkInReadyStatus);
      const paymentCR = checkInReadyStatus.find(CRStatus => CRStatus.name === 'PAYMENT');
      if(checkInScore.length === Object.keys(checkInReadyStatus.filter((CRStatus) => CRStatus.isChecked)).length) {
        return '#007B55';
      } else if (checkInScore.length === 0 || (paymentCR && paymentCR.isChecked && !paymentCR.conditionIsMet)) {
        return '#A3014A';
      } else {
        return '#FF7A00';
      }
    case 3:
      return '#596A78'
    case 4:
    case 5:
      return '#6C1DCC';
    case 6:
      return '#3366FF';
    case 7:
      return '#596A78'
    case 8:
      return '#9e9e9e'
    case 9:
      return '#ff9900'
    case 10:
      return '#c4cdd5'
    default:
      return '#ff9900'
  }
}

export const getLockedIconColor = (status, checkInReadyStatus) => {
  switch (parseInt(status)) {
    case 1:
    case 2:
      return '#fff'
    case 3:
      return '#DFE3E8'
    case 4:
    case 5:
      return '#6C1DCC';
    case 6:
      return '#1939B7';
    case 7:
      return '#fff'
    case 8:
      return '#fff'
    case 9:
      return '#fff'
    case 10:
      return '#fff'
    default:
      return '#ff9900'
  }
}

export const getAvailabilityColor = (availability, unitCount) => {
  let percentage = ((availability / unitCount) * 100);
  let color = '#000000'
  switch (true) {
    case (percentage <= 10):
      color = '#00ca4e'
      break
    case (percentage <= 20):
      color = '#b4c300'
      break
    case (percentage <= 30):
      color = '#d5be00'
      break
    case (percentage <= 40):
      color = '#e5b407'
      break
    case (percentage <= 50):
      color = '#f3a919'
      break
    case (percentage <= 60):
      color = '#ff9e29'
      break
    case (percentage <= 70):
      color = '#ff8e36'
      break
    case (percentage <= 80):
      color = '#ff7e43'
      break
    case (percentage <= 90):
      color = '#ff6e4f'
      break
    case (percentage <= 100):
      color = '#ff605c'
      break
  }
  return color
}

export const getPaymentStatusColor = (status) => {
  switch (status) {
    case 'partial':
      return 'warning.dark'
    case 'unpaid':
      return 'error.dark'
    default:
      return 'success.dark'
  }
}

export const getBookingLabel = (status, label, nights) => (status === BLOCKED_STATUS_CODE ? '' : label ? label : '')

export const getBookingBorder = (status, checkInReadyStatus, isFinalized, isLocked, isHovered: boolean = false) => {
    switch (parseInt(status)) {
      case 1:
      case 2:
        const checkInScore = getCheckInReadyScore(checkInReadyStatus);
        const paymentCR = checkInReadyStatus.find(CRStatus => CRStatus.name === 'PAYMENT');
        if(checkInScore.length === Object.keys(checkInReadyStatus.filter((CRStatus) => CRStatus.isChecked)).length) {
          return {
            borderColor: isLocked ? 'rgb(133,215,173)' : 'rgb(92,171,146)',
            borderStyle: 'solid',
            borderWidth: '1px',
          };
        } else if (checkInScore.length === 0 || (paymentCR && paymentCR.isChecked && !paymentCR.conditionIsMet)) {
          return {
            borderColor: isLocked ? 'rgb(242,133,183)' : 'rgb(196,92,139)',
            borderStyle: 'solid',
            borderWidth: '1px',
          };
        } else {
          return {
            borderColor: isLocked ? 'rgb(255,219,133)' : 'rgb(255,170,92)',
            borderStyle: 'solid',
            borderWidth: '1px',
          };
        }
      case 3:
        return {
          borderColor: isHovered ? 'rgb(202,208,215)' : 'rgb(220,224,228)',
          borderStyle: 'solid',
          borderWidth: '1px',
        }
      case 4:
      case 5:
        return {
          borderColor: isHovered ? '#AD80E2' : 'rgba(173, 128, 226, 0.32)',
          borderStyle: 'solid',
          borderWidth: '1px',
        }
      case 6:
        return {
          borderColor: isFinalized ? isHovered ? 'rgb(157,182,255)' : 'rgb(190,206,255)' : isHovered ? 'rgb(157,182,255)' : 'rgb(190,206,255)',
          borderStyle: 'solid',
          borderWidth: '1px',
        }
      case 7:
        return {
          borderColor: isHovered ? 'rgb(202,208,215)' : 'rgb(220,224,228)',
          borderStyle: 'solid',
          borderWidth: '1px',
        }
      case 8:
        return {}
      case 9:
        return {}
      case 10:
        return {}
      default:
        return {}
    }
}

export const hasOverlap = (b1, b2) => {
  if(isSameDay(new Date(b2.from), new Date(b1.to)) || isSameDay(new Date(b2.to), new Date(b1.from))) {
    return false
  } else {
  return (
    isWithinInterval(new Date(b1.from), {
      start: new Date(b2.from),
      end: new Date(b2.to)
    }) || isWithinInterval(new Date(b2.from), {
      start: new Date(b1.from),
      end: new Date(b1.to)
    }))
  }
}

export const calculatePropertyHeight = (property, isUnit, showPropertyRow, showUnitTypeRow) => {
  const { unitTypes } = property
  const heights = unitTypes.map(unitType => {
    if (unitType.isOpen) {
      if (isUnit) {
        let unitHeight = unitType.units.reduce((a, b) => ({ height: a.height + b.height }), { height: 0 }).height;
        return { height: unitHeight + (showUnitTypeRow ? UNIT_TYPE_HEIGHT : 0) + ((unitType.unallocatedBookingsCount > 0 && showUnitTypeRow) ? UNALLOCATED_HEIGHT : 0) };
      } else {
        let rateHeight = unitType.ratePlans.reduce((a, b) => ({ height: a.height + b.height }), { height: 0 }).height;
        return { height: rateHeight + (showUnitTypeRow ? UNIT_TYPE_HEIGHT : 0) };
      }
    } else {
      return { height: (showUnitTypeRow ? UNIT_TYPE_HEIGHT : 0) }
    }
  })

  return heights.reduce((a, b) => ({ height: a.height + b.height }), { height: 0 }).height + (showPropertyRow ? PROPERTY_HEIGHT : 0);
}

export const initDataFromProperties = (properties: []) => {

  let data: any = {
    rates: [],
    units: [],
    availabilities: [],
    defaultRatePlans: [],
  }
  let rates: any[] = []
  let units: any[] = []
  let availabilities: any[] = []
  let defaultRatePlans: any[] = []
  let unitAvailabilities: any[] = []

  properties.map((property: any) => {
    property.unitTypes.map(unitType => {
      const p = new Array(INITIAL_SIZE).fill({
        status: 0,
        rate: {},
      })
      const u = {
        status: 0,
        bookings: []
      }
      const uAvailability = new Array(INITIAL_SIZE).fill(false)
      
      unitType.ratePlans.map(plan => {
        rates.push({ id: plan.id, planType: plan.planType, rate: p })
      })
      unitType.units.map(unit => {
        units.push({ id: unit.id, name: unit.name, unit: u })
        unitAvailabilities.push({ id: unit.id, name: unit.name, availabilities: uAvailability })
      })
      
      units.push({ id: unitType.id, name: 'Unallocated', unit: u })
 
      const a = new Array(INITIAL_SIZE).fill({
        status: 0,
        availability: {},
      })
      availabilities.push({ id: unitType.id, availability: a })
      defaultRatePlans.push({ id: unitType.id, rate: p, planType: '', planId: -1 })
    })
  })

  data.rates = rates
  data.units = units
  data.availabilities = availabilities
  data.defaultRatePlans = defaultRatePlans
  data.unitAvailabilities = unitAvailabilities

  return data

}

export const initStatusFromProperties = (properties: []) => {

  let status: any = []

  properties.map((property: any) => {
    const s = new Array(INITIAL_SIZE).fill({
      rateStatus: 0,
      bookingStatus: 0,
    })
    status.push(s)
  })

  return status
}

export const getBookingPosition = (bookings, calendarStartDate, offsetTop, showCancelled, LFCO: Date, EFCI: Date) => {

  bookings = bookings.sort((b1: any, b2: any) => new Date(b1.from).valueOf() - new Date(b2.from).valueOf())
  let bookingPositions: any = []

  _.forEach(bookings, booking => {
    let top = showCancelled ? isCancelled(booking.status) ? offsetTop : BOOKING_OFFSET_TOP_CANCELLED : offsetTop;
    // set the minimum width for 0 night bookings 
    let position = { id: booking.id, data: booking, top: top, left: getBookingLeftDistance(booking, calendarStartDate, LFCO, EFCI), width: getBookingWidth(booking, LFCO, EFCI) || (DAY_WIDTH /2) }
    bookingPositions.push(position)
  })

  _.forEach(bookingPositions, booking => {
    let sameLeft = _.filter(bookingPositions, b => b.left === booking.left)
    if (sameLeft.length > 1) {
      _.forEach(sameLeft, (sl, slIndex) => sl.left += (slIndex * (16/sameLeft.length)))
    }
  })

  let cancelledPositions = _.filter(bookingPositions, b => isCancelled(b.data.status));
  let normalPositions =  _.filter(bookingPositions, b => !isCancelled(b.data.status));

  _.forEach(cancelledPositions, booking => {
    let fbookings = _.filter(cancelledPositions, b => hasOverlap(booking.data, b.data))
    if (fbookings.length > 1) {
      _.forEach(fbookings, (o, oIndex: number) => {
        if (o.id === booking.id) {
          let top = offsetTop;
          let topSpacing = 16;
          o.top = top + (oIndex * (topSpacing/fbookings.length));
        }
      })
    }
  })

  _.forEach(normalPositions, booking => {
    let fbookings = _.filter(normalPositions, b => hasOverlap(booking.data, b.data))
    if (fbookings.length > 1) {
      _.forEach(fbookings, (o, oIndex: number) => {
        if (o.id === booking.id) {
          let top = BOOKING_OFFSET_TOP_CANCELLED;
          let topSpacing = 16;
          o.top = top + (oIndex * (topSpacing/fbookings.length));
        }
      })
    }
  })

  return [...normalPositions, ...cancelledPositions]
}

export const getBookingLeftDistance = (booking, calendarStartDate, LFCO: Date, EFCI: Date) => {

  let from = new Date(booking.from);
  let to = new Date(booking.to);
  let start = new Date(calendarStartDate);
  start = set(start, { hours: 1, minutes: 0, seconds: 0 })
  let lfco = set(LFCO, { year: getYear(from), month: getMonth(from), date: getDate(from) });
  let efci = set(EFCI, { year: getYear(to), month: getMonth(to), date: getDate(to) });
  if (booking.status === BLOCKED_STATUS_CODE) {
    if(isBefore(from, lfco)) {
      from = set(from, { hours: 0, minutes: 0, seconds: 0 })
    } else {
      from = set(from, { hours: 6, minutes: 0, seconds: 0 })
    }

    if(isBefore(to, efci)) {
      to = set(to, { hours: 6, minutes: 0, seconds: 0 })
    } else {
      to = set(to, { hours: 24, minutes: 0, seconds: 0 })
    }
  } else {
    from = set(from, { hours: 6, minutes: 0, seconds: 0 })
  }

  return (differenceInCalendarDays(from, start) * DAY_WIDTH) + (getHours(from) * 2)
}

export const getBookingWidth = (booking, LFCO: Date, EFCI: Date) => {

  let from = new Date(booking.from);
  let to = new Date(booking.to);
  let lfco = set(LFCO, { year: getYear(from), month: getMonth(from), date: getDate(from) });
  let efci = set(EFCI, { year: getYear(to), month: getMonth(to), date: getDate(to) });
  if (booking.status === BLOCKED_STATUS_CODE) {
    if(isBefore(from, lfco)) {
      from = set(from, { hours: 0, minutes: 0, seconds: 0 })
    } else {
      from = set(from, { hours: 6, minutes: 0, seconds: 0 })
    }
  } else {
    from = set(from, { hours: 6, minutes: 0, seconds: 0 })
  }
  if (booking.status === BLOCKED_STATUS_CODE) {
    if(isBefore(to, efci)) {
      to = set(to, { hours: 6, minutes: 0, seconds: 0 })
    } else {
      to = set(to, { hours: 24, minutes: 0, seconds: 0 })
    }
  } else {
    to = set(to, { hours: 6, minutes: 0, seconds: 0 })
  }
  let duration = differenceInHours(to, from)
  return (duration / 24) * DAY_WIDTH

}

export const getSearchOptionsFromProperties = (properties: Property[]) => {

  let options: SearchOption[] = [];

  properties.map((property) => {
    options.push({ id: property.id, type: 'property', label: property.name })

    property.unitTypes.map(unitType => {
      options.push({ id: unitType.id, type: 'unitType', label: unitType.name })

      unitType.ratePlans.map(ratePlan => {
        options.push({ id: ratePlan.id, type: 'ratePlan', label: ratePlan.name })
      })
      unitType.units.map(unit => {
        options.push({ id: unit.id, type: 'unit', label: unit.name })
      })
    })
  })

  return options;
}

export const getSearchOptionIndex = (properties: Property[], id: number, type: SearchType) => {

  let result = { property: -1, unitType: -1, unit: -1, rate: -1 };

  result.property = _.findIndex(properties, (p: Property) => p.id === id)
  if (type === 'property') {
    return result
  }

  for (let property of properties) {
    let unitTypeIndex = _.findIndex(property.unitTypes, (ut: UnitType) => ut.id === id)
    if (type === 'unitType' && unitTypeIndex !== -1) {
      result.property = _.findIndex(properties, property)
      result.unitType = unitTypeIndex
    }

    for (let unitType of property.unitTypes) {
      let unitIndex = _.findIndex(unitType.units, (u: Unit) => u.id === id)
      let rateIndex = _.findIndex(unitType.ratePlans, (r: RatePlan) => r.id === id)

      if (type === 'unit' && unitIndex !== -1) {
        result.property = _.findIndex(properties, property)
        result.unitType = _.findIndex(property.unitTypes, unitType)
        result.unit = unitIndex
      }

      if (type === 'ratePlan' && rateIndex !== -1) {
        result.property = _.findIndex(properties, property)
        result.unitType = _.findIndex(property.unitTypes, unitType)
        result.rate = rateIndex
      }
    }
  }

  return result
}

export const getSearchOptionOffsetTop = (properties, indexes, showPropertyRow, showUnitTypeRow): number => {

  const { property, unitType, unit, rate } = indexes;
  let offsetTop = 0

  if (property === -1) {
    return offsetTop
  }

  for (let i = 0; i < property; i++) {
    let propertyHeight = properties[i].height
    offsetTop += propertyHeight
  }

  if (unitType === -1) {
    return offsetTop
  }

  offsetTop += (showPropertyRow ? PROPERTY_HEIGHT : 0)
  let p = properties[property]
  for (let i = 0; i < unitType; i++) {
    let unitsOfUnityTypeHeight = p.unitTypes[i].units.reduce((a, b) => ({ height: a.height + b.height }), { height: 0 }).height;
    offsetTop += (unitsOfUnityTypeHeight + (showUnitTypeRow ? UNIT_TYPE_HEIGHT : 0) + ((p.unitTypes[i].unallocatedBookingsCount > 0 && showUnitTypeRow) ? UNALLOCATED_HEIGHT : 0))
  }

  if (unit !== -1) {
    offsetTop += (showUnitTypeRow ? UNIT_TYPE_HEIGHT : 0)
    
    for (let i = 0; i < unit; i++) {
      offsetTop += UNIT_HEIGHT
    }
  }

  if (rate !== -1) {
    offsetTop += (showUnitTypeRow ? UNIT_TYPE_HEIGHT : 0)
    
    for (let i = 0; i < rate; i++) {
      offsetTop += RATE_HEIGHT
    }
  }

  return offsetTop
}

export function snapToGrid(x: number, y: number) {
  const snappedX = Math.round(x / DAY_WIDTH) * DAY_WIDTH
  // const snappedY = Math.round(y / DAY_WIDTH) * DAY_WIDTH
  return [snappedX, y]
}

export function extractProperties(data) {
  return data.reduce(function (accum: { id: number; name: string }[], item) {

    accum.push({ id: item.id, name: item.name });
    return accum;
  }, [])
}

export function extractUnitTypes(data) {
  return data.reduce(function (
    accum: { id: number; name: string; property: { id: number; name: string } }[],
    item
  ) {

    item.unitTypes.map((unitType) =>
      accum.push({
        id: unitType.id,
        name: unitType.name,
        property: { id: item.id, name: item.name },
      })
    );

    return accum;
  }, [])
}

export function extractUnits(data) {
  return data.reduce(function (
    accum: {
      id: number;
      name: string;
      unitType: { id: number; name: string };
      property: { id: number; name: string };
    }[],
    item
  ) {

    item.unitTypes.map((unitType) =>
      unitType.units.map((unit) =>
        accum.push({
          id: unit.id,
          name: unit.name,
          unitType: { id: unitType.id, name: unitType.name },
          property: { id: item.id, name: item.name },
        })
      )
    );

    return accum;
  }, [])
}

export function extractRatePlans(data) {
  return data.reduce(function (
    accum: {
      id: number;
      name: string;
      currency: string;
      unitType: { id: number; name: string };
      property: { id: number; name: string };
    }[],
    item
  ) {

    item.unitTypes.map((unitType) =>
      unitType.ratePlans.map((ratePlan) =>
        accum.push({
          id: ratePlan.id,
          name: ratePlan.name,
          currency: item.currencySymbol,
          unitType: { id: unitType.id, name: unitType.name },
          property: { id: item.id, name: item.name },
        })
      )
    );

    return accum;
  }, [])
}

export const loadDisplaySettingsFromLocalStorage = () => {
  const defaultSettings: CalendarDisplaySetting = {
    showPropertyRow: true,
    showUnitTypeRow: true,
    showPastUnallocated: false,
    showAvgNightlyRate: false,
    showBookingDate: false,
    showCollectionType: false,
    showTotalPrice: true
  };

  try {
    const displaySettingsState = localStorage.getItem('calendarDisplaySettings');
    if (displaySettingsState === null) {
      return defaultSettings;
    }
    return JSON.parse(displaySettingsState);
  } catch (err) {
    return defaultSettings;
  }
}

export const saveDisplaySettingsToLocalStorage = (displaySettings: CalendarDisplaySetting) => {
  try {
    const displaySettingsState = JSON.stringify(displaySettings);
    localStorage.setItem('calendarDisplaySettings', displaySettingsState);
  } catch {
    // ignore write errors
  }
};
