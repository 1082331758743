import { alpha, MenuItem, Paper, styled, TextField } from "@mui/material";

export const StyledInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'active',
})<{ open: boolean; active: boolean, select?: boolean }>(({ theme, open, active, select = false }) => ({
  textTransform: 'capitalize',
  cursor: 'pointer',
  ...(select && { minWidth: 128 }),
  label: {
    ...theme.typography.body2,
    ...(!active && { marginTop: theme.spacing(-0.5) }),
    color: theme.palette.text.primary,
    fontWeight: 600,
    maxWidth: 'calc(100% - 40px)',
  },
  svg: {
    top: 'calc(50% - 0.6em)',
  },
  '& .MuiOutlinedInput-input': {
    cursor: 'pointer',
    ...(!select && { width: 0 }),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  '& .Mui-focused': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiInputBase-root': {
    cursor: 'pointer',
    borderRadius: theme.spacing(4),
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: open
        ? theme.palette.primary.main
        : active
          ? theme.palette.grey[600]
          : theme.palette.grey[500_32],
    },
    '&:hover': {
      backgroundColor: open
        ? alpha(theme.palette.primary.main, 0.08)
        :  theme.palette.grey[200],
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: open
          ? theme.palette.primary.main
          : active
            ? theme.palette.grey[600]
            : theme.palette.grey[500_32],
      },
    },
  },
}));

export const PaperStyle = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 1),
  borderRadius: theme.spacing(2),
  position: 'absolute',
  //top: 104,
  zIndex: 1300,
}));

export const ListBox = styled('ul')(({ theme }) => ({
  padding: theme.spacing(0.5, 0),
  zIndex: 1,
  listStyle: 'none',
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
  maxHeight: 170,
}));

export const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: 8,
  marginRight: theme.spacing(0.5),
  height: 40,
}));
