import { alpha, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

function scrollButtonBackgroundEffect(deg: number, theme: Theme) {
  const color = theme.palette.background.default;
  return `linear-gradient(${deg}deg, ${color} 0%, ${alpha(color, 0.546875)} 57.29%, ${alpha(
    color,
    0
  )} 100%)`;
}

export default function Tabs(theme: Theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: theme.spacing(1),
          marginLeft: theme.spacing(1),
          fontWeight: theme.typography.fontWeightMedium,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
          '&.Mui-selected': {
            color: theme.palette.text.primary,
          },
          '&:not(:last-of-type)': {
            marginRight: theme.spacing(0),
          },
          '@media (min-width: 600px)': {
            minWidth: 48,
          },
        },
        labelIcon: {
          minHeight: 48,
          flexDirection: 'row',
          '& > *:first-of-type': {
            marginBottom: 0,
            marginRight: theme.spacing(1),
          },
        },
        wrapper: {
          flexDirection: 'row',
          whiteSpace: 'nowrap',
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          position: 'absolute',
          zIndex: 1,
          '& .MuiSvgIcon-root': {
            width: 24,
            height: 24,
            opacity: 0,
            transition: '0.3s',
          },
          '&:hover': {
            '& .MuiSvgIcon-root': {
              opacity: 1,
            },
          },
        },
        horizontal: {
          width: 56,
          height: '100%',
          '&:first-of-type': {
            left: 0,
            padding: theme.spacing(0, 3, 0, 1),
            background: scrollButtonBackgroundEffect(90, theme),
            '& .MuiTouchRipple-root': {
              margin: theme.spacing(0.5, 2, 0.5, 0),
            },
          },
          '&:last-child': {
            right: 0,
            padding: theme.spacing(0, 1, 0, 3),
            background: scrollButtonBackgroundEffect(-90, theme),
            '& .MuiTouchRipple-root': {
              margin: theme.spacing(0.5, 0, 0.5, 2),
            },
          },
          '& .MuiTouchRipple-root': {
            borderRadius: '50%',
          },
        },
        vertical: {
          height: 56,
          width: '100%',
          '&:first-of-type': {
            top: 0,
            padding: theme.spacing(1, 0, 3, 0),
            background: scrollButtonBackgroundEffect(180, theme),
            '& .MuiTouchRipple-root': {
              marginBottom: theme.spacing(2),
            },
          },
          '&:last-child': {
            bottom: 0,
            padding: theme.spacing(3, 0, 1, 0),
            background: scrollButtonBackgroundEffect(0, theme),
            '& .MuiTouchRipple-root': {
              marginTop: theme.spacing(2),
            },
          },
        },
      },
    },
  };
}
