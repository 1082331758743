import jwtDecode from 'jwt-decode';
import { OAUTH2_API } from '../config';
//
import axios from './axios';

// ----------------------------------------------------------------------

enum GrantType {
  Password = 'password',
  RefreshToken = 'refresh_token',
  AuthorizationCode = 'authorization_code',
}

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const checkCode = (code: number) =>
      process.env.REACT_APP_SHOW_SERVER_ERROR === 'true' && error?.response?.status === code;

    if (checkCode(401)) {
      setSession(null);
      setRefreshToken(null);
      if (error.config.url !== '/oauth2-token') {
        window.location.reload();
      }
    }

    if (checkCode(403)) {
      window.location.replace(`/403?origin=${window.location.href}`);
    }

    if (checkCode(404)) {
      window.location.replace(`/404?origin=${window.location.href}`);
    }

    return Promise.reject(error.response);
  }
);

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime - 60000;

  expiredTimer = window.setTimeout(async () => {
    const refreshToken = window.localStorage.getItem('refreshToken');
    if (refreshToken) {
      const response = await axios.post('/oauth2-token', {
        grant_type: GrantType.RefreshToken,
        ...OAUTH2_API,
        refresh_token: refreshToken,
      });
      const { access_token, refresh_token } = response.data;
      setSession(access_token);
      setRefreshToken(refresh_token);
    }
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setRefreshToken = (refreshToken: string | null) => {
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('refreshToken');
  }
};

const setOrganization = (organizationId: string | null) => {
  if (organizationId) {
    localStorage.setItem('currentOrganization', organizationId);
  } else {
    localStorage.removeItem('currentOrganization');
  }

  axios.interceptors.request.use((config) => {
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    const currentOrganization = localStorage.getItem('currentOrganization');
    if (currentOrganization) {
      config.headers.Organization = `${currentOrganization}`;
    }
    return config;
  });
};

const setUserCredentials = (username: string | null, password: string | null) => {
  if (username) {
    localStorage.setItem('username', username);
  } else {
    localStorage.removeItem('username');
  }

  if (password) {
    localStorage.setItem('password', password);
  } else {
    localStorage.removeItem('password');
  }
};

const setRedirectURL = (setRedirectURL: string | null) => {
  if (setRedirectURL) {
    localStorage.setItem('redirectURL', setRedirectURL);
  } else {
    localStorage.removeItem('redirectURL');
  }
};

export {
  GrantType,
  isValidToken,
  setSession,
  setRefreshToken,
  setOrganization,
  setUserCredentials,
  setRedirectURL,
};
