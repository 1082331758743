import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import {
  generateListingTabs,
  getPropertyMode,
  getUnitTypeByUnitId,
  viewLevelController,
} from 'src/utils/listing';
import {
  ListingMenuConfig,
  ListingPageTab,
  ListingState,
  ListingUnitMenuConfig,
  ListingUnitTypeMenuConfig,
  PropertyData,
  PropertyMode,
  PropertyTab,
  PropertyTaskResponsible,
  TaskResponsibleApiBody,
  UpdatePropertyBodyType,
  UnitData,
  UnitTab,
  UnitTypeData,
  UnitTypeTab,
  ViewLevel,
  RatePlan,
  CheckinType,
  BlockedDate,
  ListingTabsData,
  ICalImport,
  Building,
  UnitTypeUpsell,
  FaqCategory,
  PropertyBatchUpdateResponseType,
  UnittypeBatchUpdateResponseType,
} from '../../@types/listings';
import { dispatch, RootState } from '../store';
import rateAPI from 'src/api/rate';
import listingsAPI from 'src/api/listings';
import bookingAPI from 'src/api/booking';
import axios, { AxiosResponse } from 'axios';
import { Amenity, Review, Variable, Meta, Brand, InternalNote } from 'src/@types/common';
import { mergeArrays } from 'src/utils/arrayUtils';
import automatedMessageAPI from 'src/api/automated-message';
import { provideVariables } from 'src/utils/automatedMessage';
import { forEach } from 'lodash';
import { Upsell } from 'src/@types/upsell';
import api from 'src/api/api';

// ----------------------------------------------------------------------

const initialState: ListingState = {
  tabs: [
    'details',
    'pricing_and_availability',
    'policies',
    'instructions',
    'marketing',
    'operation',
  ],
  viewLevel: 'property',
  propertyMode: 'complex',
  shouldShowAlert: false,
  menuConfig: {
    id: 0,
    name: '',
    short_name: '',
    unit_types: [],
    address: {
      city: '',
      country: {
        iso2_code: '',
        name: '',
      },
    },
    channel_enabled: false,
    channel_sync_status: '',
    buildings: [],
    default_currency: {
      code: '',
      symbol: '',
    },
    created_at: null,
    updated_at: null,
    su_last_synced_at: null,
    timezone: '',
    more_than_one_building: false,
    more_than_one_floor: false,
    channel_manager: 'su',
    organization_config: {
      channel_manager: 'su',
    },
    organization: {
      name: '',
    },
    mapped_rate_plans: false,
    rate_plans_connected_to_channels: false,
    is_connected_rate_plan_to_booking_engine: false,
    rate_plans_connected_to_booking_engine: [],
    unitTypeCount: 0,
    property_link: '',
    is_active: false,
    image_url: '',
    google_connection_status: 'NOT_CONNECTED',
    google_hotel_link: '',
    loading: true,
    google_maximum_rate: {
      value: 0,
      currency: 'GBP'
    }
  },
  tabsData: {
    details: {
      loading: {
        property: [],
        unitType: [],
        unit: [],
      },
      property: null,
      unitTypes: {},
      units: {},
    },
    pricing_and_availability: {
      loading: {
        property: [],
        unitType: [],
        unit: [],
      },
      property: null,
      unitTypes: {},
      units: {},
    },
    policies: {
      loading: { property: [] },
      property: null,
      unitTypes: {},
      units: {},
    },
    instructions: {
      loading: {
        property: [],
        unit: [],
      },
      property: null,
      unitTypes: {},
      units: {},
    },
    marketing: {
      loading: {
        property: [],
        unitType: [],
        unit: [],
      },
      property: null,
      unitTypes: {},
      units: {},
    },
    operation: {
      loading: {
        property: [],
        unitType: [],
        unit: [],
      },
      property: null,
      unitTypes: {},
      units: {},
    },
  },
  systemAmenities: [],
  popupsState: {
    overrideDialog: {
      open: false,
      onSubmitFunction: () => { },
    },
    insufficientLicencesDialog: {
      open: false,
      neededLicences: {},
    },
  },
  faqCategories: [],
  checkinTypes: [],
  checkinTypeVariables: [],
  disconnectingRatePLans: [],
  reviews: {
    data: [],
    propertyId: null,
  },
  channelManager: {
    suURL: null,
  },
  manageBrands: {
    open: false,
    organizationBrands: [],
    listingBrands: [],
    defaultBrand: null,
  },
};

const slice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    reset: () => initialState,
    setViewLevel(state, action: PayloadAction<ViewLevel>) {
      state.viewLevel = action.payload;
    },
    setShouldShowAlert(state, action: PayloadAction<boolean>) {
      state.shouldShowAlert = action.payload;
    },
    setPropertyMode(state, action: PayloadAction<PropertyMode>) {
      state.propertyMode = action.payload;
    },
    setOverrideDialogOpen(
      state,
      action: PayloadAction<Partial<ListingState['popupsState']['overrideDialog']>>
    ) {
      state.popupsState.overrideDialog = {
        ...state.popupsState.overrideDialog,
        ...action.payload,
      };
    },
    setInsufficientLicencesDialogState(
      state,
      action: PayloadAction<Partial<ListingState['popupsState']['insufficientLicencesDialog']>>
    ) {
      state.popupsState.insufficientLicencesDialog = {
        ...state.popupsState.insufficientLicencesDialog,
        ...action.payload,
      };
    },
    setMenuConfig(state, action: PayloadAction<ListingMenuConfig>) {
      state.menuConfig = action.payload;
    },
    removeFromMenuConfig(state, action: PayloadAction<{ type: ViewLevel; removeId: number }>) {
      const { removeId, type } = action.payload;
      if (type === 'property') {
        state.menuConfig = initialState.menuConfig;
      } else if (type === 'unitType') {
        const unitTypes = state.menuConfig.unit_types;
        state.menuConfig.unit_types = unitTypes.filter(({ id }) => id !== removeId);
      } else if (type === 'unit') {
        const unitType = getUnitTypeByUnitId(state.menuConfig.unit_types, removeId);
        if (unitType) {
          const { units } = unitType;
          const index = state.menuConfig.unit_types.findIndex(({ id }) => id === unitType.id);
          state.menuConfig.unit_types[index].units = units.filter(({ id }) => id !== removeId);
        }
      }
    },
    updateMenuConfig(
      state,
      action: PayloadAction<
        | { id?: number; key: 'property'; value: Partial<ListingMenuConfig> }
        | { id?: number; key: 'unit_type'; value: Partial<ListingUnitTypeMenuConfig> }
        | { id?: number; key: 'unit'; value: Partial<ListingUnitMenuConfig> }
      >
    ) {
      const { id, key, value } = action.payload;
      if (key === 'property') {
        state.menuConfig = {
          ...state.menuConfig,
          ...value,
        };
      } else if (key === 'unit_type') {
        const unitTypeIndex = state.menuConfig.unit_types.findIndex((item) => item.id === id);
        state.menuConfig.unit_types[unitTypeIndex] = {
          ...state.menuConfig.unit_types[unitTypeIndex],
          ...value,
        };
      } else if (key === 'unit') {
        state.menuConfig.unit_types.forEach((unitType, index) => {
          const unitIndex = unitType.units.findIndex((item) => item.id === id);
          if (unitIndex >= 0) {
            state.menuConfig.unit_types[index].units[unitIndex] = {
              ...state.menuConfig.unit_types[index].units[unitIndex],
              ...value,
            };
          }
        });
      }
    },
    updateRatePlansConnectedToBookingEngine(
      state,
      action: PayloadAction<{ id: number; add: boolean }>
    ) {
      const { id, add } = action.payload;
      state.menuConfig.rate_plans_connected_to_booking_engine = add
        ? [...state.menuConfig.rate_plans_connected_to_booking_engine, { id }]
        : state.menuConfig.rate_plans_connected_to_booking_engine.filter(
          (ratePlan) => ratePlan.id !== id
        );
    },
    setMenuConfigLoading(state, action: PayloadAction<boolean>) {
      state.menuConfig.loading = action.payload;
    },
    setPropertyTabData(
      state,
      action: PayloadAction<{ id: number; tab: PropertyTab; data: PropertyData | null }>
    ) {
      const { tab, data } = action.payload;
      state.tabsData[tab].property = data;
    },
    setUnitTypeTabData(
      state,
      action: PayloadAction<{ id: number; tab: UnitTypeTab; data: UnitTypeData }>
    ) {
      const { id, tab, data } = action.payload;
      state.tabsData[tab].unitTypes = {
        ...state.tabsData[tab].unitTypes,
        [id]: data,
      };
    },
    setUnitTabData(state, action: PayloadAction<{ id: number; tab: UnitTab; data: UnitData }>) {
      const { id, tab, data } = action.payload;
      state.tabsData[tab].units = {
        ...state.tabsData[tab].units,
        [id]: data,
      };
    },
    updatePropertyTabData(
      state,
      action: PayloadAction<{ tab: PropertyTab; newField: Partial<PropertyData> }>
    ) {
      const { tab, newField } = action.payload;
      const existingPropertyData = state.tabsData[tab].property;
      if (existingPropertyData) {
        // @ts-ignore
        state.tabsData[tab].property = {
          ...existingPropertyData,
          ...newField,
          id: existingPropertyData.id,
        };
      }
    },
    updateUnitTypeTabData(
      state,
      action: PayloadAction<{ tab: UnitTypeTab; id: number; newField: Partial<UnitTypeData> }>
    ) {
      const { tab, id, newField } = action.payload;
      // @ts-ignore
      state.tabsData[tab].unitTypes[id] = { ...state.tabsData[tab].unitTypes[id], ...newField };
    },
    updateUnitTabData(
      state,
      action: PayloadAction<{ tab: UnitTab; id: number; newField: Partial<UnitData> }>
    ) {
      const { tab, id, newField } = action.payload;
      state.tabsData[tab].units[id] = { ...state.tabsData[tab].units[id], ...newField };
    },
    updateUnitsTabData(
      state,
      action: PayloadAction<{
        tab: UnitTab;
        unitId: number;
        newField: Partial<UnitData>;
        unitTypeId: number;
        applyLevel: ViewLevel;
      }>
    ) {
      const { applyLevel, tab, unitId, newField, unitTypeId } = action.payload;
      if (applyLevel === 'unit') {
        state.tabsData[tab].units[unitId] = { ...state.tabsData[tab].units[unitId], ...newField };
      } else if (applyLevel === 'unitType') {
        state.menuConfig.unit_types
          .find((unitType) => unitType.id === unitTypeId)
          ?.units.forEach((unit) => {
            if (state.tabsData[tab].units[unit.id]) {
              state.tabsData[tab].units[unit.id] = {
                ...state.tabsData[tab].units[unit.id],
                ...newField,
              };
            }
          });
      } else {
        Object.keys(state.tabsData[tab].units).forEach((key) => {
          state.tabsData[tab].units[key] = { ...state.tabsData[tab].units[key], ...newField };
        });
      }
    },
    updateBuilding(state, action: PayloadAction<{ id: number; newField: Partial<Building> }>) {
      const { id, newField } = action.payload;
      const propertyBuildings = state.tabsData.details.property?.buildings;
      const configBuildings = state.menuConfig.buildings;
      if (state.tabsData.details.property && propertyBuildings && propertyBuildings.length > 0) {
        const index = propertyBuildings.findIndex((building) => building.id === id);
        state.tabsData.details.property.buildings[index] = {
          ...state.tabsData.details.property.buildings[index],
          ...newField,
        };
      }
      if (configBuildings && configBuildings.length > 0) {
        const index = configBuildings.findIndex((building) => building.id === id);
        state.menuConfig.buildings[index] = {
          ...state.menuConfig.buildings[index],
          ...newField,
        };
      }
    },
    removeRatePlan(state, action: PayloadAction<{ id: number; unitTypeId: number }>) {
      const { unitTypeId, id } = action.payload;
      const ratePlans = state.tabsData.pricing_and_availability.unitTypes[unitTypeId].rate_plans;
      state.tabsData.pricing_and_availability.unitTypes[unitTypeId].rate_plans = ratePlans.filter(
        (ratePlan) => ratePlan.id !== id
      );
      if (state.tabsData.details.unitTypes[unitTypeId]) {
        state.tabsData.details.unitTypes[unitTypeId].rate_plans = ratePlans.filter(
          (ratePlan) => ratePlan.id !== id
        );
      }
    },
    addUpsell(
      state,
      action: PayloadAction<{ unitTypeUpsell: UnitTypeUpsell[]; unitTypeId: number }>
    ) {
      const { unitTypeUpsell, unitTypeId } = action.payload;
      state.tabsData.pricing_and_availability.unitTypes[unitTypeId].unit_type_upsells = [
        ...state.tabsData.pricing_and_availability.unitTypes[unitTypeId].unit_type_upsells,
        ...unitTypeUpsell,
      ];
    },
    updateUpsell(
      state,
      action: PayloadAction<{
        upsellId: number;
        upsell: Partial<Upsell>;
        unitTypeUpsell: Partial<Upsell>;
        unitTypeId: number;
      }>
    ) {
      const { upsellId, upsell, unitTypeUpsell, unitTypeId } = action.payload;
      const { unit_type_upsells } = state.tabsData.pricing_and_availability.unitTypes[unitTypeId];
      const index = unit_type_upsells.findIndex(({ upsell: { id } }) => id === upsellId);
      if (index > -1) {
        state.tabsData.pricing_and_availability.unitTypes[unitTypeId].unit_type_upsells[index] = {
          ...unit_type_upsells[index],
          ...unitTypeUpsell,
          upsell: {
            ...unit_type_upsells[index].upsell,
            ...upsell,
          },
        };
      }
    },
    removeUpsell(state, action: PayloadAction<{ ids: number[]; unitTypeId: number }>) {
      const { ids, unitTypeId } = action.payload;
      const { unit_type_upsells } = state.tabsData.pricing_and_availability.unitTypes[unitTypeId];
      state.tabsData.pricing_and_availability.unitTypes[unitTypeId].unit_type_upsells =
        unit_type_upsells.filter(({ upsell }) => !ids.includes(upsell.id));
    },
    removeUpsellFromListing(state, action: PayloadAction<{ ids: number[] }>) {
      const { ids } = action.payload;
      const unitTypes = Object.keys(state.tabsData.pricing_and_availability.unitTypes);
      unitTypes.forEach((unitTypeId) => {
        const { unit_type_upsells } = state.tabsData.pricing_and_availability.unitTypes[unitTypeId];
        state.tabsData.pricing_and_availability.unitTypes[unitTypeId].unit_type_upsells =
          unit_type_upsells.filter(({ upsell }) => !ids.includes(upsell.id));
      });
    },
    createRatePlan(state, action: PayloadAction<{ unitTypeId: number; newRatePlan: RatePlan }>) {
      const { unitTypeId, newRatePlan } = action.payload;
      const ratePlan: RatePlan = {
        ...newRatePlan,
        promotion_applications: [],
        rate_rules: [],
        total_price: null,
        payment_term: null,
        tax_rate: null,
        channel_manager: 'none',
        mapped_channels: [],
        channel_sync_status: 'Not Connected',
        has_bookings: false,
        channel_manager_errors: [],
      };
      state.tabsData.pricing_and_availability.unitTypes[unitTypeId].rate_plans = [
        ...state.tabsData.pricing_and_availability.unitTypes[unitTypeId].rate_plans,
        ratePlan,
      ];

      if (state.tabsData.details.unitTypes[unitTypeId]) {
        state.tabsData.details.unitTypes[unitTypeId].rate_plans = [
          ...state.tabsData.details.unitTypes[unitTypeId].rate_plans,
          ratePlan,
        ];
      }
    },
    updateRatePlanStates(
      state,
      action: PayloadAction<{ id: number; unitTypeId: number; newField: Partial<RatePlan> }>
    ) {
      const { id, unitTypeId, newField } = action.payload;
      const tabs = ['details', 'pricing_and_availability'];
      tabs.forEach((tab) => {
        const ratePlans = state.tabsData[tab].unitTypes[unitTypeId]?.rate_plans || [];
        const index = ratePlans.findIndex((ratePlan) => ratePlan.id === id);
        if (index > -1) {
          state.tabsData[tab].unitTypes[unitTypeId].rate_plans[index] = {
            ...ratePlans[index],
            ...newField,
          };
        }
      });
    },
    updateBlockDate(
      state,
      action: PayloadAction<{ id: number; calendarId: number; newField: Partial<BlockedDate> }>
    ) {
      const { id, calendarId, newField } = action.payload;
      const { unitTypes, units } = state.tabsData.pricing_and_availability;
      (
        [
          { key: 'units', listing: units },
          { key: 'unitTypes', listing: unitTypes },
        ] as {
          key: 'units' | 'unitTypes';
          listing:
          | ListingTabsData['pricing_and_availability']['unitTypes']
          | ListingTabsData['pricing_and_availability']['units'];
        }[]
      ).forEach(({ key, listing }) => {
        const listingIds = Object.keys(listing);
        listingIds.forEach((listingId) => {
          const { ical_imports } = state.tabsData.pricing_and_availability[key][listingId];
          state.tabsData.pricing_and_availability[key][listingId].ical_imports = ical_imports.map(
            (iCalImport) => ({
              ...iCalImport,
              ...(iCalImport.id === calendarId && {
                blocked_dates: iCalImport.blocked_dates.map((blockedDate) => ({
                  ...blockedDate,
                  ...(blockedDate.id === id && newField),
                })),
              }),
            })
          );
        });
      });
    },
    markRatePlanAsDefault(state, action: PayloadAction<{ id: number; unitTypeId: number }>) {
      const { id, unitTypeId } = action.payload;
      const ratePlans =
        state.tabsData.pricing_and_availability.unitTypes[unitTypeId]?.rate_plans || [];
      state.tabsData.pricing_and_availability.unitTypes[unitTypeId].rate_plans = ratePlans.map(
        (ratePlan) => ({
          ...ratePlan,
          is_default: ratePlan.id === id,
        })
      );
    },
    setTabsLoading(
      state,
      action: PayloadAction<{
        tab: ListingPageTab;
        loading: boolean;
        viewLevel: ViewLevel;
        id: number;
      }>
    ) {
      const { loading, tab, viewLevel, id } = action.payload;
      let loadingIds = state.tabsData[tab].loading[viewLevel];
      if (loading) loadingIds?.push(id);
      else loadingIds = loadingIds?.filter((loadingId) => loadingId !== id);
      state.tabsData[tab].loading[viewLevel] = loadingIds;
    },
    addUnitsToMenuConfig(
      state,
      action: PayloadAction<{ unitTypeId: number; units: ListingUnitMenuConfig[] }>
    ) {
      const index = state.menuConfig.unit_types.findIndex(
        ({ id }) => id === action.payload.unitTypeId
      );
      if (index > -1) {
        state.menuConfig.unit_types[index].units = [
          ...state.menuConfig.unit_types[index].units,
          ...action.payload.units,
        ];
      }
    },
    setSystemAmenities(state, action: PayloadAction<Amenity[]>) {
      state.systemAmenities = action.payload;
    },
    updateUnitTypeHousekeepingTime(
      state,
      action: PayloadAction<{ unitTypeId: number; newValue: number }>
    ) {
      const { newValue, unitTypeId } = action.payload;
      const existingPropertyPolicies = state.tabsData.policies.property;
      if (existingPropertyPolicies) {
        state.tabsData.policies.property = {
          ...existingPropertyPolicies,
          unit_types: existingPropertyPolicies.unit_types.map((unitType) =>
            unitType.id === unitTypeId
              ? { ...unitType, average_house_keeping_time: newValue }
              : unitType
          ),
        };
      }
    },
    addPropertyTaskResponsibles(state, action: PayloadAction<PropertyTaskResponsible[]>) {
      if (state.tabsData.operation.property) {
        state.tabsData.operation.property.task_responsibiles = [
          ...state.tabsData.operation.property.task_responsibiles,
          ...action.payload,
        ];
      }
    },
    removePropertyTaskResponsibles(state, action: PayloadAction<number>) {
      if (state.tabsData.operation.property) {
        const taskResponsibles = state.tabsData.operation.property.task_responsibiles;
        state.tabsData.operation.property.task_responsibiles = taskResponsibles.filter(
          ({ id }) => id !== action.payload
        );
      }
    },
    updatePropertyTaskResponsibles(
      state,
      action: PayloadAction<{ responsibleId: number, newField: Partial<PropertyTaskResponsible> }>
    ) {
      if (state.tabsData.operation.property) {
        const { responsibleId, newField } = action.payload;
        const taskResponsibles = state.tabsData.operation.property.task_responsibiles;
        const index = taskResponsibles.findIndex(({ responsible }) => responsible.id === responsibleId);
        if (index >= 0 && taskResponsibles[index]) {
          state.tabsData.operation.property.task_responsibiles[index] = {
            ...state.tabsData.operation.property.task_responsibiles[index],
            ...newField
          };
        }
      }
    },
    setFaqCategories(state, action: PayloadAction<FaqCategory[]>) {
      state.faqCategories = action.payload;
    },
    setCheckinTypes(state, action: PayloadAction<CheckinType[]>) {
      state.checkinTypes = action.payload;
    },
    setCheckinTypeVariables(state, action: PayloadAction<Variable[]>) {
      state.checkinTypeVariables = action.payload;
    },
    setDisconnectingRatePlans(state, action: PayloadAction<{ id: number; add: boolean }>) {
      const { id, add } = action.payload;
      state.disconnectingRatePLans = add
        ? [...state.disconnectingRatePLans, id]
        : state.disconnectingRatePLans.filter((ratePlanId) => ratePlanId !== id);
    },
    setReviews(
      state,
      action: PayloadAction<{
        data?: Review[];
        meta?: Meta;
        page?: number;
        isFiltered?: boolean;
        propertyId?: number;
      }>
    ) {
      const { data, meta, page, isFiltered, propertyId } = action.payload;
      state.reviews = {
        data:
          page !== undefined && page === 1
            ? data || state.reviews.data
            : [...(state.reviews?.data || []), ...(data || [])],
        meta: {
          ...meta,
          total_items:
            (isFiltered === false ? meta?.total_items : state.reviews?.meta?.total_items) || 0,
        } as Meta,
        propertyId: propertyId || state.reviews?.propertyId || null,
      };
    },
    createICalImport(
      state,
      action: PayloadAction<{
        iCal: ICalImport;
        unitIds: string[];
        unitTypeId: string;
        viewLevel: ViewLevel;
      }>
    ) {
      const { iCal, unitIds, unitTypeId, viewLevel } = action.payload;
      if (viewLevel !== 'unit') {
        // add ical to unit type level
        state.tabsData.pricing_and_availability.unitTypes[unitTypeId].ical_imports = [
          ...state.tabsData.pricing_and_availability.unitTypes[unitTypeId].ical_imports,
          iCal,
        ];
        // add ical for each unit available in unitIds
        let loadedUnits = Object.keys(state.tabsData.pricing_and_availability.units).filter(
          (unit) => unitIds.includes(unit)
        );
        forEach(loadedUnits, (unitId) => {
          state.tabsData.pricing_and_availability.units[unitId].ical_imports = [
            ...state.tabsData.pricing_and_availability.units[unitId].ical_imports,
            iCal,
          ];
        });
      } else {
        // check if unit type is loaded
        const hasUnitType = Object.keys(state.tabsData.pricing_and_availability.unitTypes).indexOf(
          unitTypeId
        );
        if (hasUnitType >= 0) {
          // add ical to unit type
          state.tabsData.pricing_and_availability.unitTypes[unitTypeId].ical_imports = [
            ...state.tabsData.pricing_and_availability.unitTypes[unitTypeId].ical_imports,
            iCal,
          ];
        }

        // add ical to unit
        const unitId = unitIds[0];
        state.tabsData.pricing_and_availability.units[unitId].ical_imports = [
          ...state.tabsData.pricing_and_availability.units[unitId].ical_imports,
          iCal,
        ];
      }
    },
    updateICalImport(
      state,
      action: PayloadAction<{
        iCal: Partial<ICalImport>;
        unitIds: string[];
        unitTypeId: string;
        viewLevel: ViewLevel;
      }>
    ) {
      const { iCal, unitIds, unitTypeId, viewLevel } = action.payload;
      const { id } = iCal;
      if (viewLevel !== 'unit') {
        // add ical to unit type level
        const iCalIndex = state.tabsData.pricing_and_availability.unitTypes[
          unitTypeId
        ].ical_imports.findIndex((ical_import) => ical_import.id === id);
        if (iCalIndex >= 0) {
          state.tabsData.pricing_and_availability.unitTypes[unitTypeId].ical_imports[iCalIndex] = {
            ...state.tabsData.pricing_and_availability.unitTypes[unitTypeId].ical_imports[
            iCalIndex
            ],
            ...iCal,
          };
        }
        // add ical for each unit available in unitIds
        let loadedUnits = Object.keys(state.tabsData.pricing_and_availability.units).filter(
          (unit) => unitIds.includes(unit)
        );
        forEach(loadedUnits, (unitId) => {
          const iCalIndex = state.tabsData.pricing_and_availability.units[
            unitId
          ].ical_imports.findIndex((ical_import) => ical_import.id === id);
          if (iCalIndex >= 0) {
            state.tabsData.pricing_and_availability.units[unitId].ical_imports[iCalIndex] = {
              ...state.tabsData.pricing_and_availability.units[unitId].ical_imports[iCalIndex],
              ...iCal,
            };
          }
        });
      } else {
        // check if unit type is loaded
        const hasUnitType = Object.keys(state.tabsData.pricing_and_availability.unitTypes).indexOf(
          unitTypeId
        );
        if (hasUnitType >= 0) {
          // add ical to unit type
          const iCalIndex = state.tabsData.pricing_and_availability.unitTypes[
            unitTypeId
          ].ical_imports.findIndex((ical_import) => ical_import.id === id);
          if (iCalIndex >= 0) {
            state.tabsData.pricing_and_availability.unitTypes[unitTypeId].ical_imports[iCalIndex] =
            {
              ...state.tabsData.pricing_and_availability.unitTypes[unitTypeId].ical_imports[
              iCalIndex
              ],
              ...iCal,
            };
          }
        }

        // add ical to unit
        const unitId = unitIds[0];
        const iCalIndex = state.tabsData.pricing_and_availability.units[
          unitId
        ].ical_imports.findIndex((ical_import) => ical_import.id === id);
        if (iCalIndex >= 0) {
          state.tabsData.pricing_and_availability.units[unitId].ical_imports[iCalIndex] = {
            ...state.tabsData.pricing_and_availability.units[unitId].ical_imports[iCalIndex],
            ...iCal,
          };
        }
      }
    },
    updateReviews(state, action: PayloadAction<Review>) {
      const index = state.reviews?.data.findIndex((review) => review.id === action.payload.id);
      if (index !== undefined && index > -1) {
        const copyOfReviews = [...(state.reviews?.data || [])];
        copyOfReviews.splice(index, 1, action.payload);
        state.reviews = { ...state.reviews, data: copyOfReviews };
      } else {
        state.reviews = {
          ...state.reviews,
          data: [action.payload, ...(state.reviews?.data || [])],
          meta: {
            ...(state.reviews?.meta as Meta),
            total_items: (state.reviews?.meta?.total_items as number) + 1,
          },
        };
      }
    },
    deleteAReview(state, action: PayloadAction<number>) {
      if (state.reviews) {
        state.reviews = {
          ...state.reviews,
          data: state.reviews?.data.filter((review) => review.id !== action.payload) || [],
          meta: {
            ...(state.reviews.meta as Meta),
            total_items: (state.reviews.meta?.total_items as number) - 1,
          },
        };
      }
    },
    setSuURL(state, action: PayloadAction<{ suURL: string | null }>) {
      state.channelManager.suURL = action.payload.suURL;
    },
    setManageBrandsOpen(state, action: PayloadAction<boolean>) {
      state.manageBrands.open = action.payload;
    },
    setManageBrandsState(
      state,
      action: PayloadAction<{
        organizationBrands?: Brand[];
        listingBrands?: Brand[];
        defaultBrand?: Brand | null;
      }>
    ) {
      state.manageBrands = {
        ...state.manageBrands,
        ...action.payload,
      };
    },
    addInternalNote(state, action: PayloadAction<InternalNote>) {
      if (state.tabsData.details.property) {
        state.tabsData.details.property.notes = [
          ...state.tabsData.details.property.notes,
          action.payload,
        ];
      }
    },
    updateInternalNote(state, action: PayloadAction<InternalNote>) {
      if (state.tabsData.details.property) {
        const { id, message } = action.payload;
        const noteIndex = state.tabsData.details.property.notes.findIndex((note) => note.id === id);

        if (noteIndex !== -1) {
          state.tabsData.details.property.notes[noteIndex] = {
            ...state.tabsData.details.property.notes[noteIndex],
            message,
          };
        }
      }
    },
    deleteInternalNote(state, action: PayloadAction<InternalNote>) {
      if (state.tabsData.details.property) {
        const { id } = action.payload;
        const newNotes = state.tabsData.details.property.notes.filter((note) => note.id !== id);
        state.tabsData.details.property.notes = newNotes;
      }
    },
    moveUnitToAnotherUnitType(
      state,
      action: PayloadAction<{ currentUnitTypeId: number; sourceUnitTypeId: number; unitId: number }>
    ) {
      const { currentUnitTypeId, sourceUnitTypeId, unitId  } = action.payload;
      const currentUnitTypeIndex = state.menuConfig.unit_types.findIndex(
        ({ id }) => id === currentUnitTypeId
      ); 
      const newUnitTypeIndex = state.menuConfig.unit_types.findIndex(({ id }) => id === sourceUnitTypeId);
       if (currentUnitTypeIndex > -1 && newUnitTypeIndex > -1) {
        const unitToMove = state.menuConfig.unit_types[currentUnitTypeIndex].units.find(
          (unit) => unit.id === unitId
        );
        if (unitToMove) {
          state.menuConfig.unit_types[currentUnitTypeIndex].units = state.menuConfig.unit_types[
            currentUnitTypeIndex
          ].units.filter((unit) => unit.id !== unitId);
          state.menuConfig.unit_types[newUnitTypeIndex].units = [
            ...state.menuConfig.unit_types[newUnitTypeIndex].units,
            unitToMove,
          ];
        }
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  reset,
  setTabsLoading,
  setViewLevel,
  setShouldShowAlert,
  setPropertyMode,
  setOverrideDialogOpen,
  setInsufficientLicencesDialogState,
  markRatePlanAsDefault,
  updateBlockDate,
  setMenuConfig,
  updateMenuConfig,
  updateRatePlansConnectedToBookingEngine,
  addUnitsToMenuConfig,
  updatePropertyTabData,
  updateUnitTabData,
  updateUnitTypeTabData,
  createICalImport,
  updateICalImport,
  updateRatePlanStates,
  setDisconnectingRatePlans,
  addUpsell,
  updateUpsell,
  removeUpsell,
  removeUpsellFromListing,
  setReviews,
  updateReviews,
  deleteAReview,
  setSuURL,
  setManageBrandsOpen,
  setManageBrandsState,
  addInternalNote,
  updateInternalNote,
  deleteInternalNote,
  updatePropertyTaskResponsibles,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPropertyConfig(
  key: ViewLevel,
  id: number,
  fallback?: (menuConfig: ListingMenuConfig) => void
) {
  return async () => {
    dispatch(slice.actions.setMenuConfigLoading(true));
    try {
      await axios
        .all([
          listingsAPI.fetchConfig(key === 'unitType' ? 'unit_type' : key, id),
          api.fetchListingBrands(),
        ])
        .then(
          axios.spread((config, brands) => {
            dispatch(
              slice.actions.setManageBrandsState({
                defaultBrand: config.data.default_brand,
                listingBrands: config.data.brands,
                organizationBrands: brands.data,
              })
            );
            dispatch(slice.actions.setMenuConfig(config.data));
            fallback && fallback(config.data);
          })
        )
        .catch((error) => { });
    } catch (error) { }
    dispatch(slice.actions.setMenuConfigLoading(false));
  };
}

export function getPropertyData(id: number, tab: PropertyTab) {
  return async () => {
    try {
      const { data } = await listingsAPI.fetchProperty(tab, id);
      dispatch(slice.actions.setPropertyTabData({ id, tab, data }));
    } catch (error) { }
  };
}
export function getUnitTypeData(id: number, tab: UnitTypeTab) {
  return async () => {
    try {
      const { data } = await listingsAPI.fetchUnitType(tab, id);
      dispatch(slice.actions.setUnitTypeTabData({ id, tab, data }));
    } catch (error) { }
  };
}
export function getUnitData(id: number, tab: UnitTab) {
  return async () => {
    try {
      const { data } = await listingsAPI.fetchUnit(tab, id);
      dispatch(slice.actions.setUnitTabData({ id, tab, data }));
    } catch (error) { }
  };
}

export function getSystemAmenities() {
  return async () => {
    try {
      const { data } = await listingsAPI.fetchAmenities();
      dispatch(slice.actions.setSystemAmenities(data));
    } catch (error) { }
  };
}

export function initializeListingTab(
  menuConfig: ListingMenuConfig,
  tab: ListingPageTab,
  propertyMode: PropertyMode,
  controlledViewLevel: ViewLevel,
  id: number
) {
  const handleLoading = (loading: boolean, viewLevels: ViewLevel[], ids: number[]) => {
    viewLevels.forEach((viewLevel, index) => {
      dispatch(slice.actions.setTabsLoading({ tab, loading, viewLevel, id: ids[index] }));
    });
  };

  return async () => {
    try {
      const unitId = menuConfig.unit_types[0]?.units[0]?.id;
      const unitTypeId = menuConfig.unit_types[0]?.id;
      const shouldFetchUnit = generateListingTabs('unit').includes(tab);
      const shouldFetchUnitType = generateListingTabs('unitType').includes(tab);
      const shouldFetchProperty = generateListingTabs('property').includes(tab);
      batch(async () => {
        if (propertyMode === 'complex') {
          handleLoading(true, [controlledViewLevel], [id]);
          if (controlledViewLevel === 'property' && shouldFetchProperty)
            await dispatch(getPropertyData(id, tab as PropertyTab));
          else if (controlledViewLevel === 'unitType' && shouldFetchUnitType)
            await dispatch(getUnitTypeData(id, tab as UnitTypeTab));
          else if (controlledViewLevel === 'unit' && shouldFetchUnit)
            await dispatch(getUnitData(id, tab as UnitTab));
          handleLoading(false, [controlledViewLevel], [id]);
        } else if (propertyMode === 'multi_unit') {
          if (controlledViewLevel === 'unit' && shouldFetchUnit) {
            handleLoading(true, ['unit'], [id]);
            await dispatch(getUnitData(id, tab as UnitTab));
            handleLoading(false, ['unit'], [id]);
          } else {
            handleLoading(true, ['unitType', 'property'], [unitTypeId, menuConfig.id]);
            await axios.all([
              ...(shouldFetchUnitType
                ? [dispatch(getUnitTypeData(unitTypeId, tab as UnitTypeTab))]
                : []),
              ...(shouldFetchProperty
                ? [dispatch(getPropertyData(menuConfig.id, tab as PropertyTab))]
                : []),
            ]);
            handleLoading(false, ['unitType', 'property'], [unitTypeId, menuConfig.id]);
          }
        }
        if (propertyMode === 'single_unit') {
          handleLoading(
            true,
            ['unit', 'unitType', 'property'],
            [unitId, unitTypeId, menuConfig.id]
          );
          await axios.all([
            ...(shouldFetchUnit ? [dispatch(getUnitData(unitId, tab as UnitTab))] : []),
            ...(shouldFetchUnitType
              ? [dispatch(getUnitTypeData(unitTypeId, tab as UnitTypeTab))]
              : []),
            ...(shouldFetchProperty
              ? [dispatch(getPropertyData(menuConfig.id, tab as PropertyTab))]
              : []),
          ]);
          handleLoading(
            false,
            ['unit', 'unitType', 'property'],
            [unitId, unitTypeId, menuConfig.id]
          );
        }
      });
    } catch (error) { }
  };
}

export function changeViewLevel(viewLevel: ViewLevel, propertyMode: PropertyMode) {
  return async () => {
    const controlledViewLevel = viewLevelController(propertyMode, viewLevel);
    dispatch(slice.actions.setViewLevel(controlledViewLevel));
  };
}

export function listingViewController(viewLevel: ViewLevel, menuConfig: ListingMenuConfig) {
  return async () => {
    const propertyMode = getPropertyMode(menuConfig);
    batch(() => {
      dispatch(slice.actions.setPropertyMode(propertyMode));
      dispatch(changeViewLevel(viewLevel, propertyMode));
    });
  };
}

export function updateProperty(
  tab: PropertyTab | undefined,
  propertyId: number | undefined,
  requestBody: Partial<UpdatePropertyBodyType>,
  fieldToUpdate?: Partial<PropertyData>,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    if (propertyId) {
      try {
        const response = await listingsAPI.updateProperty(propertyId, requestBody);
        fieldToUpdate &&
          tab &&
          dispatch(slice.actions.updatePropertyTabData({ tab, newField: fieldToUpdate }));
        callback?.(response, response.status);
      } catch (error) {
        callback?.(null, error?.status || 500);
      }
    }
    setLoading?.(false);
  };
}

const unitTypeErrorCheck = (error) => {
  try {
    const licenseError = error?.data?.violations?.find(
      ({ propertyPath }) => propertyPath === 'unit_type_category'
    );
    if (licenseError) {
      dispatch(
        setInsufficientLicencesDialogState({
          open: Boolean(licenseError.message),
          neededLicences: JSON.parse(licenseError.message) ?? {},
        })
      );
    }
  } catch (error) { }
};

export function updateUnitType(
  tab: UnitTypeTab,
  unitTypeId: number | undefined,
  requestBody: any,
  fieldToUpdate: Partial<UnitTypeData>,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    if (unitTypeId) {
      try {
        const response = await listingsAPI.updateUnitType(unitTypeId, requestBody);
        dispatch(
          slice.actions.updateUnitTypeTabData({ tab, id: unitTypeId, newField: fieldToUpdate })
        );
        callback?.(response, true);
      } catch (error) {
        callback?.(error, false);
        unitTypeErrorCheck(error);
      }
    }
    setLoading?.(false);
  };
}

export function updateUnit(
  tab: UnitTab,
  unitId: number | undefined,
  requestBody: any,
  fieldToUpdate: Partial<UnitData>,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null) => void
) {
  return async () => {
    setLoading?.(true);
    if (unitId) {
      try {
        const response = await listingsAPI.updateUnit(unitId, requestBody, tab);
        dispatch(slice.actions.updateUnitTabData({ tab, id: unitId, newField: fieldToUpdate }));
        callback?.(response);
      } catch (error) {
        callback?.(null);
      }
    }
    setLoading?.(false);
  };
}

export function updateUnits(
  tab: UnitTab,
  applyLevel: ViewLevel,
  unitId: number,
  unitTypeId: number,
  propertyId: number,
  requestBody: any,
  fieldToUpdate: Partial<UnitData>,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<PropertyBatchUpdateResponseType | UnittypeBatchUpdateResponseType, Record<any, any>> | Record<any, any> | null) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = Object.keys(requestBody).length ? (
        applyLevel === 'property'
          ? await listingsAPI.updatePropertyUnits(propertyId, requestBody)
          : applyLevel === 'unitType'
            ? await listingsAPI.updateUnitTypeUnits(unitTypeId, requestBody)
            : await listingsAPI.updateUnit(unitId, requestBody, tab)
      ) : {};
      dispatch(
        slice.actions.updateUnitsTabData({
          tab,
          unitId,
          applyLevel,
          newField: fieldToUpdate,
          unitTypeId,
        })
      );
      callback?.(response);
    } catch (e) {
      callback?.(null);
    } finally {
      setLoading?.(false);
    }
  };
}

export function createRatePlan(
  unitTypeId: number,
  requestBody: any,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await rateAPI.createRatePlan(requestBody);
      dispatch(slice.actions.createRatePlan({ unitTypeId, newRatePlan: response.data }));
      callback?.(response, true);
    } catch (error) {
      callback?.(error, false);
    }
    setLoading?.(false);
  };
}

export function updateRatePlan(
  ratePlanId: number[],
  unitTypeId: number,
  requestBody: any[],
  fieldToUpdate: Partial<RatePlan>[],
  setLoading?: (succeed: boolean) => void,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      await axios
        .all(ratePlanId.map((id, index) => rateAPI.updateRatePlan(id, requestBody[index])))
        .then(
          axios.spread((...responses) => {
            responses.forEach((response, index) => {
              dispatch(
                slice.actions.updateRatePlanStates({
                  id: ratePlanId[index],
                  unitTypeId,
                  newField: fieldToUpdate[index],
                })
              );
              callback?.(response, true);
            });
          })
        );
    } catch (error) {
      callback?.(error, false);
    }
    setLoading?.(false);
  };
}

export function updateBuilding(
  buildingId: number,
  requestBody: any,
  fieldToUpdate: Partial<Building>,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await listingsAPI.updateBuilding(buildingId, requestBody);
      dispatch(slice.actions.updateBuilding({ id: buildingId, newField: fieldToUpdate }));
      callback?.(response, true);
    } catch (error) {
      callback?.(error, false);
    }
    setLoading?.(false);
  };
}

export function removeRatePlan(
  ratePlanId: number,
  unitTypeId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await rateAPI.removeRatePlan(ratePlanId);
      dispatch(slice.actions.removeRatePlan({ id: ratePlanId, unitTypeId }));
      callback?.(response, true);
    } catch (error) {
      callback?.(error, false);
    }
    setLoading?.(false);
  };
}

export function removeListing(
  id: number,
  type: ViewLevel,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    try {
      const apis: { [key in ViewLevel]: (id: number) => Promise<AxiosResponse<any, any>> } = {
        property: listingsAPI.removeProperty,
        unitType: listingsAPI.removeUnitType,
        unit: listingsAPI.removeUnit,
      };
      const response = await apis[type](id);
      dispatch(slice.actions.removeFromMenuConfig({ removeId: id, type }));
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
    }
  };
}

export function unallocateBookings(bookingsId: number[]) {
  return async () => {
    try {
      await bookingAPI.unallocateBookings(bookingsId);
    } catch (error) { }
  };
}

export function syncRatePlanManually(
  id: number,
  unitTypeId: number,
  remove: boolean,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await rateAPI.syncToChannelManager({
        entity: 'RatePlan',
        id,
        ...(remove && { status: 'delete' }),
      });
      dispatch(
        slice.actions.updateRatePlanStates({
          id,
          unitTypeId,
          newField: { synced_with_channel_manager: true },
        })
      );
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
      if (error.status === 406) {
        dispatch(
          slice.actions.updateRatePlanStates({
            id,
            unitTypeId,
            newField: { synced_with_channel_manager: false },
          })
        );
      }
    }
    setLoading?.(false);
  };
}

export function updateHousekeepingEstimation(
  values: { [key: number]: { [key: number]: number } },
  setLoading: (state: boolean) => void,
  callback: (succeed: boolean) => void
) {
  return async () => {
    setLoading(true);
    try {
      const propertyIds = Object.keys(values);
      const apis = mergeArrays(
        propertyIds.map((propertyId) => {
          const unitTypeIds = Object.keys(values[propertyId]);
          return unitTypeIds.map((unitTypeId) => {
            const newValue = values[propertyId][unitTypeId];
            return newValue
              ? async () => {
                await listingsAPI.updateUnitType(+unitTypeId, {
                  average_house_keeping_time: newValue,
                });
                dispatch(
                  slice.actions.updateUnitTypeHousekeepingTime({
                    unitTypeId: +unitTypeId,
                    newValue,
                  })
                );
              }
              : undefined;
          });
        })
      );
      await axios.all(apis.map((api) => api?.())).then(() => {
        callback(true);
      });
    } catch (error) {
      callback(false);
    }
    setLoading(false);
  };
}

export function addTaskResponsibility(
  propertyId: number,
  {
    staffs,
    teams,
    companies,
  }: {
    staffs: TaskResponsibleApiBody;
    teams: TaskResponsibleApiBody;
    companies: TaskResponsibleApiBody;
  },
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      await axios
        .all([
          ...staffs.map(({ id, category }) =>
            listingsAPI.addTaskResponsibility(propertyId, { staff: id }, category)
          ),
          ...teams.map(({ id, category }) =>
            listingsAPI.addTaskResponsibility(propertyId, { team: id }, category)
          ),
          ...companies.map(({ id, category }) =>
            listingsAPI.addTaskResponsibility(propertyId, { company: id }, category)
          ),
        ])
        .then(
          axios.spread((...responses) => {
            const newResponsibles = responses.map(({ data }) => data);
            dispatch(slice.actions.addPropertyTaskResponsibles(newResponsibles));
          })
        );
      callback?.(true);
    } catch (error) {
      callback?.(false);
    }
    setLoading?.(false);
  };
}

export function removeTaskResponsibility(
  responsibleId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      await listingsAPI.removeTaskResponsibility(responsibleId);
      dispatch(slice.actions.removePropertyTaskResponsibles(responsibleId));
      callback?.(true);
    } catch (error) {
      callback?.(false);
    }
    setLoading?.(false);
  };
}

export function allocateUnits(
  unitsId: number[],
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await listingsAPI.allocateUnits(unitsId);
      response.data
        .filter(({ status }) => status === 'ACTIVATED')
        .forEach(({ id }) => {
          dispatch(slice.actions.updateMenuConfig({ id, key: 'unit', value: { is_active: true } }));
        });
      callback?.(true, response);
    } catch (error) {
      callback?.(false, null);
    }
    setLoading?.(false);
  };
}

export function moveUnitToUnitType(
  unitId: number,
  currentUnitTypeId: number,
  sourceUnitTypeId: number,
  force?: boolean,
  setLoading?: (state: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await listingsAPI.moveUnitToUnitType(unitId, sourceUnitTypeId, force);
      if (response.status === 200) {
        dispatch(
          slice.actions.moveUnitToAnotherUnitType({ currentUnitTypeId, sourceUnitTypeId, unitId })
        );
      }
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
    }
    setLoading?.(false);
  };
}

export function releaseLicense(
  unitsId: number[],
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    try {
      const response = await listingsAPI.deactivateListing(unitsId);
      response.data.forEach(({ id }) => {
        dispatch(slice.actions.updateMenuConfig({ id, key: 'unit', value: { is_active: false } }));
      });
      callback?.(true, response);
    } catch (error) {
      callback?.(false, null);
    }
  };
}

export function listingActivate(
  propertyId: number,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    try {
      const response = await listingsAPI.activateListing(propertyId);
      response.data.activatedUnits.map((unitId) =>
        dispatch(
          slice.actions.updateMenuConfig({ id: unitId, key: 'unit', value: { is_active: true } })
        )
      );
      callback?.(true, response);
    } catch (error) {
      callback?.(false, null);
    }
  };
}

export function getFaqCategories(setLoading?: (succeed: boolean) => void) {
  return async () => {
    try {
      setLoading?.(true);
      const { data } = await listingsAPI.fetchFaqCategories();
      dispatch(slice.actions.setFaqCategories(data));
    } catch (e) {
    } finally {
      setLoading?.(false);
    }
  };
}

export function getCheckinTypes() {
  return async () => {
    try {
      const { data } = await listingsAPI.fetchCheckinTypes();
      dispatch(slice.actions.setCheckinTypes(data));
    } catch (e) { }
  };
}

export function setPublishedBrandsChange(
  id: number,
  publishedBrands: number[],
  defaultBrand?: number,
  setLoading?: (state: boolean) => void,
  callback?: (response: any, succeed: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const res = await listingsAPI.updateProperty(id, {
        brands: publishedBrands,
        ...(defaultBrand && { default_brand: defaultBrand }),
      });
      dispatch(
        slice.actions.setManageBrandsState({
          listingBrands: res.data.brands,
          ...(defaultBrand && { defaultBrand: res.data.default_brand }),
        })
      );
      callback?.(res, true);
    } catch (e) {
      callback?.(e, false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getCheckinTypeVariables() {
  return async () => {
    try {
      const { data } = await automatedMessageAPI.fetchVariables('check_in_type', null);
      const variables = provideVariables(data);
      dispatch(slice.actions.setCheckinTypeVariables(variables.bodyVariables));
    } catch (e) { }
  };
}

export function addUpsellToUnitType(
  upsell: Upsell,
  unitTypeId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await rateAPI.addUpsell(
        [upsell.id],
        [unitTypeId],
        upsell.sales_price?.value || null,
        upsell.purchase_price?.value || null
      );
      dispatch(
        slice.actions.addUpsell({
          unitTypeId,
          unitTypeUpsell: response.data.added_unit_type_upsells,
        })
      );
      callback?.(true, response);
    } catch (error) {
      callback?.(false, null);
    }
    setLoading?.(false);
  };
}

export function getReviews(args: {
  page: number;
  limit: number;
  propertyId?: number | undefined;
  pending?: boolean;
  setIsLoading?: (state: boolean) => void;
}) {
  const { page, limit, propertyId, setIsLoading, pending } = args;
  return async () => {
    try {
      setIsLoading?.(true);
      const { data } = await api.fetchReviews({
        page,
        limit,
        propertyId,
        approved: pending === true ? [null] : [],
      });
      dispatch(slice.actions.setReviews({ ...data, page, isFiltered: !!pending }));
    } catch (e) {
    } finally {
      setIsLoading?.(false);
    }
  };
}

// selectors
export const isMultiBrandSelector = (state: RootState): boolean => {
  const { organizationBrands } = state.listing.manageBrands;
  return organizationBrands.length > 1;
};
