import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultHeader, defaultFooterTemplate } from 'src/_mock/email-template-header-and-footer';
import { AutomatedMessageState } from '../../../@types/automated-message';
import {
  createPageReducers,
  getConditions,
  getVariables,
  getLanguages,
  getAttachments,
  uploadAttachment,
  provideTemplates,
  provideConditions,
  provideWhen,
  saveAutomatedMessage,
} from './create-page';
import automatedMessageAPI from 'src/api/automated-message';
import { landingPage } from './landing-page';
import { convertConditions, convertWhen } from 'src/utils/automatedMessage';
import { dispatch } from 'src/redux/store';
import { Dispatch, SetStateAction } from 'react';
import { replaceContentEntitiesWithVariables } from 'src/utils/variables';

// ----------------------------------------------------------------------

export const initialState: AutomatedMessageState = {
  brands: [],
  saveStatus: null,
  name: '',
  editingId: null,
  isActive: true,
  isFavorite: false,
  isArchived: false,
  enableDelete: true,
  enableArchive: false,
  when: {
    isEditing: false,
    hasError: false,
    timeFrame: ['', ''],
    whenRadioValue: 'based_on_booking_timeline',
    timelineValue: 'createdAt',
    timeComparisonValue: 'on',
    timeVariationAmountValue: 1,
    timeVariationValue: 'day',
  },
  condition: {
    isEditing: false,
    hasError: false,
    shouldCheckForError: false,
    shouldScrollToError: false,
    popupVariables: [],
    selectedOptions: [],
    selectedConditions: [],
  },
  message: {
    isEditing: false,
    messageVariables: {
      headerVariables: [],
      bodyVariables: [],
      footerVariables: [],
    },
    languages: [],
    attachments: [],
    shouldCheckForToFieldError: false,
    ShouldCheckContentMessageFieldError: false,
    selectedMessageIndex: 0,
    selectedLanguageTab: 'en',
    failedRenderMessages: [],
    hasFailedRenderMessages: false,
    messages: [
      {
        id: null,
        type: 'email',
        isHtml: false,
        toSelectedValues: [],
        ccSelectedValues: [],
        bccSelectedValues: [],
        overrideSettings: [],
        contents: [
          {
            subject: '',
            header: defaultHeader,
            messageText: '',
            footer: defaultFooterTemplate,
            language: 'en',
          },
        ],
        attachments: [],
        timestamp: Date.now(),
        booking: null,
      },
    ],
  },
  landingPage: {
    automatedMessages: [],
    category: 'all',
    loading: false,
    hasMore: true,
    pageNumber: 1,
    filters: {
      status: '',
      sendVia: [],
      messageType: '',
      toFilteredValues: [],
      languageFilteredValues: [],
      hasAttachments: false,
      isFavorite: false,
    },
    setting: {
      languages: [],
      replyTos: [],
      emailSignitures: [],
      autoResponse: null,
    },
    autoResponseVariables: [],
    automatedMessageConfigs: {},
  },
  isSavedMessage: false,
  createdAt: '',
  updatedAt: '',
  automatedMessageNameHasError: false,
};

export const slice = createSlice({
  name: 'automatedMessage',
  initialState,
  reducers: {
    setBrands(state, action) {
      state.brands = action.payload;
    },
    setAutomatedMessage: (state, action) => {
      const automatedMessage = action.payload;
      const when = automatedMessage.conditions.filter((condition) => condition.group_name === "when")?.[0];
      const conditions = automatedMessage.conditions.filter((condition) => condition.group_name !== "when");
      const { headerVariables, bodyVariables, footerVariables } = state.message.messageVariables;

      state.name = automatedMessage.name;
      state.isActive = automatedMessage.is_active;
      state.isFavorite = automatedMessage.is_favorite;
      state.isArchived = automatedMessage.is_archived;
      state.enableDelete = automatedMessage.enable_delete;
      state.enableArchive = automatedMessage.enable_archive;
      state.when.timeFrame = [automatedMessage.time_frame_from || '', automatedMessage.time_frame_to || ''];
      state.message.messages = automatedMessage.templates.map((template) => ({
        ...template,
        overrideSettings: template.overrideSettings || [],
        contents: template.contents.map((content) => ({
          ...content,
          subject: replaceContentEntitiesWithVariables(content.subject, bodyVariables) || '',
          messageText: replaceContentEntitiesWithVariables(content.messageText, bodyVariables) || '',
          header: replaceContentEntitiesWithVariables(content.header, headerVariables) || defaultHeader,
          footer: content.footer || defaultFooterTemplate,
        }))
      }));
      state.when = { ...state.when, ...convertWhen(when) };
      state.condition.selectedConditions = conditions.map((condition) => convertConditions(condition));
      state.condition.selectedOptions = conditions.map((condition) => state.condition.popupVariables.find((variable) => variable.name === condition.label));
      state.createdAt = automatedMessage.created_at;
      state.updatedAt = automatedMessage.updated_at;
    },
    revertIndexPageStateToInitialState: (state) => (
      { ...state, landingPage: initialState.landingPage }
    ),
    revertCreatePageStateToInitialState: (state: AutomatedMessageState) => ({
      ...initialState,
      message: {
        ...initialState.message,
        messageVariables: state.message.messageVariables,
        languages: state.message.languages,
        attachments: state.message.attachments,
      },
      landingPage: state.landingPage,
      brands: state.brands,
    }),
    setIsSavedMessage: (state, action: PayloadAction<boolean>) => {
      state.isSavedMessage = action.payload;
    },
    setAutomatedMessageNameHasError: (state, action: PayloadAction<boolean>) => {
      state.automatedMessageNameHasError = action.payload;
    },
    ...createPageReducers,
    ...landingPage,
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setAutomatedMessage,
  setSaveStatus,
  setAutomatedMessageName,
  setAutomatedMessageActive,
  setAutomatedMessageArchive,
  setAutomatedMessageFavorite,
  removeAutomatedMessageFromList,
  setTimeFrame,
  setWhenHasError,
  setWhenIsEditing,
  changeWhen,
  setWhenValue,
  setConditionIsEditing,
  setConditionHasError,
  setConditionShouldCheckForError,
  setShouldScrollToConditionError,
  changeCondition,
  setPopupVariables,
  setSelectedOptions,
  removeSelectedOptions,
  setSelectedCondition,
  removeSelectedCondition,
  setFirstOperator,
  setSecondOperator,
  setVariables,
  setConditionValue,
  setMessageVariables,
  setShouldCheckForToFieldError,
  setShouldCheckContentMessageFieldError,
  setSelectedMessageIndex,
  setSelectedLanguageTab,
  setMessageType,
  setIsHtml,
  setOverrideSetting,
  setOverrideSettings,
  setToSelectedValues,
  setCcSelectedValues,
  setBccSelectedValues,
  setContents,
  addNewContent,
  deleteContent,
  removeMessageAttachment,
  removeMessageAttachmentById,
  addNewMessageAttachment,
  addNewMessage,
  removeMessage,
  setMessageIsEditing,
  changeMessage,
  setStatus,
  setSendVia,
  removeSendVia,
  setMessageFilterType,
  setToFilteredValue,
  setLanguageFilteredValues,
  removeLanguage,
  setHasAttachment,
  setIsFavorite,
  setLoading,
  setHasMore,
  setPage,
  removeFilters,
  revertCreatePageStateToInitialState,
  setAutomatedMessages,
  setIsActiveAutomatedMessage,
  setIsFavoriteAutomatedMessage,
  setCategory,
  setBrands,
  setReplyToValue,
  setAutoResponseVariables,
  removeLanguageConfig,
  addLanguageConfig,
  setSetting,
  setHasFailedRenderMessages,
  setFailedRenderMessages,
  setAutomatedMessageConfigs,
  setBookingToMessage,
  setIsSavedMessage,
  setAutomatedMessageNameHasError,
} = slice.actions;

export {
  getConditions,
  getVariables,
  getLanguages,
  getAttachments,
  uploadAttachment,
  provideTemplates,
  provideConditions,
  provideWhen,
  saveAutomatedMessage,
  
};

// ----------------------------------------------------------------------

export function fetchBrands() {
  return async () => {
    try {
      const response = await automatedMessageAPI.fetchOrganizationBrands();
      dispatch(slice.actions.setBrands(response.data));
    } catch (error) {}
  }
};

export function setFavoriteAutomatedMessage(id: number, isFavorite: boolean, indexPage: boolean, setIsLoading: Dispatch<SetStateAction<boolean>>) {
  return async () => {
    setIsLoading(true);
    try {
      await automatedMessageAPI.setFavorite(id, isFavorite);
      if (indexPage) {
        dispatch(slice.actions.setIsFavoriteAutomatedMessage({ id, isFavorite }));
      } else {
        dispatch(slice.actions.setAutomatedMessageFavorite(isFavorite));
      }
    } catch (error) {}
    setIsLoading(false);
  };
};

export function cloneAutomatedMessage(id: number, setIsLoading: Dispatch<SetStateAction<boolean>>, statusCallBack?: (success: boolean) => void) {
  return async () => {
    setIsLoading(true);
    try {
      const response = await automatedMessageAPI.cloneAutomatedMessage(id);
      dispatch(slice.actions.setClonedAutomatedMessage(response.data));
      statusCallBack && statusCallBack(true);
    } catch (error) {
      statusCallBack && statusCallBack(false);
    }
    setIsLoading(false);
  }
};

export function changeAutomatedMessageActiveState(id: number, isActive: boolean, bookingIds: number[], isFromNow: boolean) {
  return async () => {
    try {
      await automatedMessageAPI.changeAutomatedMessageActiveState(id, isActive, bookingIds, isFromNow);
    } catch (error) {}
  }
};


export function deleteOrArchiveAutomatedMessage(id: number, enabledDelete: boolean, isArchived: boolean, setIsLoading: Dispatch<SetStateAction<boolean>>, statusCallback: (success: boolean) => void) {
  return async () => {
    setIsLoading(true);
    try {
      if (enabledDelete) await automatedMessageAPI.deleteAutomatedMessage(id);
      else await automatedMessageAPI.archiveAutomatedMessage(id, isArchived);
      statusCallback(true);
    } catch (error) {
      statusCallback(false);
    } finally {
      setIsLoading(false);
    }
  }
};

// ----------------------------------------------------------------------
