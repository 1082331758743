import {
  PromotionApplication,
  PromotionApplicationBody,
  PromotionBody,
  PromotionParams,
  Voucher,
} from 'src/@types/promotion';
import axios from '../utils/axios';
import { Meta } from 'src/@types/common';

function promotionAPI() {
  return {
    fetchPromotions: (params: PromotionParams, signal?: AbortSignal) => {
      const url = `/apis/promotion_applications`;
      return axios.get<{ data: PromotionApplication[]; meta: Meta }>(url, { params, signal });
    },
    fetchPromotion: (id: number | string) => {
      const url = `/apis/promotion_applications/${id}`;
      return axios.get(url);
    },
    createPromotionApplications: (body: PromotionApplicationBody) => {
      const url = `/apis/promotion_applications`;
      return axios.post(url, body);
    },
    updatePromotionApplications: (id: number | string, body: Partial<PromotionApplicationBody>) => {
      const url = `/apis/promotion_applications/${id}`;
      return axios.put(url, body);
    },
    deletePromotionApplications: (id: number | string) =>
      axios.delete(`/apis/promotion_applications/${id}`),
    validatePromotionDelete: (id: number | string) =>
      axios.get(`/apis/promotion/check_delete_action_validation/${id}`),
    updatePromotion: (id: number | string, body: Partial<PromotionBody>) => {
      const url = `/apis/promotions/${id}`;
      return axios.put(url, body);
    },
    createVoucher: (code: string, limit: number | null, application: number | string) => {
      const url = `/apis/vouchers`;
      return axios.post(url, { code, limit, application });
    },
    updateVoucher: (body: Voucher) => {
      const { id, ...rest } = body;
      const url = `/apis/vouchers/${id}`;
      return axios.put(url, rest);
    },
    removeVoucher: (id: number | string) => axios.delete(`/apis/vouchers/${id}`),
    fetchApplicablePromotionsForRatePlan: (id: number) => axios.get(`/apis/promotion/applied_promotion_applications/${id}`),
  };
}

export default promotionAPI();
