import { Ticket } from 'src/@types/ticket';
import axios from '../utils/axios';
import { FilterValue } from '../@types/filter';

function ticketsAPI() {
  return {
    fetchTickets: (
      page: number,
      limit: number,
      filtersState: {
        status?: FilterValue;
        urgencyLevel?: FilterValue;
      },
      signal?: AbortSignal,
      advanceFilterParam?: { key: string; value: string }
    ) => {
      const url = `/apis/tickets`;
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          ...(Array.isArray(filtersState?.status) && {
            status: filtersState.status.map((status) => status.value),
          }),
          ...(Array.isArray(filtersState?.urgencyLevel) && {
            urgencyLevel: filtersState.urgencyLevel.map((status) => status.value),
          }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
        },
      });
    },
    fetchTicketTypes: () => axios.get('/apis/ticket_types'),
    createTicket: (ticket: Ticket) => {
      const url = `/apis/tickets`;
      return axios.post(url, ticket);
    },
    updateTicket: (id: number, body: any) => {
      const url = `/apis/tickets/${id}`;
      return axios.put(url, body);
    },
    updateTicketAsRead: (id: number, body: any) => {
      const url = `/apis/set_hand_read/${id}`;
      return axios.post(url, body);
    },
    uploadAttachments: (ticketLineId: number, files: FormData) => {
      const url = `apis/ticket_line/upload_attached_files/${ticketLineId}`;
      return axios.post(url, files, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    getHandOverview: () => axios.get('/apis/get_hand_overview'),
    getHandUrgencyLevel: () => axios.get('/apis/get_hand_urgency_level'),
    getHandStatus: () => axios.get('/apis/get_hand_status'),
    getHandDetail: (handId: number) => axios.get(`/apis/ticket/get_hand/${handId}`),
    closeHand: (handId: number) => axios.put(`/apis/ticket/close_hand/${handId}`),
    addUserReply: (handId: number, message: string, file_ids: string) =>
      axios.post(`/apis/ticketLine/add_user_reply/${handId}`, {
        message,
        file_ids,
      }),
    switchOrganization: (organizationId: number) => (
      axios.get(`/apis/switch_organization/hand/${organizationId}`)
    )
  };
}

export default ticketsAPI();
