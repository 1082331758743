// @mui
import {
  Badge,
  Box,
  Link,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Hand } from 'src/@types/hand';
import Iconify from 'src/components/Iconify';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { fToNow } from 'src/utils/formatTime';
import React from 'react';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import Label, { LabelColor } from 'src/components/Label';
import ticketAPI from 'src/api/ticket';

type Props = {
  hand: Hand;
};

export const getUrgencyFlag = (urgencyLevel: string) => {
  const urgencyColors: { [key: string]: string } = {
    Low: 'text.disabled',
    Medium: 'warning.main',
    High: 'error.main',
  };

  const color = urgencyColors[urgencyLevel];

  if (!color) return null;

  return <Iconify icon={'ic:round-flag'} width={20} height={20} color={color} />;
};

export const getBadgeIcon = (status: string, size: number) => {
  switch (status) {
    case 'Clarification Required':
      return (
        <Iconify
          icon={'mdi:question-mark-circle'}
          width={size}
          height={size}
          color={'error.main'}
        />
      );
    case 'Submitted':
      return <Iconify icon={'mdi:clock'} width={size} height={size} color={'info.main'} />;
    case 'Awaiting Response':
      return (
        <Iconify icon={'mdi:clock-time-four'} width={size} height={size} color={'info.main'} />
      );
    case 'Under Review':
      return (
        <Iconify icon={'f7:search-circle-fill'} width={size} height={size} color={'warning.main'} />
      );
    case 'In Progress':
      return (
        <Iconify
          icon={'pepicons-pencil:gear-circle-filled'}
          width={size}
          height={size}
          color={'primary.main'}
        />
      );
    case 'Resolved':
      return (
        <Iconify icon={'mdi:check-circle'} width={size} height={size} color={'success.main'} />
      );
    default:
      return null;
  }
};

export const getHandStatus = (status: string) => {
  const statusColors: { [key: string]: string } = {
    'Clarification Required': 'error',
    'Submitted': 'info',
    'Under Review': 'warning',
    'In Progress': 'primary',
    'Resolved': 'success',
    'Closed': 'default',
  };

  const color = statusColors[status] || 'default';

  return (
    <Label variant="ghost" color={color as LabelColor} sx={{ width: 140, borderRadius: 1, p: 0 }}>
      {status}
    </Label>
  );
};

export const getHandIcon = (status: string, size: number) => {
  switch (status) {
    case 'Closed':
      return (
        <SvgIconStyle
          src={'/icons/ic_hand-off.svg'}
          sx={{ width: size, height: size, color: 'grey.400' }}
        />
      );

    default:
      return (
        <SvgIconStyle
          src={'/icons/ic_hand.svg'}
          sx={{ width: size, height: size, color: 'grey.400' }}
        />
      );
  }
};

export function HandItem({ hand }: Props) {
  const updateReadState = async () => {
    await ticketAPI.updateTicketAsRead(hand?.id, { read: true }).then(() => {});
  };

  return (
    <ListItemButton
      component={Link}
      href={`${PATH_DASHBOARD.hands.view}/${hand.id}`}
      onClick={updateReadState}
      target="_blank"
      rel="noopener"
      sx={{
        py: 1,
        pl: 1,
        pr: 2,
        mt: 0.5,
        alignItems: 'flex-start',
      }}
    >
      <Stack direction={'row'} spacing={2} flexGrow={1} width={'100%'}>
        {hand.status && (
          <Box>
            <Badge
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              sx={{
                '& .MuiBadge-badge': {
                  bottom: 4,
                  right: 4,
                },
              }}
              badgeContent={getBadgeIcon(hand.status.name, 12)}
            >
              {getHandIcon(hand.status.name, 24)}
            </Badge>
          </Box>
        )}
        <Stack flexGrow={1} width={0}>
          <ListItemText
            primary={
              <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Typography variant="body2" color={'text.secondary'}>
                  {hand.id}
                </Typography>
                <Tooltip arrow placement="top" title={hand.subject}>
                  <Typography noWrap variant="body1" fontWeight={'fontWeightSemiBold'}>
                    {hand.subject}
                  </Typography>
                </Tooltip>
              </Stack>
            }
            secondary={
              <>
                <Typography
                  variant="body2"
                  color={'text.secondary'}
                  sx={{
                    mt: 0.5,
                    lineHeight: '24px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {hand.ticket_lines?.map((line) => line.description)}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    color: 'text.disabled',
                  }}
                >
                  <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                  {fToNow(hand.date)}
                </Typography>
              </>
            }
          />
        </Stack>
      </Stack>
    </ListItemButton>
  );
}
