import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isValidPhoneNumber } from 'react-phone-number-input';
import automatedMessageAPI from 'src/api/automated-message';
import { provideConditionsSummaryText, provideVariables } from 'src/utils/automatedMessage';
import { defaultHeader, defaultFooterTemplate } from 'src/_mock/email-template-header-and-footer';
import {
  AutomatedMessageState,
  MessageAttachment,
  MessageDetails,
  MessageType,
  FailedRenderMessage,
  WhenState,
  SaveStatus,
  SelectedCondition,
  ConditionState,
  PopupVariable,
  MessageVariables,
  Language,
  OverrideSetting,
  MessageState,
  BookingAutomatedMessage,
} from '../../../@types/automated-message';
import { dispatch } from '../../store';
import { slice } from './index';
import * as yup from 'yup';
import { convertHtmlToText } from 'src/utils/convertHtmlToText';
import { removeNewLineFromEntity, replaceContentVariablesWithEntity } from 'src/utils/variables';
import { VariableApiType, File } from 'src/@types/common';
import { PayloadAction } from '@reduxjs/toolkit';

export const createPageReducers = {
  setSaveStatus(state: AutomatedMessageState, action: PayloadAction<SaveStatus>) {
    state.saveStatus = action.payload;
  },
  setAutomatedMessageName(state: AutomatedMessageState, action: PayloadAction<string>) {
    state.name = action.payload;
  },
  setAutomatedMessageEditingId(state: AutomatedMessageState, action: PayloadAction<number | null>) {
    state.editingId = action.payload;
  },
  setAutomatedMessageActive(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.isActive = action.payload;
  },
  setAutomatedMessageArchive(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.isArchived = action.payload;
  },
  setAutomatedMessageFavorite(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.isFavorite = action.payload;
  },
  setTimeFrame(state: AutomatedMessageState, action: PayloadAction<[string, string]>) {
    state.when.timeFrame = action.payload;
  },
  setWhenHasError(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.when.hasError = action.payload;
  },
  setWhenIsEditing(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.when.isEditing = action.payload;
  },
  setWhenValue(
    state: AutomatedMessageState,
    action: PayloadAction<{ stateName: string; value: any }>
  ) {
    state.when[action.payload.stateName] = action.payload.value;
  },
  changeWhen(state: AutomatedMessageState, action: PayloadAction<WhenState>) {
    state.when = { ...state.when, ...action.payload };
  },
  changeCondition(state: AutomatedMessageState, action: PayloadAction<ConditionState>) {
    state.condition = { ...state.condition, ...action.payload };
  },
  setConditionIsEditing(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.condition.isEditing = action.payload;
  },
  setConditionHasError(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.condition.hasError = action.payload;
  },
  setConditionShouldCheckForError(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.condition.shouldCheckForError = action.payload;
  },
  setShouldScrollToConditionError(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.condition.shouldScrollToError = action.payload;
  },
  setPopupVariables(state: AutomatedMessageState, action: PayloadAction<PopupVariable[]>) {
    state.condition.popupVariables = action.payload;
  },
  setSelectedOptions(state: AutomatedMessageState, action: PayloadAction<{ item: any[] }>) {
    state.condition.selectedOptions = [...state.condition.selectedOptions, action.payload.item];
  },
  removeSelectedOptions(state: AutomatedMessageState, action: PayloadAction<any>) {
    state.condition.selectedOptions = state.condition.selectedOptions.filter(
      (deleteOption) => deleteOption.name !== action.payload.label.name
    );
  },
  setSelectedCondition(state: AutomatedMessageState, action: PayloadAction<any>) {
    const operators: any[] = [];
    const variables: any[] = [];
    if (action.payload.operator_1) {
      operators.push(Object.values(action.payload.operator_1)[0]);
    }
    if (action.payload.operator_2) {
      operators.push(Object.values(action.payload.operator_2)[0]);
    }
    if (action.payload.variables) {
      variables.push(Object.values(action.payload.variables)[0]);
    }
    state.condition.selectedConditions = [
      ...state.condition.selectedConditions,
      {
        name: action.payload.name,
        group_name: action.payload.group_name,
        value: [],
        operators: operators,
        variable: variables,
      },
    ];
  },
  removeSelectedCondition(state: AutomatedMessageState, action: PayloadAction<any>) {
    state.condition.selectedConditions = state.condition.selectedConditions.filter(
      (deleteOption) => deleteOption.name !== action.payload.label.name
    );
  },
  setFirstOperator(state: AutomatedMessageState, action: PayloadAction<any>) {
    state.condition.selectedConditions.map((item) => {
      if (item.name === action.payload.name) item.operators[0] = action.payload.operator;
    });
  },
  setSecondOperator(state: AutomatedMessageState, action: PayloadAction<any>) {
    state.condition.selectedConditions.map((item) => {
      if (item.name === action.payload.name) item.operators[1] = action.payload.operator;
    });
  },
  setVariables(state: AutomatedMessageState, action: PayloadAction<any>) {
    state.condition.selectedConditions.map((item) => {
      if (item.name === action.payload.name) item.variable = [action.payload.variable];
    });
  },
  setConditionValue(state: AutomatedMessageState, action: PayloadAction<any>) {
    state.condition.selectedConditions.map((item) => {
      if (item.name === action.payload.name) {
        if (action.payload.type === 'multi-select') item.value = action.payload.value;
        else item.value = [action.payload.value];
      }
    });
  },
  setMessageVariables(state: AutomatedMessageState, action: PayloadAction<MessageVariables>) {
    state.message.messageVariables = action.payload;
  },
  setLanguages(state: AutomatedMessageState, action: PayloadAction<Language[]>) {
    state.message.languages = action.payload;
  },
  setAttachments(state: AutomatedMessageState, action: PayloadAction<string[]>) {
    state.message.attachments = action.payload;
  },
  setBookingToMessage(
    state: AutomatedMessageState,
    action: PayloadAction<BookingAutomatedMessage>
  ) {
    state.message.messages[state.message.selectedMessageIndex].booking = action.payload;
  },
  setShouldCheckForToFieldError(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.message.shouldCheckForToFieldError = action.payload;
  },
  setShouldCheckContentMessageFieldError(
    state: AutomatedMessageState,
    action: PayloadAction<boolean>
  ) {
    state.message.ShouldCheckContentMessageFieldError = action.payload;
  },
  setHasFailedRenderMessages(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.message.hasFailedRenderMessages = action.payload;
  },
  setFailedRenderMessages(
    state: AutomatedMessageState,
    action: PayloadAction<FailedRenderMessage[]>
  ) {
    state.message.failedRenderMessages = action.payload;
  },
  setSelectedMessageIndex(state: AutomatedMessageState, action: PayloadAction<number>) {
    state.message.selectedMessageIndex = action.payload;
    state.message.selectedLanguageTab = state.message.messages[action.payload].contents[0].language;
  },
  setSelectedLanguageTab(state: AutomatedMessageState, action: PayloadAction<string>) {
    state.message.selectedLanguageTab = action.payload;
  },
  setMessageType(state: AutomatedMessageState, action: PayloadAction<MessageType>) {
    state.message.messages[state.message.selectedMessageIndex].type = action.payload;
  },
  setIsHtml(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.message.messages[state.message.selectedMessageIndex].isHtml = action.payload;
  },
  setOverrideSettings(state: AutomatedMessageState, action: PayloadAction<OverrideSetting[]>) {
    const { selectedMessageIndex } = state.message;
    state.message.messages[selectedMessageIndex].overrideSettings = action.payload;
  },
  setOverrideSetting(state: AutomatedMessageState, action: PayloadAction<OverrideSetting>) {
    const { selectedMessageIndex } = state.message;
    const { overrideSettings } = state.message.messages[selectedMessageIndex];
    const foundSetting = overrideSettings.find(
      (setting) => setting.brandId === action.payload.brandId
    );
    if (foundSetting) {
      const index = overrideSettings.indexOf(foundSetting);
      if (index > -1) {
        state.message.messages[selectedMessageIndex].overrideSettings[index] = action.payload;
      }
    } else {
      if (action.payload.name || action.payload.email) {
        state.message.messages[selectedMessageIndex].overrideSettings = [
          ...overrideSettings,
          action.payload,
        ];
      }
    }
  },
  setToSelectedValues(state: AutomatedMessageState, action: PayloadAction<any[]>) {
    state.message.messages[state.message.selectedMessageIndex].toSelectedValues = action.payload;
  },
  setCcSelectedValues(state: AutomatedMessageState, action: PayloadAction<any[]>) {
    state.message.messages[state.message.selectedMessageIndex].ccSelectedValues = action.payload;
  },
  setBccSelectedValues(state: AutomatedMessageState, action: PayloadAction<any[]>) {
    state.message.messages[state.message.selectedMessageIndex].bccSelectedValues = action.payload;
  },
  setContents(state: AutomatedMessageState, action: PayloadAction<any>) {
    const { contents } = state.message.messages[state.message.selectedMessageIndex];
    const content = contents.find(
      (content) => content.language === state.message.selectedLanguageTab
    );
    if (content) {
      const contentIndex = contents.indexOf(content);
      if (contentIndex > -1) {
        state.message.messages[state.message.selectedMessageIndex].contents[contentIndex][
          action.payload.propertyToChange
        ] = action.payload.value;
      }
    }
  },
  deleteContent(
    state: AutomatedMessageState,
    action: PayloadAction<{
      selectedMessageIndex: number;
      languageToDelete: string;
    }>
  ) {
    const { selectedMessageIndex, languageToDelete } = action.payload;
    const { contents } = state.message.messages[selectedMessageIndex];
    const filteredContents = contents.filter((content) => content.language !== languageToDelete);
    const deletedLanguageWasSelected = languageToDelete === state.message.selectedLanguageTab;
    state.message.messages[selectedMessageIndex].contents = filteredContents;
    if (deletedLanguageWasSelected) {
      state.message.selectedLanguageTab =
        state.message.messages[state.message.selectedMessageIndex].contents[0].language;
    }
  },
  addNewContent(state: AutomatedMessageState, action: PayloadAction<string>) {
    state.message.messages[state.message.selectedMessageIndex].contents = [
      ...state.message.messages[state.message.selectedMessageIndex].contents,
      {
        subject: '',
        header: defaultHeader,
        messageText: '',
        footer: defaultFooterTemplate,
        language: action.payload,
      },
    ];
  },
  removeMessageAttachment(state: AutomatedMessageState, action: PayloadAction<number>) {
    const { attachments } = state.message.messages[state.message.selectedMessageIndex];
    const { controller } = attachments[action.payload];
    if (controller) controller.abort();
    attachments.splice(action.payload, 1);
    state.message.messages[state.message.selectedMessageIndex].attachments = attachments;
  },
  removeMessageAttachmentById(
    state: AutomatedMessageState,
    action: PayloadAction<number | string>
  ) {
    const { attachments } = state.message.messages[state.message.selectedMessageIndex];
    const attachment = attachments.find((attachment) => attachment.id === action.payload);
    if (attachment) {
      const index = attachments.indexOf(attachment);
      if (attachment.controller) attachment.controller.abort();
      attachments.splice(index, 1);
      state.message.messages[state.message.selectedMessageIndex].attachments = attachments;
    }
  },
  addNewMessageAttachment(state: AutomatedMessageState, action: PayloadAction<any>) {
    const { attachments } = state.message.messages[state.message.selectedMessageIndex];
    state.message.messages[state.message.selectedMessageIndex].attachments = [
      ...attachments,
      action.payload,
    ];
  },
  replaceMessageAttachment(
    state: AutomatedMessageState,
    action: PayloadAction<{
      currentId: number | string;
      file: File;
    }>
  ) {
    const { currentId, file } = action.payload;
    const { attachments } = state.message.messages[state.message.selectedMessageIndex];
    const attachment = attachments.find((attachment) => attachment.id === currentId);
    if (attachment) {
      const index = attachments.indexOf(attachment);
      const newAttachment: MessageAttachment = {
        ...attachment,
        id: file.id,
        file_name: file.file_name,
        controller: undefined,
      };
      attachments.splice(index, 1, newAttachment);
      state.message.messages[state.message.selectedMessageIndex].attachments = attachments;
    }
  },
  updateMessageAttachmentUploadedPercent(state: AutomatedMessageState, action: PayloadAction<any>) {
    const { id, uploadedPercent } = action.payload;
    const { attachments } = state.message.messages[state.message.selectedMessageIndex];
    const attachment = attachments.find((attachment) => attachment.id === id);
    if (attachment) {
      const index = attachments.indexOf(attachment);
      const newAttachment = { ...attachment, uploadedPercent };
      attachments.splice(index, 1, newAttachment);
      state.message.messages[state.message.selectedMessageIndex].attachments = attachments;
    }
  },
  addNewMessage(state: AutomatedMessageState) {
    state.message.messages = [
      ...state.message.messages,
      {
        id: null,
        type: 'email',
        isHtml: false,
        toSelectedValues: [],
        ccSelectedValues: [],
        bccSelectedValues: [],
        overrideSettings: [],
        contents: [
          {
            subject: '',
            header: defaultHeader,
            messageText: '',
            footer: defaultFooterTemplate,
            language: 'en',
          },
        ],
        attachments: [],
        timestamp: Date.now(),
        booking: null,
      },
    ];
    const { messages } = state.message;
    state.message.selectedLanguageTab = messages[messages.length - 1].contents[0].language;
    state.message.selectedMessageIndex = messages.length - 1;
  },
  removeMessage(state: AutomatedMessageState, action: PayloadAction<number>) {
    const { messages, selectedMessageIndex } = state.message;
    state.message.messages = messages.filter((message, index) => index !== action.payload);
    if (action.payload < selectedMessageIndex) {
      state.message.selectedMessageIndex = selectedMessageIndex - 1;
    }
  },
  setMessageIsEditing(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.message.isEditing = action.payload;
  },
  changeMessage(state: AutomatedMessageState, action: PayloadAction<MessageState>) {
    state.message = { ...state.message, ...action.payload };
  },
  setMessagesId(state: AutomatedMessageState, action: PayloadAction<MessageDetails[]>) {
    state.message.messages = state.message.messages.map((tem, i) => ({
      ...tem,
      id: action.payload[i].id,
    }));
  },
};

export function getConditions() {
  return async () => {
    try {
      const response = await automatedMessageAPI.fetchConditionsPopup();
      dispatch(slice.actions.setPopupVariables(response.data));
    } catch (error) {}
  };
}
function createCopies(obj) {
  let newObject = {};
  for (let key in obj) {
      if (obj.hasOwnProperty(key)) {         
          newObject[key] = obj[key];         
          let newKey = key + "1";         
          newObject[newKey] = {
              name: `{${obj[key].name}}`,
              group_name: obj[key].group_name, 
              value: obj[key].value.replace("{{", "(").replace("}}", ")"),  
              example: obj[key].example
          };
      }
  }

  return newObject;
}
export function getVariables(type: VariableApiType, bookingId = null) {
  return async () => {
    try {
      let response = await automatedMessageAPI.fetchVariables(type, bookingId);
      response.data.body = createCopies(response.data.body);      
      const variables = provideVariables(response.data);      
      if (type === 'automated_message') {
        dispatch(slice.actions.setMessageVariables(variables));
      }
      if (type === 'auto_response') {
        dispatch(slice.actions.setAutoResponseVariables(variables.bodyVariables));
      }
    } catch (error) {}
  };
}

export function getLanguages() {
  return async () => {
    try {
      const response = await automatedMessageAPI.fetchLanguages();
      const languageValues = Object.keys(response.data);
      const languages = languageValues.map((value) => ({ value, label: response.data[value] }));
      dispatch(slice.actions.setLanguages(languages));
    } catch (error) {}
  };
}

export function getAttachments() {
  return async () => {
    try {
      const response = await automatedMessageAPI.fetchAttachments();
      dispatch(slice.actions.setAttachments(response.data));
    } catch (error) {}
  };
}

const onPercentageChange = (id: string | number, uploadedPercent: number | undefined) => {
  dispatch(slice.actions.updateMessageAttachmentUploadedPercent({ id, uploadedPercent }));
};

export function uploadAttachment(attachment: FormData, id: string, signal: AbortSignal) {
  return async () => {
    try {
      const config: AxiosRequestConfig<FormData> = {
        signal,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          onPercentageChange(id, percent);
        },
      };
      const response = await automatedMessageAPI.uploadFile(attachment, config);
      dispatch(slice.actions.replaceMessageAttachment({ currentId: id, file: response.data }));
      onPercentageChange(response.data.id, undefined);
    } catch (error) {
      dispatch(slice.actions.removeMessageAttachmentById(id));
    }
  };
}

const validateRecipients = (recipients: any, type: MessageType) =>
  recipients.filter(
    (recipient) =>
      recipient.type === 'variable' ||
      (type === 'email'
        ? yup.string().email().isValidSync(recipient.content)
        : isValidPhoneNumber(recipient.content))
  );
export const provideTemplates = (messages: MessageDetails[]) =>
  messages.map((message) => ({
    ...message,
    isHtml: message.type === 'email' ? message.isHtml : false,
    overrideSettings: message.type === 'email' ? message.overrideSettings : [],
    toSelectedValues:
      message.type !== 'api' ? validateRecipients(message.toSelectedValues, message.type) : [],
    ccSelectedValues:
      message.type === 'email' ? validateRecipients(message.ccSelectedValues, 'email') : [],
    bccSelectedValues:
      message.type === 'email' ? validateRecipients(message.bccSelectedValues, 'email') : [],
     
    contents: message.contents.map((content) => {
      const isEmail = message.type === 'email';
      if (isEmail && message.isHtml)
        return {
          ...content,
          subject: removeNewLineFromEntity(
            convertHtmlToText(replaceContentVariablesWithEntity(content.subject))
          ),
          header: replaceContentVariablesWithEntity(content.header),
          footer: content.footer,
          messageText: replaceContentVariablesWithEntity(content.messageText),
        };
      return {
        ...content,
        header: '',
        footer: null,
        subject: isEmail
          ? removeNewLineFromEntity(
              convertHtmlToText(replaceContentVariablesWithEntity(content.subject))
            )
          : '',
        messageText: isEmail
          ? replaceContentVariablesWithEntity(content.messageText)
          : removeNewLineFromEntity(
              convertHtmlToText(replaceContentVariablesWithEntity(content.messageText))
            ),
      };
    }),
    timestamp: message.timestamp || Date.now(),
  }));
export const provideWhen = (whenState: WhenState) => {
  switch (whenState.whenRadioValue) {
    case 'based_on_booking_timeline':
      return {
        name: 'Booking Time Line',
        group_name: 'when',
        value: whenState.timeComparisonValue === 'on' ? [] : [whenState.timeVariationAmountValue],
        variable: [whenState.timelineValue],
        operators:
          whenState.timeComparisonValue === 'on'
            ? [whenState.timeComparisonValue]
            : [whenState.timeComparisonValue, whenState.timeVariationValue],
      };
    default:
      return undefined;
  }
};
export const provideConditions = (selectedConditions: SelectedCondition[]) =>
  selectedConditions.map((condition) => ({
    ...condition,
    value:
      condition.group_name === 'property'
        ? condition.value.map((val) => val?.id || val?.name)
        : condition.value,
  }));

export function saveAutomatedMessage(
  automatedMessageState: AutomatedMessageState,
  isFromNow: boolean,
  foundBookingIds: any[],
  callback: (succeed: boolean) => void
) {
  return async () => {
    const { name, isActive, editingId, isSavedMessage } = automatedMessageState;
    const { selectedConditions } = automatedMessageState.condition;
    const templates = provideTemplates(automatedMessageState.message.messages);
    const when = provideWhen(automatedMessageState.when);
    const body = {
      id: editingId,
      name: name,
      is_active: isActive,
      timeFrame: automatedMessageState.when.timeFrame,
      when: !isSavedMessage && when ? [when] : [],
      conditions: !isSavedMessage ? provideConditions(selectedConditions) : [],
      templates: templates,
      is_from_now: isFromNow,
      found_booking_ids: foundBookingIds,
      conditions_text: !isSavedMessage
        ? provideConditionsSummaryText(automatedMessageState.when, selectedConditions)
        : '',
      saved_message: isSavedMessage,
    };
    try {
      const response = await automatedMessageAPI.postAutomatedMessage(body);
      dispatch(slice.actions.setAutomatedMessageEditingId(response.data.id));
      dispatch(slice.actions.setSaveStatus('succeed'));
      callback(true);
    } catch (error) {
      dispatch(slice.actions.setSaveStatus('failed'));
      callback(false);
    }
  };
}

export function renderTemplates(messages: MessageDetails[], callback: (success: boolean) => void) {
  return async () => {
    try {
      dispatch(slice.actions.setFailedRenderMessages([]));
      const templates = provideTemplates(messages);
      const renderResultRes = await automatedMessageAPI.renderTemplates(templates);
      const failedRenderTemplates = renderResultRes.data.filter((temp) => temp.status === 'failed');
      dispatch(slice.actions.setFailedRenderMessages(failedRenderTemplates));
      dispatch(slice.actions.setHasFailedRenderMessages(failedRenderTemplates.length > 0));
      callback(failedRenderTemplates.length === 0);
    } catch (err) {
      callback(false);
    }
  };
}

export function saveTemplates(
  automatedMessageId: number,
  messages: MessageDetails[],
  callback: (success: boolean) => void
) {
  return async () => {
    try {
      const templates = provideTemplates(messages);
      const saveTemplatesRes = await automatedMessageAPI.saveTemplates(
        automatedMessageId,
        templates
      );
      if (saveTemplatesRes.data?.message?.templates)
        dispatch(slice.actions.setMessagesId(saveTemplatesRes.data.message.templates));
      dispatch(slice.actions.setSelectedMessageIndex(0));
      callback(true);
    } catch (err) {
      callback(false);
    }
  };
}

export function getAutomatedMessage(
  id: number,
  setLoading?: (state: boolean) => void,
  callback?: (res: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const res = await automatedMessageAPI.fetchAutomatedMessage(id);
      dispatch(slice.actions.setAutomatedMessage(res.data));
      callback?.(res, 200);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}
