import React, { useEffect } from 'react'
import LoadingScreen from 'src/components/LoadingScreen';

const Redirecting = ({ to }) => {

  useEffect(() => {
    window.location.href = to;
  }, [to])

  return (
    <LoadingScreen />
  )
}

export default Redirecting;