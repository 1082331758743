import { differenceInMinutes, isAfter, max } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { min } from "lodash";
import { Params } from 'react-router';
import {
  ListingMenuConfig,
  ListingPageTab,
  ListingTabsData,
  ListingUnitTypeMenuConfig,
  PropertyMode,
  RoomType,
  ViewLevel,
  TimeIndex,
  PropertyPolicies,
  CheckinCheckoutProps,
  TaskResponsible,
  TaskCategory,
  UnitTypeCategory,
  Faq,
  SimplifiedTimeKey,
  TimeKey,
  CheckinCheckoutTimes,
  CheckinChekoutLimits,
} from 'src/@types/listings';
import PropertyApi from 'src/api/property';
import { mergeObjectOfArrays } from './arrayUtils';
import numeral from "numeral";

export const NO_LICENSE = 'NO_LICENSE';
export const NOT_ENOUGH = 'NOT_ENOUGH';
export const HAVE_LICENSE = 'HAVE_LICENSE';

export const getListingMenuBedrooms = (category: UnitTypeCategory | null, bedrooms: number) => category?.show_name === 'Studio' ? undefined : bedrooms;
export const getListingSubheader = (category: UnitTypeCategory | null) => category ? [
  category.show_name || '',
  category.type || '',
].filter((text) => text).join(' / ') : undefined;

export const generateListingTabs = (key: ViewLevel): ListingPageTab[] => {
  const TABS: { [key in ViewLevel]: ListingPageTab[] } = {
    property: [
      'details',
      'pricing_and_availability',
      'policies',
      'instructions',
      'marketing',
      'operation',
    ],
    unitType: ['details', 'pricing_and_availability', 'marketing', 'operation'],
    unit: ['details', 'pricing_and_availability', 'instructions', 'marketing', 'operation'],
  };
  return TABS[key];
};

export const tabExceptions = (
  key: ViewLevel,
  tabs: ListingPageTab[],
  propertyMode: PropertyMode
): ListingPageTab[] => {
  switch (propertyMode) {
    case 'complex':
      return tabs.filter((tab) => !generateListingTabs(key).includes(tab));
    case 'multi_unit':
      if (key === 'unit') {
        return tabs.filter((tab) => !generateListingTabs(key).includes(tab));
      }
      return tabs.filter((tab) => ![
        ...generateListingTabs('unitType'),
        ...generateListingTabs('property'),
      ].includes(tab));
    case 'single_unit':
      return tabs.filter((tab) => ![
        ...generateListingTabs('unit'),
        ...generateListingTabs('unitType'),
        ...generateListingTabs('property'),
      ].includes(tab));
  }
};

export const getViewLevelsContainingTab = (tab: ListingPageTab): ViewLevel[] => {
  const containing: ViewLevel[] = [];
  (['property', 'unitType', 'unit'] as ViewLevel[]).forEach((key) => {
    if (generateListingTabs(key).includes(tab)) containing.push(key);
  });
  return containing;
};

export const viewLevelController = (propertyMode: PropertyMode, viewLevel: ViewLevel) => {
  switch (propertyMode) {
    case 'complex':
      return viewLevel;
    case 'multi_unit':
      return viewLevel === 'unitType' ? 'property' : viewLevel;
    case 'single_unit':
      return 'property';
    default:
      return viewLevel;
  }
};

export const getPropertyMode = (menuConfig: ListingMenuConfig): PropertyMode => {
  if (menuConfig.unit_types.length === 1) {
    if (menuConfig.unit_types[0]?.units.length === 1) return 'single_unit';
    else return 'multi_unit';
  }
  return 'complex';
};

export const getUnitTypeByUnitId = (unitTypes: ListingUnitTypeMenuConfig[], unitId: number) => {
  const unitType = unitTypes.find((unitType) => unitType.units.some(({ id }) => id === unitId));
  return unitType || null;
};

export const getRelevantId = (params: Readonly<Params<string>>, viewKey: ViewLevel) => {
  const { propertyId, unitTypeId, unitId } = params;
  if (viewKey === 'property' && propertyId) return +propertyId;
  if (viewKey === 'unitType' && unitTypeId) return +unitTypeId;
  if (viewKey === 'unit' && unitId) return +unitId;
  return null;
};

export const checkTabIsLoading = (
  tabsData: ListingTabsData,
  tab: ListingPageTab,
  viewLevel: ViewLevel,
  id: number | null
) => {
  if (!id) return false;
  return tabsData[tab]?.loading[viewLevel]?.includes(id);
};

export const checkIfShouldFetch = (
  tabsData: ListingTabsData,
  tab: ListingPageTab,
  viewLevel: ViewLevel,
  id: number
): boolean => {
  if (checkTabIsLoading(tabsData, tab, viewLevel, id)) return false;
  switch (tab) {
    case 'details':
      if (viewLevel === 'property' && tabsData.details.property !== null) return false;
      if (viewLevel === 'unitType' && tabsData.details.unitTypes[id]) return false;
      if (viewLevel === 'unit' && tabsData.details.units[id]) return false;
      return true;
    case 'pricing_and_availability':
      if (viewLevel === 'property' && tabsData.pricing_and_availability.property !== null) return false;
      if (viewLevel === 'unitType' && tabsData.pricing_and_availability.unitTypes[id]) return false;
      if (viewLevel === 'unit' && tabsData.pricing_and_availability.units[id]) return false;
      return true;
    case 'policies':
      if (viewLevel === 'property' && tabsData.policies.property !== null) return false;
      return true;
    case 'instructions':
      if (viewLevel === 'property' && tabsData.instructions.property !== null) return false;
      if (viewLevel === 'unit' && tabsData.instructions.units[id]) return false;
      return true;
    case 'marketing':
      if (viewLevel === 'property' && tabsData.marketing.property?.id === id) return false;
      if (viewLevel === 'unitType' && tabsData.marketing.unitTypes[id]) return false;
      if (viewLevel === 'unit' && tabsData.marketing.units[id]) return false;
      return true;
    case 'operation':
      if (viewLevel === 'property' && tabsData.operation.property !== null)
        return false;
      if (viewLevel === 'unitType' && tabsData.operation.unitTypes[id]) return false;
      if (viewLevel === 'unit' && tabsData.operation.units[id]) return false;
      return true;
    default:
      return false;
  }
};

export const checkRoomCanHaveBed = (roomtype: RoomType) => {
  const roomTypes: RoomType[] = ['Bedroom', 'Living Area', 'Main Area', 'Living Room'];
  return roomTypes.includes(roomtype);
};

export const getTimeIndex = (
  startTime: string | null | undefined,
  endTime: string | null | undefined
): TimeIndex | '' => {
  if (startTime || endTime) {
    if (startTime === endTime) return 'at';
    if (!startTime) return 'before';
    if (!endTime) return 'after';
    return 'between';
  }
  return '';
};

export const getControlledTimes = (
  timeIndex: TimeIndex,
  startTime: Date | null,
  endTime: Date | null,
  reversedPriority = false
) => {
  let controlledStartTime: Date | null = startTime;
  let controlledEndTime: Date | null = endTime;
  if (timeIndex === 'at') {
    if (reversedPriority) controlledStartTime = endTime;
    else controlledEndTime = startTime;
  } else if (timeIndex === 'before') {
    controlledStartTime = null;
  } else if (timeIndex === 'after') {
    controlledEndTime = null;
  }
  return { controlledStartTime, controlledEndTime };
};

export const getControlledValue = (
  overriddenValue?: any  | null,
  defaultValue?: any, equalCheck = false
) => {
  if (overriddenValue === undefined || overriddenValue === null) {
    return { value: defaultValue, overridden: false };
  }
  if (equalCheck && overriddenValue === defaultValue) {
    return { value: defaultValue, overridden: false };
  }
  return { value: overriddenValue, overridden: true };
};

export const getCheckinCheckoutLimit = (checkinCheckoutTimes: CheckinCheckoutTimes, key: SimplifiedTimeKey) => {
  let maxTime: Date | undefined = undefined;
  let minTime: Date | undefined = undefined;
  const order: SimplifiedTimeKey[] = ['EPCO', 'EFCO', 'LFCO', 'LPCO', 'EPCI', 'EFCI', 'LFCI', 'LPCI'];
  const selfIndex = order.indexOf(key);
  for (let i = 0; i < order.length; i++) {
    const timeKey = convertSimplifiedToTimeKey(order[i]);
    const value = checkinCheckoutTimes[timeKey];
    const zonedValue = value ? utcToZonedTime(value, 'UTC') : null;
    if (zonedValue !== null && i !== selfIndex) {
      zonedValue.setFullYear(0, 0, 1);
      if (i < selfIndex) {
        if (minTime === undefined || isAfter(zonedValue, minTime)) {
          minTime = zonedValue;
        }
      } else if (maxTime === undefined) {
        maxTime = zonedValue;
      }
      
    }
    
  }
  return { maxTime, minTime };
}

export const getCheckinCheckoutLimits = (checkinCheckoutTimes: CheckinCheckoutTimes) => {
  const limits = {};
  const order: SimplifiedTimeKey[] = ['EPCO', 'EFCO', 'LFCO', 'LPCO', 'EPCI', 'EFCI', 'LFCI', 'LPCI'];
  order.forEach(key => {
    limits[key] = getCheckinCheckoutLimit(checkinCheckoutTimes, key);
  });
  return limits as CheckinChekoutLimits;
}

// ----------------------------------------------------------------------

const getDefaultCompareDate = (value: Date | null | 'org_config' | undefined, propertyValue: string | null, orgValue: string | null) => {
  if (value === 'org_config') return orgValue ? utcToZonedTime(orgValue, 'UTC') : null;
  if (value === undefined) return propertyValue ? utcToZonedTime(propertyValue, 'UTC') : null;
  return value ? utcToZonedTime(value, 'UTC') : null;
};
const getTimeDifference = (start: Date, end: Date) => {
  start.setFullYear(1, 1, 1);
  end.setFullYear(1, 1, 1);
  return differenceInMinutes(start, end) / 60;
};
const getMaxEstimeationTime = ({ LFCO, LPCO, EPCI, EFCI }: {[key in 'LFCO' | 'LPCO' | 'EPCI' | 'EFCI']: Date | null}) => {
  if (LFCO && EFCI) {
    if (EPCI && LPCO) {
      return min([getTimeDifference(EFCI, LPCO), getTimeDifference(EPCI, LFCO)]) || 0;
    } else if (LPCO) {
      return getTimeDifference(EFCI, LPCO);
    } else if (EPCI) {
      return getTimeDifference(EPCI, LFCO);
    }
    return getTimeDifference(EFCI, LFCO);
  }
  return 0;
};
export const checkTimesHasConflictWithHousekeepingTime = ({ LFCO, LPCO, EPCI, EFCI }: {[key in 'LFCO' | 'LPCO' | 'EPCI' | 'EFCI']?: Date | null | 'org_config'}, property: PropertyPolicies, props: CheckinCheckoutProps) => {
  const {
    latest_free_checkout_time,
    latest_possible_checkout_time,
    earliest_possible_checkin_time,
    earliest_free_checkin_time,
  } = property.organization_config;
  const {
    latestFreeCheckoutTime,
    latestPossibleCheckoutTime,
    earliestPossibleCheckinTime,
    earliestFreeCheckinTime,
  } = props;

  LFCO = getDefaultCompareDate(LFCO, latestFreeCheckoutTime, latest_free_checkout_time);
  LPCO = getDefaultCompareDate(LPCO, latestPossibleCheckoutTime, latest_possible_checkout_time);
  EPCI = getDefaultCompareDate(EPCI, earliestPossibleCheckinTime, earliest_possible_checkin_time);
  EFCI = getDefaultCompareDate(EFCI, earliestFreeCheckinTime, earliest_free_checkin_time);

  const maxEstimationTime = getMaxEstimeationTime({ LFCO, LPCO, EPCI, EFCI });
  const hasConflict = property.unit_types.some(({ average_house_keeping_time }) => average_house_keeping_time > maxEstimationTime);
  return { hasConflict, maxEstimationTime };
};

export const generateTaskResponsibleOptions = (data: TaskResponsible[]) => {
  const newOptions = {};
  data.forEach((responsible) => {
    if (responsible.task_categories) {
      let categories: TaskCategory[] = [];
      if (Array.isArray(responsible.task_categories)) {
        categories = responsible.task_categories;
      } else {
        categories = Object.values(responsible.task_categories);
      }
      categories.forEach((category) => {
        if (newOptions[category] === undefined) {
          newOptions[category] = [];
        }
        newOptions[category].push({ ...responsible, groupBy: category });
      });
    }
  });
  return mergeObjectOfArrays(newOptions);
};

export const checkListingTabsAcl = (user, tab: string, viewLevel: ViewLevel, PropertyMode?: PropertyMode) => { 
  switch (tab) {
    case 'details':
      if (viewLevel === 'property') {
        return (
          user.acls.PeymansPropertyBundleProperty.VIEW ||
          user.acls.PeymansPropertyBundleProperty.EDIT ||
          user.acls.PeymansPropertyBundleRoom.VIEW ||
          user.acls.PeymansPropertyBundleRoom.EDIT
        );
      } else if (viewLevel === 'unitType') {
        return (
          user.acls.PeymansPropertyBundleUnitType.VIEW ||
          user.acls.PeymansPropertyBundleUnitType.EDIT
        );
      } else if (viewLevel === 'unit') {
        return (
          user.acls.PeymansPropertyBundlePropertyUnit.VIEW ||
          user.acls.PeymansPropertyBundlePropertyUnit.EDIT
        );
      } else {
        return false;
      }
      
    case 'pricing_and_availability':
      return user.acls.peymans_property_bundle_ical_pricing_tab_acl?.EXECUTE;
    
    case 'policies':
      return (
        user.acls.PeymansPropertyBundleProperty.VIEW ||
        user.acls.PeymansPropertyBundleProperty.EDIT
      );

    case 'instructions':
      if (viewLevel === 'property') {
        if (PropertyMode === 'single_unit') 
          return (
            (user.acls.PeymansPropertyBundleProperty.VIEW &&
              user.acls.PeymansBookingEngineBundleFaq.VIEW) ||
            user.acls.PeymansPropertyBundlePropertyUnit.VIEW ||
            user.acls.PeymansPropertyBundlePropertyUnit.EDIT
          );
        else
          return (
            user.acls.PeymansPropertyBundleProperty.VIEW &&
            user.acls.PeymansBookingEngineBundleFaq.VIEW
          );
      }
      if (viewLevel === 'unitType') {
        return (user.acls.PeymansPropertyBundleProperty.VIEW &&
          user.acls.PeymansBookingEngineBundleFaq.VIEW) ||
        user.acls.PeymansPropertyBundlePropertyUnit.VIEW ||
        user.acls.PeymansPropertyBundlePropertyUnit.EDIT;
      }
      if (viewLevel === 'unit') {
        return (
          user.acls.PeymansPropertyBundlePropertyUnit.VIEW ||
          user.acls.PeymansPropertyBundlePropertyUnit.EDIT
        );
      }
      return false;
    
    case 'marketing':
      if (viewLevel === 'property') {
        return (
          user.acls.PeymansPropertyBundleProperty.VIEW ||
          user.acls.PeymansPropertyBundleProperty.EDIT
        );
      }
      if (viewLevel === 'unitType') {
        return (
          user.acls.PeymansPropertyBundleUnitType.VIEW ||
          user.acls.PeymansPropertyBundleUnitType.EDIT
        );
      }
      return (
        user.acls.PeymansPropertyBundlePropertyUnit.VIEW ||
        user.acls.PeymansPropertyBundlePropertyUnit.EDIT
      );

    case 'operation':
      if (viewLevel === 'property') {
        return (
          user.acls.PeymansStaffBundleStaff.VIEW ||
          user.acls.PeymansStaffBundleStaff.EDIT ||
          user.acls.PeymansStaffBundleStaff.ASSIGN ||
          user.acls.PeymansStaffBundleStaff.CREATE ||
          user.acls.PeymansTaskBundleTaskResponsible.EDIT ||
          user.acls.PeymansTaskBundleTask.VIEW ||
          user.acls.PeymansTaskBundleTask.EDIT
        );
      } else if (viewLevel === 'unitType') {
        return (
          user.acls.PeymansTaskBundleTask.VIEW ||
          user.acls.PeymansTaskBundleTask.EDIT
        );
      } else if (viewLevel === 'unit') {
        return (
          user.acls.PeymansTaskBundleTask.VIEW ||
          user.acls.PeymansTaskBundleTask.EDIT
        );
      } else {
        return false;
      }
  }
};

export const hasListingEditAccess = (user, viewLevel: ViewLevel) => {
  switch (viewLevel) {
    case 'property':
      return user.acls.PeymansPropertyBundleProperty.EDIT;
    case 'unitType':
      return user.acls.PeymansPropertyBundleUnitType.EDIT;
    case 'unit':
      return user.acls.PeymansPropertyBundlePropertyUnit.EDIT;
    
    default:
      return false;
  }
}

export const hasListingViewAccess = (user, viewLevel: ViewLevel) => {
  switch (viewLevel) {
    case 'property':
      return user.acls.PeymansPropertyBundleProperty.VIEW;
    case 'unitType':
      return user.acls.PeymansPropertyBundleUnitType.VIEW;
    case 'unit':
      return user.acls.PeymansPropertyBundlePropertyUnit.VIEW;
    
    default:
      return false;
  }
}

export const createdAtPerView = (
  viewLevel: ViewLevel,
  menuConfig,
  unitType,
  unit,
  isSingle: boolean
) => {
  if (isSingle) {
    const dates: Date[] = [];
    if (menuConfig.created_at) {
      dates.push(new Date(menuConfig.created_at))
    }
    if (unitType?.created_at) {
      dates.push(new Date(unitType?.created_at))
    }
    if (unit?.created_at) {
      dates.push(new Date(unit?.created_at))
    }
    return max(dates);
  } else {
    switch (viewLevel) {
      case 'property':
        return menuConfig?.created_at;
      case 'unitType':
        return unitType?.created_at;
      case 'unit':
        return unit?.created_at;

      default:
        return null;
    }
  }
};

export const updatedAtPerView = (
  viewLevel: ViewLevel,
  menuConfig,
  unitType,
  unit,
  isSingle: boolean
) => {
  if (isSingle) {
    const dates: Date[] = [];
    if (menuConfig.updated_at) {
      dates.push(new Date(menuConfig.updated_at))
    }
    if (unitType?.updated_at) {
      dates.push(new Date(unitType?.updated_at))
    }
    if (unit?.updated_at) {
      dates.push(new Date(unit?.updated_at))
    }
    return max(dates);
  } else {
    switch (viewLevel) {
      case 'property':
        return menuConfig.updated_at;
      case 'unitType':
        return unitType?.updated_at;
      case 'unit':
        return unit?.updated_at;

      default:
        return null;
    }
  }
};

export const generateRatePlanName = (unitTypeName: string, names: string[], suffixValue: number = 1) => {
  let suffix: number = suffixValue;
  let isDuplicate = true;
  let ratePlanName = '';
  while(isDuplicate) {
    ratePlanName = `${unitTypeName}-${numeral(suffix).format('00')}`;
    if(names.includes(ratePlanName)) {
      suffix = suffix + 1;
    } else {
      isDuplicate = false;
    }
  }

  return ratePlanName;
}

export const findAreaId = async (city: string | null, countryCode: string | null) => {
  if (city === null || countryCode === null) {
    return null;
  }
  let response = await PropertyApi.getCityAndAreaId(city, countryCode);
  return Promise.resolve(response.data.area_id);
};

export const sortFaqs = (faqs: Faq[]): Faq[] => [...faqs].sort((a, b) => a.priority - b.priority);
export const findNextMaxFaqsPriority = (faqs: Faq[]): number => {
  let maxPriority = 0;
  faqs.forEach((faq)=> {
    if (faq.priority > maxPriority) maxPriority = faq.priority;
  });
  return maxPriority + 1;
}

export const getUnitTypeCategoryType = (type: string | undefined) => {
  if (type?.toLowerCase() === 'private room' || type?.toLowerCase() === 'shared room') {
    return 'Room';
  }
  return type;
};

export const convertSimplifiedToTimeKey = (key: SimplifiedTimeKey) => ({
  EPCI: 'earliest_possible_checkin_time',
  EFCI: 'earliest_free_checkin_time',
  LFCI: 'latest_free_checkin_time',
  LPCI: 'latest_possible_checkin_time',
  EPCO: 'earliest_possible_checkout_time',
  EFCO: 'earliest_free_checkout_time',
  LFCO: 'latest_free_Checkout_time',
  LPCO: 'latest_possible_checkout_time',
}[key] as TimeKey);

export const formatAddress = (address: (string | undefined)[]): string => {
  const nonNullAddress = address.filter((element) => element);
  const formattedAddress = nonNullAddress.join(', ');
  return formattedAddress;
}
