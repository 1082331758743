import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Divider,
  Typography,
  SxProps,
  DialogProps,
  IconButton,
  DialogTitleProps,
  Theme,
  Collapse,
  StackProps,
} from '@mui/material';
import Iconify from '../Iconify';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { ColorSchema } from 'src/theme/palette';
import { sxConvertor } from 'src/utils/sxConvertor';

export type DialogSeverity = ColorSchema | 'default';

export type DialogBoxProps = {
  severity?: DialogSeverity;
  title: string | ReactNode;
  children: ReactNode | null;
  openState: boolean;
  action?: any | null;
  DialogProps?: Partial<DialogProps>;
  dialogTitleProps?: Partial<DialogTitleProps>;
  ContentProps?: SxProps<Theme>;
  icon?: string | ReactNode;
  closeIcon?: boolean;
  titleContainerProps?: StackProps;
  hideActionDivider?: boolean;
};

export default function DialogBox({
  severity = 'default',
  title,
  children,
  openState,
  action,
  DialogProps,
  dialogTitleProps,
  ContentProps,
  icon,
  closeIcon = false,
  titleContainerProps,
  hideActionDivider = false,
}: DialogBoxProps) {
  const theme = useTheme();
  const defaultIcons = {
    info: 'ic:round-info',
    warning: 'mdi:alert-circle',
    error: 'ic:round-error',
    success: 'ic:round-check-circle',
  };

  return (
    <Dialog open={openState} {...DialogProps}>
      <DialogTitle
        sx={[
          { paddingBottom: 2, display: 'flex', alignItems: 'center' },
          ...sxConvertor(dialogTitleProps?.sx),
        ]}
        {...dialogTitleProps}
      >
        <Stack
          {...titleContainerProps}
          direction={'row'}
          alignItems={'flex-start'}
          flexGrow={1}
          sx={[
            { gap: theme.spacing(1.5) },
            ...(titleContainerProps?.sx && Array.isArray(titleContainerProps?.sx)
              ? titleContainerProps?.sx
              : [titleContainerProps?.sx || {}]),
          ]}
        >
          {icon && typeof icon !== 'string'
            ? icon
            : severity !== 'default' && (
                <Iconify
                  width={28}
                  height={28}
                  minWidth={28}
                  minHeight={28}
                  icon={icon || defaultIcons[severity]}
                  color={theme.palette[severity].main}
                />
              )}

          {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
        </Stack>
        {closeIcon && DialogProps?.onClose && (
          <IconButton
            onClick={(e) => {
              if (DialogProps.onClose) DialogProps.onClose(e, 'escapeKeyDown');
            }}
          >
            <Iconify icon={'ic:round-close'} width={24} height={24} />
          </IconButton>
        )}
      </DialogTitle>
      {children ? (
        <DialogContent sx={[{ pl: severity !== 'default' ? 8 : 2 }, ...sxConvertor(ContentProps)]}>
          {children}
        </DialogContent>
      ) : null}
      {!hideActionDivider && <Divider />}
      <Collapse in={action}>
        <DialogActions sx={{ pt: '16px !important', pb: '20px !important', display: 'block' }}>
          {action}
        </DialogActions>
      </Collapse>
    </Dialog>
  );
}
