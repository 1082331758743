import { ChangePriceSpanEnum, ChangePriceTypeEnum, MoreLessEnum, RateRuleCreateBody, RateRuleCategory, RateRuleState, SpanUnitEnum, RateRuleCategoryEnum, SpanKey, RateRule, RateRuleCreateState, SpanKeyType, PriceAdjustmentKeyType, RateRuleMode } from 'src/@types/rate-rule';
import { getDate, getMonth, getYear } from 'date-fns';
import { Nullable, WeekDayKey } from 'src/@types/common';

export const getRateRuleTitle = (title: string) => ({ title });
export const getRateRuleCategory = (category: RateRuleCategory) => ({
  category: RateRuleCategoryEnum[category],
});
export const getRateRuleTimePeriod = (mode: RateRuleMode, timePeriod: RateRuleState['timePeriod']) => ({
  recurring: mode === 'annual',
  startDay: timePeriod[0] ? getDate(timePeriod[0]) : null,
  startMonth: timePeriod[0] ? getMonth(timePeriod[0]) + 1 : null,
  startYear:  timePeriod[0] && mode === 'one_time' ? getYear(timePeriod[0]) : null,
  endDay: timePeriod[1] ? getDate(timePeriod[1]) : null,
  endMonth: timePeriod[1] ? getMonth(timePeriod[1]) + 1 : null,
  endYear:  timePeriod[1] && mode === 'one_time' ? getYear(timePeriod[1]) : null,
});

export const getRateRuleAllocation = (
  applyToChannels: RateRuleState['channelBookings'],
  applyToDirectBooking: RateRuleState['directBookings'],
  applyToAll: RateRuleState['applyToAllRatePlans'],
  ratePlans: RateRuleState['selectedRatePlans'],
) => ({
  applyToChannels,
  applyToDirectBooking,
  ratePlans,
  disabled: !applyToAll && ratePlans.length === 0,
});

export const getConditionDaysOfWeek = (weekDays: WeekDayKey[]) => ({
  hasConditionDaysOfWeek: true,
  daysOfWeekArray: weekDays,
});

export const getConditionLeadTime = (category: RateRuleCategory, { key, span }: SpanKeyType) => ({
  hasConditionLeadTime: true,
  leadTimeSpecificTime: "00:00" as RateRuleCreateBody['leadTimeSpecificTime'],
  leadTimeTimeType: category === 'last_minute_bookings' ? "Earliest Free Check-in Time" as RateRuleCreateBody['leadTimeTimeType'] : null,
  leadTimeMoreOrLess: category === 'last_minute_bookings' ? MoreLessEnum.LESS : MoreLessEnum.MORE,
  leadTimeQuantityUnit: category === 'last_minute_bookings' && key === 'hour' ? SpanUnitEnum.HOUR : SpanUnitEnum.DAY,
  leadTimeQuantityValue: getSpanValue(key, span),
});

export const getConditionChangeRates = ({ key, percent }: PriceAdjustmentKeyType) => ({
  changeRatesCapValue: null,
  decreaseOrIncreaseType: ChangePriceTypeEnum.PERCENT,
  decreaseOrIncrease: key === 'increase_by' ? ChangePriceSpanEnum.INCREASE : ChangePriceSpanEnum.DECREASE,
  hasActionChangeRates: percent !== null,
  changeRatesQuantityValue: percent ? +(percent ?? '') : null,
});

export const getConditionLengthOfStay = ({ maxStay, minStay }: RateRuleCreateState['lengthOfStay']) => ({
  minStay,
  maxStay,
  hasActionLengthOfStay: minStay !== null || maxStay !== null,
});

export const getConditionStopCheckins = (stopCheckins: RateRuleCreateState['stopCheckins']) => ({
  stopCheckins,
  hasActionStopCheckins: stopCheckins !== null,
});

export const getConditionStopCheckouts = (stopCheckouts: RateRuleCreateState['stopCheckouts']) => ({
  stopCheckouts,
  hasActionStopCheckouts: stopCheckouts !== null,
});

export const getConditionStopSell = (stopSell: RateRuleCreateState['stopSell']) => ({
  stopSell,
  hasActionStopSell: stopSell !== null,
});

const getSpanValue = (key: SpanKey, value: Nullable<number>) => key === 'month' ? (value ?? 0) * 30 : value;

export const convertRateRuleToRequestBody = (rateRule: RateRuleState): RateRuleCreateBody => {
  const { category } = rateRule;
  return {
    ...getRateRuleTitle(rateRule.title),
    ...getRateRuleCategory(rateRule.category),
    ...getRateRuleTimePeriod(rateRule.mode, rateRule.timePeriod),
    ...getRateRuleAllocation(
      rateRule.channelBookings,
      rateRule.directBookings,
      rateRule.applyToAllRatePlans,
      rateRule.selectedRatePlans,
    ),
    ...((['change_in_price', 'change_in_availability', 'orphan_nights'] as RateRuleCategory[]).includes(category) &&
      getConditionDaysOfWeek(rateRule.weekDays)
    ),
    ...((['early_bird_bookings', 'last_minute_bookings', 'booking_window'] as RateRuleCategory[]).includes(category) && 
      getConditionLeadTime(category,
        category === 'early_bird_bookings' ? rateRule.ends :
        category === 'last_minute_bookings' ? rateRule.starts :
        rateRule.bookingWindow
      )
    ),
    ...(category === 'orphan_nights' && {
      hasConditionOrphanNights: true,
    }),
    ...((['change_in_price', 'early_bird_bookings', 'last_minute_bookings', 'orphan_nights'] as RateRuleCategory[]).includes(category) && 
      getConditionChangeRates(category === 'change_in_price' ? rateRule.priceAdjustment : rateRule.changePrice)
    ),
    ...((['change_in_availability', 'early_bird_bookings', 'last_minute_bookings', 'orphan_nights'] as RateRuleCategory[]).includes(category) && {
      ...getConditionLengthOfStay(rateRule.lengthOfStay),
      ...getConditionStopCheckins(rateRule.stopCheckins),
      ...getConditionStopCheckouts(rateRule.stopCheckouts),
      ...getConditionStopSell(rateRule.stopSell),
    }),
    ...(category === 'booking_window' && {
      hasActionStopSell: true,
      stopSell: true,
    }),
  };
};

export const mapCategory: { [key in `${RateRuleCategoryEnum}`]: RateRuleCategory } = {
  CHANGE_IN_PRICE: 'change_in_price',
  CHANGE_IN_AVAILABILITY: 'change_in_availability',
  EARLY_BIRD_BOOKINGS: 'early_bird_bookings',
  LAST_MINUTE: 'last_minute_bookings',
  ORPHAN_NIGHTS: 'orphan_nights',
  BOOKING_WINDOW: 'booking_window',
  ADVANCED: 'advanced',
};

const getKeySpanOfValue = (unit: Nullable<SpanUnitEnum>, value: Nullable<number>, minimum = false): SpanKeyType => ({
  key: !minimum && value !== null && value % 30 === 0 ? 'month' : unit === SpanUnitEnum.HOUR ? 'hour' : 'day',
  span: !minimum && value !== null && value % 30 === 0 ? value / 30 : value,
});

const getPriceAdjustment = (unit: Nullable<ChangePriceSpanEnum>, value: Nullable<number>): PriceAdjustmentKeyType => ({
  key: unit === ChangePriceSpanEnum.INCREASE ? 'increase_by' : 'decrease_by',
  percent: value ? value.toString() : null,
});

const getTimePeriod = (year: number, month: number, day: number) => new Date(year ?? getYear(new Date()), month - 1, day, 0, 0, 0);

export const convertRateRuleBodyToState = (rateRule: RateRule): Partial<RateRuleCreateState> => ({
  id: rateRule.id,
  title: rateRule.title,
  expired: rateRule.expired,
  disabled: rateRule.disabled,
  mode: rateRule.recurring ? 'annual' : 'one_time',
  timePeriod: [
    getTimePeriod(rateRule.start_year, rateRule.start_month,rateRule.start_day),
    getTimePeriod(rateRule.end_year, rateRule.end_month, rateRule.end_day),
  ],
  category: mapCategory[rateRule.category],
  weekDays: rateRule.days_of_week_array?.filter((day) => day),
  lengthOfStay: {
    minStay: rateRule.min_stay,
    maxStay: rateRule.max_stay,
  },
  stopSell: rateRule.stop_sell,
  stopCheckins: rateRule.stop_checkins,
  stopCheckouts: rateRule.stop_checkouts,
  channelBookings: rateRule.apply_to_channels,
  directBookings: rateRule.apply_to_direct_booking,
  applyToAllRatePlans: false,
  selectedRatePlans: rateRule.rate_plans?.map(({ id }) => id),
  ...(rateRule.category === 'CHANGE_IN_PRICE' && {
    priceAdjustment: getPriceAdjustment(rateRule.decrease_or_increase, rateRule.change_rates_quantity_value),
  }),
  ...(rateRule.category === 'EARLY_BIRD_BOOKINGS' && {
    ends: getKeySpanOfValue(rateRule.lead_time_quantity_unit, rateRule.lead_time_quantity_value),
  }),
  ...(rateRule.category === 'LAST_MINUTE' && {
    starts: getKeySpanOfValue(rateRule.lead_time_quantity_unit, rateRule.lead_time_quantity_value, true),
  }),
  ...(rateRule.category === 'BOOKING_WINDOW' && {
    bookingWindow: getKeySpanOfValue(rateRule.lead_time_quantity_unit, rateRule.lead_time_quantity_value),
  }),
  ...((['EARLY_BIRD_BOOKINGS', 'LAST_MINUTE', 'ORPHAN_NIGHTS'] as `${RateRuleCategoryEnum}`[]).includes(rateRule.category) && {
    changePrice: getPriceAdjustment(rateRule.decrease_or_increase, rateRule.change_rates_quantity_value),
  }),
});
