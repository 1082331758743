import { AxiosRequestConfig } from 'axios';
import axios from '../utils/axios';
import { File, VideoUploadPostApiResponseType } from 'src/@types/common';

function attachmentAPI() {
  return {
    getFullsizeFile: (file_id: string | number, file_name: string) => {
      const url = `apis/attachments/fullsize/${file_id}/${file_name}`;
      return axios.get(url);
    },
    uploadFile: (file: FormData, config?: AxiosRequestConfig<FormData>) => {
      const url = '/apis/files';
      return axios.post<File>(url, file, config);
    },
    uploadVideo: (file: FormData, config?: AxiosRequestConfig<FormData>) => (
      axios.post<VideoUploadPostApiResponseType>('/apis/files/video', file, config)
    ),
    removeFile: (id: number) => axios.delete(`/apis/files/${id}`),
    deleteFile: (fileId: number | string) => {
      const url = '/apis/delete_file';
      return axios.delete(url, {
        data: { file_id: fileId },
      });
    },
    getFile: (id: number) => axios.get(`/apis/files/${id}`),
    UploadFileTo: (fileId: string, entityClass: string, entityId: number) => {
      const url = '/apis/add_attachment_file_to';
      return axios.post(url, { file_id: fileId, entity_class: entityClass, entity_id: entityId });
    },
    getAttachmentFile: (id: number) => {
      const url = `/apis/attachments/${id}/file`;
      return axios.get(url, {
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        }
      });
    },
    removeAttachment: (attachmentId: number | number[]) => {
      const url = `/apis/delete_attachments_entity`;
      return axios.post(url, {
        attachment_id: attachmentId
      });
    },
    getImageLink: (fileId: number) => {
      const url = '/apis/file/get_thumbnail_preview';
      return axios.get(url, { params: { file_id: fileId } });
    },
  };
}

export default attachmentAPI();
