import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { matchRoutes } from 'react-router';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';
import Redirecting from 'src/pages/auth/Redirecting';
import { HOST_API, OAUTH2_CODE } from 'src/config';
import useLocalStorage from 'src/hooks/useLocalStorage';

// ----------------------------------------------------------------------
type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [redirectURL, setRedirectURL] = useLocalStorage('redirectURL', '');

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== redirectURL && pathname !== '/auth') {
      setRedirectURL(pathname);
    }
    return (
      <Redirecting
        to={`${HOST_API}/oauth2-token/authorize?response_type=code&client_id=${OAUTH2_CODE.client_id}&redirect_uri=${window.location.origin}/auth`}
      />
    );
  }

  if (redirectURL && pathname !== redirectURL) {
    setRedirectURL('');
    return <Navigate to={pathname} />;
  }

  if (!matchRoutes(user?.access?.pages, pathname)) {
    return <Navigate to={`/403?origin=${pathname}`} />;
  }

  return <>{children}</>;
}
