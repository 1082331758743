import axios from '../utils/axios';

function treeAPI() {
  return {
    fetchTree: (level: 'rate_plan' | 'unit', params?: {
      type?: 'Nightly' | 'Weekly' | 'Monthly',
      root?: 'city' | 'brand',
    }) => {
      const url = `/apis/listing/tree/${level}`;
      return axios.get(url, { params: { root: 'brand', ...params } });
    },
    fetchInvestorUnitTree: (owner_id: null | string | number) =>
      axios.get(`/apis/listing/investor/tree/unit`, {
        params: {
          root: 'brand',
          available_units: 1,
          owner_id: owner_id,
        },
      }),
  };
}

export default treeAPI();
