import axios from 'src/utils/axios';

function accountAPI() {
  return {
    getOrganisationDetail: () => axios.get('/apis/organization/get_organization'),
    renameOrganisation: (organisationId: number, organisationNewName: string) => {
      const url = `/apis/organization/rename_organization_name/${organisationId}`;
      return axios.post(url, {
        organization_name: organisationNewName,
      });
    },
    updateOrganizationConfigurations: ({
      id,
      currency,
      registeredName,
      registeredNumber,
      dataProtectionNumber,
    }: {
      id: number;
      currency?: string;
      registeredName?: string;
      registeredNumber?: string | null;
      dataProtectionNumber?: string | null;
    }) =>
      axios.put(`/apis/organization_configurations/${id}`, {
        ...(currency !== undefined && { default_currency: currency }),
        ...(registeredName !== undefined && { registered_company_name: registeredName }),
        ...(registeredNumber !== undefined && { registered_number: registeredNumber }),
        ...(dataProtectionNumber !== undefined && { data_protection_number: dataProtectionNumber }),
      }),
    updateBrandName: (brandId: number, brandName: string) => {
      const url = `/apis/brand/rename_brand_name/${brandId}`;
      return axios.put(url, {
        name: brandName,
      });
    },
    createBrand: (name: string, subdomain: string) => {
      const url = '/apis/brand/create_brand';
      return axios.post(url, { name, subdomain });
    },
    cancelSubscription: (contractId: number, force: any) => {
      const url = `/apis/crm/cancel_subscription/${contractId}?force=${force}`;
      return axios.get(url);
    },
    fetchCustomer: (customerId: number) => {
      const url = `/apis/zeevou_customers/${customerId}`;
      return axios.get(url);
    },
    fetchCustomerCards: () => {
      const url = `/apis/zeevou_customer_cards`;
      return axios.get(url);
    },
    saveCard: (
      card_number: string,
      name_on_card: string,
      expire_date: string | number,
      cvv: string | number,
      redirect_url: string,
      region: string,
      country: string,
      first_line_address: string,
      city: string,
      post_code: string,
      customer_id: string | number
    ) => {
      const url = `/apis/crm/add_new_card`;
      return axios.post(url, {
        card_number,
        name_on_card,
        expire_date,
        cvv,
        redirect_url,
        region,
        country,
        first_line_address,
        city,
        post_code,
        customer_id,
      })
    },
    updateCustomerContactEmail: (customerId: number, email: string | null) => {
      const url = `/apis/zeevou_customers/${customerId}`;
      return axios.put(url, {
        email,
      })
    },
    addCustomerBillingAddress: (customerId: number, address) => {
      const url = `/apis/zeevou_customers/${customerId}`;
      return axios.put(url, {
        new_billing_address: address,
      })
    },
    setCustomerDefaultBillingAddress: (customerId: number, addressId: number) => {
      const url = `/apis/zeevou_customers/${customerId}`;
      return axios.put(url, {
        default_billing_address: addressId,
      })
    },
    deleteCustomerBillingAddress: (addressId: number) => {
      const url = `/apis/addresses/${addressId}`;
      return axios.delete(url)
    },
    setCustomerDefaultCard: (cardId: number) => {
      const url = `/apis/zeevou_customer_cards/${cardId}`;
      return axios.put(url, {
        is_default: true,
      })
    },
    deleteCustomerCard: (cardId: number) => {
      const url = `/apis/zeevou_customer_cards/${cardId}`;
      return axios.delete(url)
    },
  };
}

export default accountAPI();
